import React from "react";
import "./DotsStage_5.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Validators } from "@UIKit";
import TextField from "@mui/material/TextField";
import SVG from "react-inlinesvg";
import star from "@images/svg/star_req.svg";
import arrowLeft from "@images/svg/arrow-left-prize.svg";
import { useHistory } from "react-router-dom";

const DotsStage_5 = ({ handleFormData, prevStep, values, errorNumber, handleCreatePoint }) => {
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const submitFormData = (e) => {
    e.preventDefault();
    handleCreatePoint();
    history.push('/')
  };

  return (
    <div className="dots-stage_5">
      <div className="dots-container">
        <a href="/" className="dots__arrowMap"><SVG src={arrowLeft}></SVG>На карту</a>
        {!isAuthenticated && (
          <div>
            <h1 className="dots__title">Внеси свой email</h1>
            <div className="dots-stage_1__desc">Чтобы получить подарок и участвовать в розыгрышах призов.</div>
            <div className="input-wrapper-field">
              <TextField
                className="dots__input"
                type="email"
                id="standard-basic"
                defaultValue={values.authorEmail}
                onChange={handleFormData("authorEmail")}
                label="Твой e-mail"
                variant="standard"
              />
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
            <div className="dots-stage_5__buttons">
              <button className="dots__button default" onClick={submitFormData}>
                Отправить
              </button>
              <Link to="/sign-in" className="dots__button">
                Авторизоваться
              </Link>
            </div>
          </div>
        )}
        {isAuthenticated && (
          <div>
            <h1 className="dots__title">Всё отлично!</h1>
            <div className="dots-stage_1__desc">
              <p>
                Как только точка пройдёт модерацию, тебе будут начислены баллы, которые ты можешь обменять на любой
                подарок в программе лояльности.
              </p>
              <p>
                Добавлять точки и рекомендации можно неограниченно. Больше баллов — больше призов! Спасибо, что делаешь
                мир лучше вместе с нами.
              </p>
            </div>
            <Link to="/" className="dots__button">
              На главную
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default DotsStage_5;
