import { styled } from "@mui/material/styles";

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 24,
    height: 24,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "white",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage: "radial-gradient(#FFE04D, #fff 48%,transparent 40%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#FFE04D",
  },
});

export {BpCheckedIcon};
