import React from 'react'
import { UIRoundedButton } from '@UIKit'
import { commonService } from '@services/commonService'


function renderFavouritesButtonText(isMobile, isFavourites) {
  if (isFavourites) {
    return 'Открепить'
  }
  return isMobile ? 'Закрепить' : 'Закрепить сверху'
}

function AuthControls({ onClick, onFavourites, isDisabled, isFavourites, color, task }) {
  const handleClick = React.useCallback(
    () => {
      commonService.sendEvent({
        category: 'task_send',
        action: `User is going to complete task ${task.id}`
      });
      onClick();
    },
    []
  );

  const handleFavouriteClick = React.useCallback(
    () => {
      if (!isFavourites) {
        commonService.sendEvent({
          category: 'pin_the_task',
          action: `User has pinned task ${task.id}`
        });
      }
      onFavourites();
    },
    []
  );

  const isMobile = commonService.isMobileSize()
  return (
    <>
      <UIRoundedButton
        className="Task__Button"
        color={ color }
        onClick={ handleClick }
        disabled={ isDisabled }
      >
        Выполнить
      </UIRoundedButton>
      <UIRoundedButton
        className="Task__Button"
        color={ 'primary' }
        onClick={ handleFavouriteClick }
      >
        { renderFavouritesButtonText(isMobile, isFavourites) }
      </UIRoundedButton>
    </>
  )
}

function RegularControls({ color, onClick }) {
  // TODO:
  //   if (status === TaskStatuses.DECLINED)
  //   <UIRoundedButton
  //     className="Task__Button"
  //     onClick={ onClick }
  //     key="2"
  //   >
  //     Выполнить снова
  //   </UIRoundedButton>
  return (
    <>
      <UIRoundedButton
        className="Task__Button"
        color={ color }
        onClick={ onClick }
      >
        Подробнее
      </UIRoundedButton>
    </>
  )
}

function TaskControls({
  // status,
  task,
  color,
  onClick,
  onFavourites,
  isAuthenticated,
  isDisabled,
  isFavourites,
}) {
  const invertColor = color === 'primary' ? 'secondary' : 'primary'
  return (
    <div className="Task__Controls">
      { isAuthenticated
        ? <AuthControls
            onClick={ onClick }
            onFavourites={ onFavourites }
            isFavourites={ isFavourites }
            isDisabled={ isDisabled }
            color={ invertColor }
            task={task}
          />
        : <RegularControls
            color={ invertColor }
            onClick={ onClick }
          />
      }
    </div>
  )
}

export default React.memo(TaskControls)
