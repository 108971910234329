import React from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import './NativeSelect.scss';

export const NativeSelect = ({
  suggestions,
  className,
  placeholder,
  label,
  handleChange,
  onClick,
  selectedKey = '',
  input
}) => {
  const [ id ] = React.useState(uniqueId('NativeSelect_'));

  const handleSelectChange = React.useCallback(({ target: { value }}) => {
    const foundSuggestion = suggestions
      .find(suggestion => suggestion.key === (/^\d+$/.test(value) ? +value : value));
    handleChange && handleChange(foundSuggestion);
  }, [suggestions]);

  React.useEffect(() => {
    if (selectedKey) {
      input && input.onChange(selectedKey);
    }
  }, [selectedKey, input])

  return (
    <div className={clsx("NativeSelect", className)}>
      { label && (input && input.value) &&
        <label
          htmlFor={id}
          className={clsx("NativeSelect__Label")}
          spellCheck="false"
        >
          {label}
        </label>
      }

      <select id={id}
        className={clsx(
          "NativeSelect__Select",
          input && !input.value && 'NativeSelect__DefaultOption'
        )}
        onClick={onClick}
        onChange={handleSelectChange}
        value={selectedKey}
        {...input}
      >
        <option key={0} value="" disabled>{ label }</option>
        { suggestions.map((suggestion) => {
          return (
            <option key={suggestion.key} value={suggestion.key}>
              { suggestion.value }
            </option>
          );
        }) }
      </select>
    </div>

  )
}
