
import React from 'react';
import useWindowSize from '@hooks/useWindowSize';
import { useLocation } from 'react-router-dom'

const observeConfig = { childList: true,subtree: true };

export const useScroll = (parentRef, childRef) => {
  const [ windowSize ] = useWindowSize();
  const location = useLocation()
  const [ hasScroll, setHasScroll ] = React.useState(false);

  const [ observedTarget, setObservedTarget ] = React.useState(null);

  const measureContent = () => {
    setHasScroll(childRef.current.scrollHeight > parentRef.current.clientHeight);
  };

  React.useEffect(
    () => {
      if (!parentRef.current || !childRef.current)
        return;
      
      if (childRef.current !== observedTarget) {
        setObservedTarget(childRef.current);
      }
      measureContent();
    },
    [windowSize, childRef, location, parentRef]
  );

  React.useEffect(() => {
    if (!observedTarget) {
      return;
    }
    const observer = new MutationObserver(measureContent);
    observer.observe(observedTarget, observeConfig);

    return () => observer.disconnect();
  }, [ observedTarget ])

  return [ hasScroll ];
}