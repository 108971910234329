export const isDebug = true;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const YMAPS_API_KEY = process.env.REACT_APP_YMAPS_API_KEY;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const server = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_API_URL;
export const APP_URL = process.env.REACT_APP_URL;

export const APPLE_ID_CLIENT = process.env.REACT_APP_APPLE_ID_CLIENT;
export const VK_ID_CLIENT = process.env.REACT_APP_VK_ID_CLIENT;
export const FACEBOOK_ID_CLIENT = process.env.REACT_APP_FACEBOOK_ID_CLIENT;
export const GOOGLE_ID_CLIENT = process.env.REACT_APP_GOOGLE_ID_CLIENT;
export const MAX_ZOOM = 16;
export const MIN_ZOOM = 9;
