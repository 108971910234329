export const pressEnter = userInput => ({ type: 'pressEnter', payload: { userInput } });
export const selectSuggestion = userInput => ({ type: 'selectSuggestion', payload: { userInput } });
export const inputChange = (userInput, filteredSuggestions) => ({
  type: 'inputChange',
  payload: { userInput, filteredSuggestions }
})
export const setState = payload => ({ type: 'setState', payload });

export const initialState = {
  // The active selection's index
  activeSuggestion: 0,
  // The suggestions that match the user's input
  filteredSuggestions: [],
  // Whether or not the suggestion list is shown
  showSuggestions: false,
  // What the user has entered
  userInput: ''
}

export const autocompleteReducer = (state, action) => {
  switch(action.type) {
    case 'pressEnter': {
      return {
        ...state,
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: action.payload.userInput
      }
    }
    case 'inputChange': {
      return {
        activeSuggestion: 0,
        filteredSuggestions: action.payload.filteredSuggestions,
        showSuggestions: true,
        userInput: action.payload.userInput
      }
    }
    case 'selectSuggestion': {
      return {
        ...state,
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: action.payload.userInput
      };
    }
    case 'setState': {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
}
