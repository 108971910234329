import envelopeIcon from '@images/design/envelope.svg';
import profileCircleIcon from '@images/design/profile-circle.svg';
import clockIcon from '@images/design/clock.svg';
import attentionIcon from '@images/design/attention.svg';

class Notification {
  title;
  message;
  icon;
  time;
  constructor({ title, message, icon, time }) {
    this.title = title;
    this.message = message;
    this.icon = icon;
    this.time = time;
  }
}

export class NotificationSuccess extends Notification {
  type = 'success';
}

export class NotificationFail extends Notification {
  type = 'fail';
}

export const messageSent = new NotificationSuccess({
  title: 'Ваше обращение отправлено!',
  message: 'Ответ поступит на ваш электронный адрес указанный в письме',
  icon: envelopeIcon,
  time: 2000
});

export const forgotPasswordSent = new NotificationSuccess({
  title: 'Письмо отправлено',
  icon: envelopeIcon,
  time: 2000
})

export const profileChanged = new NotificationSuccess({
  title: 'Изменения сохранены',
  icon: profileCircleIcon,
  time: 2000
});

export const taskSubmitted = new NotificationSuccess({
  title: 'Задание отправлено',
  message: 'Статус задания вы можете отслеживать в разделе “Выполненные задания”',
  icon: clockIcon,
  time: 2000
});

export const somethingGoesWrong = new NotificationFail({
  title: 'Ошибка',
  message: 'Что-то пошло не так, пожалуйста, обновите страницу и попробуйте снова',
  icon: attentionIcon,
  time: 2000
})
