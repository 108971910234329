import React, { useEffect, useState } from "react";
import DotsStageOne from './DotsStage_1/DotsStage_1';
import DotsStageTwo from './DotsStage_2/DotsStage_2';
import DotsStageThree from './DotsStage_3/DotsStage_3';
import DotsStageFour from './DotsStage_4/DotsStage_4';
import DotsStageFive from './DotsStage_5/DotsStage_5';
import { createPoint } from '../../../../redux/actions/points';
import { useDispatch } from "react-redux";
import { pickBy } from 'lodash';
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";

const AddPointStepForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [errorNumber, setErrorNumber] = useState(false);
  const [formData, setFormData] = useState({
    pointType: 0,
    pointTag: 'service',
    authorName: '',
    authorEmail: '',
    authorPosition: '',
    title: '',
    "functions": [],
    city: '',
    address: '',
    phone: '',
    url: '',
    description: '',
    activeDateFrom: '',
    activeDateTo: '',
    socialMediaLink1: '',
    socialMediaLink2: '',
    socialMediaLink3: '',
    socialMediaLink4: '',
    promoCode: '',
    groupTime: '',
    groupSize: '',
    role: 0,
  });

  const [cookies, setCookie, removeCookie] = useCookies(['formData']);

  useEffect(() => {
    cookies.dotsData && setFormData(cookies.dotsData);
  }, []);
  
  const cities = useSelector((state) => state.map.cities);
  
  const [pointCities, setPointCities] = useState([]);
  const toSuggestion = (filter) => ({ ...filter, key: filter.id, value: filter.title });

  useEffect(() => {
    setPointCities(sortBy(cities, ["title"]).map(toSuggestion));
  }, [cities]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleInputData = input => e => {
    if (input === 'functions') {
      setFormData({ ...formData, [input]: [e.target.value] });
    } else {
      setFormData({ ...formData, [input]: e.target.value });
    }

    if (formData.phone.length === 12) {
      setErrorNumber(false);
    }
  }

  const handleCreatePoint = () => {
    //TODO: проверить преобразование formData все ли верно
    if (typeof formData.city === 'string') {
      const {id} = pointCities.find(item => formData.city === item.title);
      formData.city = id;
    }

    if (formData.phone === '') {
      formData.phone = '+79999999999';
    }

    if (formData.authorEmail === '') {
      formData.authorEmail = 'service@plus-one.ru';
    }

    if (formData.authorName === '') {
      formData.authorName = 'service';
    }

    if (formData.address === '' ) {
      formData.address = '-';
    }

    const a = formData.phone.split('');
    const b = a.filter(element => element != " ");

    if (b.length != 12) {
      setErrorNumber(true);
    } else {
      var compacted = pickBy(formData);
      dispatch(createPoint(compacted, dispatch));
      setStep(step + 1);
    }
  };

  const saveAndContinueLater = () => {
    removeCookie('dotsData');
    setCookie('dotsData', formData);
    history.push('/')
  }

  switch (step) {
    case 1:
      return (
        <DotsStageOne
          nextStep={nextStep}
          setStep={setStep}
          handleFormData={handleInputData}
          values={formData} 
        />
      );
    case 2:
      return (
        <DotsStageThree
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          values={formData}
          setCookie={setCookie}
          saveAndContinueLater={saveAndContinueLater}
          cookies={cookies}
        />
      );
    case 3:
      return (
        <DotsStageTwo
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          values={formData}
          setCookie={setCookie}
          saveAndContinueLater={saveAndContinueLater}
        />
      );
    case 4:
      return (
        <DotsStageFour
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          handleCreatePoint={handleCreatePoint}
          values={formData}
          errorNumber={errorNumber}
          saveAndContinueLater={saveAndContinueLater}
          cookies={cookies}
        />
      );
    case 5:
      return (
        <DotsStageFive
          prevStep={prevStep}
          handleFormData={handleInputData}
          handleCreatePoint={handleCreatePoint}
          values={formData}
        />
      );
    default:
      return null;
  }
}

export default React.memo(AddPointStepForm);