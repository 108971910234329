import React from "react";
import "./CitizensPartnerShip.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";
import phoneCity from "@images/phone-city.jpg";

const CitizensPartnerShip = () => {
  return (
    <div className="citizens-partner-ship about-sub">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/smi">Помоги нам сделать карту +1Город лучше</Link>
        </div>
        <div className="about-sub__flex">
          <div className="citizens-partner-ship__left-block">
            <h1 className="about-sub__title">Помоги нам сделать карту +1Город лучше</h1>
            <div className="about-sub__text">
              <p>
                +1Город помогает жителям легко и быстро узнавать о социальных и волонтерских проектах поблизости. На
                карту нанесены пункты приема вторсырья, экологические сервисы, и многое другое. Помоги нам сделать карту
                лучше! Нанеси на карту знакомую тебе организацию или проверь ближайшие к тебе.{" "}
              </p>
              <p>
                Вместе мы сможем собрать наиболее полную и точную карту экологических и социальных городских сервисов,
                которыми сможет воспользоваться каждый.
              </p>
            </div>
          </div>
          <div className="about-sub__img">
            <img src={phoneCity}></img>
          </div>
        </div>
        <Link className="about-sub__button" to="/loyalty/new_point">
          Добавить точку
        </Link>
      </div>
    </div>
  );
};

export default CitizensPartnerShip;
