import { createActions } from 'redux-actions';
import * as API from '../../api/about';
import { SET_ABOUT } from '../constants';

export const { setAbout } = createActions(SET_ABOUT);

export const fetchAbout = () => async dispatch => {
  try {
    const res = await API.fetchAbout();
    dispatch(setAbout({ text: res.data.results }));

    return res;
  } catch (e) {
    throw e;
  }
};
