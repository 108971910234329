import React from 'react'
import { withRouter } from 'react-router';

const RedirectPage = withRouter(({ location }) => {
  React.useEffect(() => {
    const params = new URLSearchParams(location.hash.slice(1));
    const accessToken = params.get('access_token');
    if (!accessToken) {
      return;
    }
    window.opener.handleAccessToken(accessToken)
    window.close();
  }, [])
  
  return null;
})

export default RedirectPage;
