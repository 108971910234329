import React from 'react'
import { UIDialog } from '@UIKit'
import TaskConfirm from './TaskConfirm'
import TaskForm from './TaskForm'
import { createTaskPayload } from '@UIKit/utils/formdata';
import './TaskDialog.scss'

const generateFileArray = (task) => {
  if (!task) {
    return [];
  }
  const { photoAnswer, videoAnswer } = task;
  return new Array(photoAnswer + videoAnswer).fill(null);
}

const validateForm = (files, message, { textAnswer }) =>
  (!textAnswer || message) && files.every(Boolean);

function TaskDialog({
  data,
  onClose,
  onSubmit,
  isOpen,
  isPreview,
}) {
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [files, setFiles] = React.useState(generateFileArray(data))
  const [message, setMessage] = React.useState('')
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  const handleFilesChange = React.useCallback(files => {
    setIsDirty(true);
    setFiles(files)
    setIsValid(validateForm(files, message, data));
  }, [message, data]);

  const handleMessageChange = React.useCallback(({ target }) => {
    setIsDirty(true);
    setMessage(target.value)
    setIsValid(validateForm(files, target.value, data));
  }, [files, data]);

  const handleClose = React.useCallback(
    () => {
      isDirty && !showConfirmation
        ? setShowConfirmation(true) : onClose();
    },
    [isDirty, showConfirmation]
  )

  const handleCancel = React.useCallback(
    () => setShowConfirmation(false),
    []
  );

  const handleSubmit = async () => {
    const formData = createTaskPayload(message, files);
    await onSubmit(data.id, formData);
    onClose();
  }

  if (!isOpen || !data)
    return null

  return (
    <UIDialog
      className="TaskDialog"
      onClose={ handleClose }
      isOpen={ isOpen }
      blurred
      onBackdropClick={ handleClose }
    >
      { showConfirmation
        ? <TaskConfirm
            onClose={ onClose }
            onCancel={ handleCancel }
          />
        : <TaskForm
            data={ data }
            files={ files }
            message={ message }
            isValid={isValid}
            onFilesChange={ handleFilesChange }
            onMessageChange={ handleMessageChange }
            onSubmit={ handleSubmit }
            isPreview={ isPreview }
          />
      }
    </UIDialog>
  )
}

export default TaskDialog
