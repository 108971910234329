import { SET_IS_OPEN_MODAL_PRODUCTS, SET_IS_OPEN_MODAL_NAV } from '../constants/index';

const setIsOpenProductsModal = payload => ({
	type: SET_IS_OPEN_MODAL_PRODUCTS
});

const setIsOpenNavModal = payload => ({
	type: SET_IS_OPEN_MODAL_NAV
});

export {setIsOpenProductsModal, setIsOpenNavModal};