import React from "react";
import "./Prize_thank.scss";
import SVG from "react-inlinesvg";
import arrow from "@images/svg/arrow-left-prize.svg";
import { Link } from "react-router-dom";

const Prize_thank = () => {
  return (
    <div className="prize_thank">
      <div className="prize_congratulation__container">
        <Link to="/prize_delivery">
          <SVG className="prize_congratulation__arrow" src={arrow} />
        </Link>
        <div className="prix_in__title">Спасибо</div>
        <div className="prize_thank__text">Ваш приз будет доставлен по адресу:</div>
        <div className="prize_thank__adress">г. Москва, ул. Московская, д.12, кв. 12</div>
        <div className="prize_thank__wrapper">
          <Link className="prize_thank__nav-adress" to="/prize_delivery">
            редактировать адрес?
          </Link>
          <div className="prize_thank__button">
            <Link to="/prizes" className="prix_in__nav">
              вернуться к призам
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prize_thank;
