import React from "react";
import "./Medianetwork.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";
import { AppPartners } from '@UIKit/AppPartners/AppPartners';
import { useSelector } from "react-redux";

const Medianetwork = () => {
  const store = useSelector(state => state);
  const cities = store.map.cities.filter(city => city.smiUrl != null);

  return (
    <div className="medianetwork">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/medianetwork">Медиасеть +1Город</Link>
        </div>
        <h1 className="about-sub__title">Медиасеть +1Город</h1>
        <div className="medianetwork__text">
          <p>Медиасеть +1 — это объединение региональных СМИ и сообществ в социальных сетях в 75 городах России, с совокупной численностью населения более 15 млн пользователей. Тематика этих медиа — локальные новости, информация о социальных проектах, экология, урбанистика, важные вопросы для этих городов.</p>
          <p>Мы всегда открыты к сотрудничеству со СМИ и волонтерскими организациями.</p>
        </div>
        <AppPartners cities={cities} />
      </div>
    </div>
  );
};

export default Medianetwork;
