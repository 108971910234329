import React, { useRef } from 'react';
import clsx from 'clsx';
import { UIAppSideBar } from '@UIKit';
import { commonService } from '@services/commonService';
import { useScroll } from '@hooks/useScroll';
import './AppPage.scss';

export const AppPage = React.forwardRef(({ children }, forwardRef) => {
  const isDesktop = !commonService.isMobileDevice();
  const childRef = useRef();
  const [ hasScroll ] = useScroll(forwardRef, childRef);

  return (
    <div className={clsx("AppPage", isDesktop && hasScroll &&  'AppPage--desktop-scroll') }>
      <UIAppSideBar className="AppPage__SideBar" />
      <div className={ clsx('AppPage__Container', isDesktop && 'AppPage__Container--desktop') } ref={forwardRef}>
        <div className="AppPage__Content" ref={childRef}>
          { children }
        </div>
      </div>
    </div>
  )
})
