import React from 'react';
import { UIInput } from '@UIKit';
import { months } from '@UIKit/utils/formatters'
import './DatePicker.scss'
import { UIAdaptiveAutocomplete } from '@UIKit';
import { Field, reduxForm, formValueSelector, change, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { parseDate, composeDate } from '@UIKit/utils/formatters';
import { identity } from 'lodash';
import { bindActionCreators } from 'redux';
import { Validators } from '@UIKit/utils/validators';

export const DatePicker = React.memo(({
  day,
  year,
  month,
  onDateChange = identity,
  change,
  errors
}) => {
  const handleChange = (suggestion) => {
    change('month', suggestion.key);
  }

  const suggestions = React.useMemo(() =>
    months.map((month, indx) => ({
      key: moment(indx + 1, 'M').format('MM'),
      value: [ month.charAt(0).toUpperCase() + month.slice(1) ].join('')
    }))
  , []);

  React.useEffect(() => {
    const composedDate = composeDate({ day, month, year });
    if (composedDate) {
      onDateChange(composedDate);
    }
  }, [ day, month, year ]);

  return (
    <div className="DatePicker__Fields">
      <Field name="day"
        type="number"
        component={UIInput}
        className="DatePicker__Input"
        placeholder="День"
        validate={[ Validators.day ]}
      />
      <UIAdaptiveAutocomplete
        type="select"
        className="DatePicker__Autocomplete"
        placeholder="Месяц"
        zIndex={10000}
        selectedKey={month}
        handleChange={handleChange}
        suggestions={suggestions}
        listWidth={115}
        invalid={!!errors.month}
      />
      <Field name="year"
        type="number"
        component={UIInput}
        className="DatePicker__Input"
        placeholder="Год"
        validate={[ Validators.maxYears100 ]}
      />
    </div>
  )
});

const mapStateToProps = (state, { value, form }) => {
  return {
    initialValues: parseDate(value),
    form,
    day: formValueSelector(form)(state, 'day'),
    month: formValueSelector(form)(state, 'month'),
    year: formValueSelector(form)(state, 'year'),
    errors: getFormSyncErrors(form)(state)
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    validate: ({ day, month, year }) => {
      const errors = {
        year: ' ',
        month: ' ',
        day: ' '
      };

      if (!year && !month && !day) {
        return;
      }

      if (composeDate({ day, month, year })) {
        return;
      }

      return errors;
    }
  })(DatePicker)
);
