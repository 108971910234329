import React from 'react';
import Popover from 'react-popover';
import './CityPopover.scss';
import { UIRoundedButton } from '@UIKit';
import { bindActionCreators } from 'redux';
import { setMap } from '@redux/actions/map';
import { connect } from 'react-redux';
import clsx from 'clsx';

const CityPopover = ({ children, selectedCity, openCityDialog, setMap, showPopover, isOpen, className, isMobile, ...props }) => {
  const closePopover = () => setMap({ showPopover: false });
  const handleClick = React.useCallback(evt => evt.stopPropagation(), []);
  const isPopoverOpen = React.useMemo(() => showPopover && isOpen, [showPopover, isOpen]);

  const handleOpenCityDialog = React.useCallback(
    () => {
      openCityDialog();
      closePopover();
    }, []);

  const popoverOptions = React.useMemo(() => {
    return {
      body: (
        <div className="CityPopover__Container"
          onClick={handleClick}
          onMouseEnter={handleClick}
          onMouseLeave={handleClick}
        >
          <h3 className="CityPopover__Title">
            Ваш город { selectedCity.title }?
          </h3>
          <UIRoundedButton onClick={closePopover} color={isMobile ? 'transparent' : 'primary'}>
            Все верно
          </UIRoundedButton>
          <UIRoundedButton onClick={handleOpenCityDialog} color={isMobile ? 'primary' : 'secondary'}>
            Выбрать другой
          </UIRoundedButton>
        </div>
      ),
      onOuterAction: closePopover,
      ...props,
      isOpen: isPopoverOpen,
    }
  }, [isPopoverOpen]);

  return (
    <Popover {...popoverOptions} className={clsx("CityPopover", className)}>
      { children }
    </Popover>
  );
}
const mapStateToProps = ({ map: { showPopover } }) => ({ showPopover });
const mapStateToDispatch = dispatch => bindActionCreators({ setMap }, dispatch);
export default connect(mapStateToProps, mapStateToDispatch)(CityPopover);
