import React, { useEffect, useState } from "react";
import { RoundedButton } from "../RoundedButton";
import NewBusinessIcon from "../../img/new-business.svg";
import Dot from "../../img/dot.svg";
import { connect, useDispatch, useSelector } from "react-redux";
import "./BusinessPromotion.scss";
import { fetchTasks } from "../../redux/actions/loyalty";
import { bindActionCreators } from "redux";
import SimpleSlider from "../../components/SliderSlick/SliderSlick";
import searchIcon from "@images/svg/search-black.svg";
import SVG from "react-inlinesvg";
import { commonService } from "@services/commonService";
import search from "@images/svg/search.svg";
import { getFetchData } from '@redux/actions/feature'

// const links = {
//   addBusiness: 'https://forms.gle/JzLuJ4RCHwTVJTCe7',
//   showNearBy: '/filter/local_business/'
// }


export const BusinessPromotion = (props) => {
  const [firstUpdate, setFirstUpdate] = useState(true);
  const { categories, tasks } = props;
  const store = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
   props.fetchTasks();
   dispatch(getFetchData());
  }, []);

  const routeTo = (path) => () => {
    props.history.push(path);
  };

  const getSearchResults = () => {
    const { searchText, currentCenter, setSearchResult } = props;
    setSearchResult({ searchTextToDisplay: searchText });
    handleSearch(searchText, currentCenter, true);
  };

  const handleSearch = (searchText, [latitude, longitude], composeQuery) => {
    let searchQuery = null;
    const {
      history,
      location: { search },
    } = props;

    if (composeQuery) {
      const composedQuery = commonService.composeSearchQuery(searchText, search, [latitude, longitude]);
      searchQuery = `/search/?${composedQuery}`;
      history.push(searchQuery);
      props.setSearchResult({
        searchQuery,
        searchText,
      });
    }
    props.setPoints({ selectedPointIds: [] });
    props.setAppState({
      filterIsOpen: false,
      isSearching: true,
    });
    props.searchPoints(searchText, latitude, longitude);
  };

  const changeSearch = ({ target }) => {
    props.setSearchResult({ searchText: target.value });
  };

  const onEnter = (event) => {
    const { searchText, currentCenter, setSearchResult } = props;

    if (event.key === "Enter") {
      setSearchResult({ searchTextToDisplay: searchText });
      handleSearch(searchText, currentCenter, true);
    }
  };

  const closeAllModal = () => {
    const tmpArr = props.categories;
    tmpArr.forEach((cater) => (cater.active = false));
    props.setPoints({ categories: tmpArr, onlineServiceOpen: false });
  };

  const renderSearchForm = () => {
    const { searchText } = props;

    const isNoSearch = props.location.search.includes("=no-search");

    return (
      <div className={isNoSearch ? "search hidden" : "search"}>
        <div className="search__form">
          <input
            onChange={changeSearch}
            onKeyPress={onEnter}
            onClick={closeAllModal}
            value={searchText}
            className="search__field"
            type="text"
            placeholder="Поиск..."
            spellCheck="false"
          />
          <button className="search__icon" onClick={getSearchResults}>
            <SVG src={search} />
          </button>
        </div>
      </div>
    );
  };

  const Tasks = () => {
    return <SimpleSlider dataSlider={tasks} name="tasks" goTo={props.goTo} location={props.location}></SimpleSlider>;
  };

  const Categories = () => {
    return (
      <SimpleSlider
        dataSlider={categories}
        name="categories"
        goTo={props.goTo}
        location={props.location}
      ></SimpleSlider>
    );
  };

  const featureBlock = () => {
    return (
      <SimpleSlider
        dataSlider={store.feature}
        name="feature"
        goTo={props.goTo}
        location={props.location}
        cities={props.cities}
        filters={props.filters}
      ></SimpleSlider>
    );
  };

  const maximizedView = () => {
    return (
      <div className="BusinessPromotion--maximized">
        <h1 className="BusinessPromotion__title">Добавляй точки на карту и получай баллы</h1>
        <p className="BusinessPromotion__description">
          Если вы владелец бизнеса, НКО, горожанин или волонтер, вы можете добавить организацию или сервис, мероприятие
          или акцию к нам на карту. Для предпринимателей у нас есть дополнительные возможности по взаимодействию с
          пользователями.
        </p>
        <div className="BusinessPromotion__button-group">
          <RoundedButton svgIcon={Dot} className="BusinessPromotion__button" handleClick={routeTo("/loyalty/new_point")}>
            <span className="BusinessPromotion__button-text">Добавить точку</span>
          </RoundedButton>
          {/* <div className="BusinessPromotion__description-add-dot">Добавьте вашу организацию или сервис, чтобы начать взаимодействие с нашими пользователями.</div>
          <RoundedButton
            svgIcon={NewBusinessIcon}
            className="BusinessPromotion__button"
            handleClick={routeTo('/loyalty/new')}
          >
            <span className="BusinessPromotion__button-text">Добавить бизнес</span>
          </RoundedButton> */}
        </div>
      </div>
    );
  };


  return (
    <div>
      {featureBlock()}
      {renderSearchForm()}
      {Categories()}
      {maximizedView()}
      {Tasks()}
    </div>
  );
};

const mapStateToProps = ({ loyalty: { tasks } }) => {
  return {
    tasks,
  };
};

const mapStateToDispatch = (dispatch) =>
  bindActionCreators(
    {
      fetchTasks,
    },
    dispatch
  );

export default connect(mapStateToProps, mapStateToDispatch)(BusinessPromotion);
