import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

const PartnerMedia = React.memo(({ slug, cities, className }) => {
  const [ media, setMedia ] = React.useState(null);
  
  React.useEffect(() => {
    const selectedCity = cities.find(city => city.slug === slug);
    if (!selectedCity) {
      return;
    }
    const { smiTitle, smiUrl } = selectedCity;
    setMedia({ smiTitle, smiUrl });
  }, [ slug, cities ]);

  return media && (
    <a className={clsx(className, "Link")} href={media.smiUrl} target="_blank" rel="noreferrer">
      { media.smiTitle }
    </a>
  );
})

const mapStateToDispatch = ({ map: { cities, slug } }) => {
  return { cities, slug };
}

export default connect(mapStateToDispatch)(PartnerMedia)