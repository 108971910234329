import * as moment from 'moment';
import "moment/locale/ru";
import { isNumber, isNil } from 'lodash';

moment.locale('ru');

export const months = moment.months();

export const dateFormatter = (date) => {
  if (!date) {
    return '';
  }

  const formattedDate = moment(date);

  return formattedDate.isValid()
    ? formattedDate.format('LL').slice(0, -3)
    : ''
};

export const autocompleteFormatter = suggestions => itemIdOrValue => {
  if (isNil(itemIdOrValue)) {
    return '';
  }

  if (!isNumber(itemIdOrValue)) {
    return itemIdOrValue;
  }

  const foundSuggestion = suggestions.find(suggestion => {
    return suggestion.key === itemIdOrValue;
  });

  if (!foundSuggestion) {
    return itemIdOrValue;
  }

  return foundSuggestion.value;
}

export const phoneNumberFormatter = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  }

  return `+7${phoneNumber}`;
}

export const parseDate = (date) => {
  const defaultParse = {
    day: '', month: '', year: ''
  }

  const formattedDate = moment(date);

  if (!date || !formattedDate.isValid()) {
    return defaultParse;
  }

  return {
    day: formattedDate.format('DD'),
    month: formattedDate.format('MM'),
    year: formattedDate.format('YYYY'),
  };
}

export const composeDate = ({day = '', month = '', year = ''}) => {
  const composedDate = moment(
    `${year}-${month}-${day}`,
    'YYYY-MM-D',
    true
  )

  if (!composedDate.isValid()) {
    return null;
  }

  return composedDate.format('YYYY-MM-DD');
}
