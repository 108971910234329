import React from 'react';
import { UIPortal, UIAppSideBar } from '@UIKit';
import { useSwipeable } from "react-swipeable";
import './SideBar.scss';
import { CSSTransition } from 'react-transition-group';

export const SideBar = ({ sideBarOpen, toggleSideBar, className, toggleSignOutDialog }) => {
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => toggleSideBar()
  });

  return (
    <CSSTransition in={sideBarOpen} timeout={230} classNames="SideBar" unmountOnExit mountOnEnter>
      <UIPortal className="SideBar__Portal">
        <div className="SideBar" { ...handlers }>
          <div className="SideBar__Container">
            <UIAppSideBar
              className={className}
              toggleSideBar={toggleSideBar}
              toggleSignOutDialogs={toggleSignOutDialog}
            />
          </div>
        </div>
      </UIPortal>
    </CSSTransition>
  )
}
