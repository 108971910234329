import { createActions } from "redux-actions";
import * as API from "@api/loyalty";
import { initialState } from "@redux/reducers/loyalty";
import * as AuthAPI from "@api/auth";
import { SET_LOYALTY } from "../constants";
import { showNotification } from "@redux/actions/miscellaneous";
import { profileChanged, taskSubmitted, somethingGoesWrong, NotificationFail } from "@UIKit/utils/notifications";
import loyaltyIcon from "@images/design/loyalty.svg";
import attentionIcon from "@images/design/attention.svg";
import { pluralizePoints } from "@UIKit/utils/pluralize";
import { sortTasks, SortByEnum } from "@UIKit/utils/sorting";
import QS from "@UIKit/utils/query";

export const { setLoyalty } = createActions(SET_LOYALTY);

export const updateLoyaltyWallet = () => async (dispatch) => {
  try {
    const { data: wallet } = await API.getLoyaltyWallet();
    dispatch(setLoyalty({ wallet }));
  } catch (e) {
    throw e;
  }
};

export const fetchTasks = () => async dispatch => {
  try {
    const { data: tasks } = await API.fetchTasks();
    dispatch(setLoyalty({ tasks }));
    return tasks;
  } catch (e) {
    throw e;
  }
};

export const saveUser = (data) => async (dispatch) => {
  const { favoriteCategories, favoritePoints, ...user } = data;
  dispatch(
    setLoyalty({
      user,
      favoriteCategories,
      favoritePoints,
    })
  );
};

export const changeLoyaltyUser = () => async (dispatch, getState) => {
  const {
    form: {
      profile: { values },
    },
  } = getState();
  const promises = [API.changeUser(values)];

  try {
    const [{ data }] = await Promise.all(promises);
    dispatch(saveUser(data));
    dispatch(showNotification(profileChanged));
  } catch (e) {
    throw e;
  }
};

export const refreshLoyaltyUser = () => async (dispatch) => {
  try {
    const { data } = await AuthAPI.getUser();
    dispatch(saveUser(data));
  } catch (e) {
    throw e;
  }
};

export const loadTransactions = () => async (dispatch) => {
  try {
    const {
      data: { results: userTransactions },
    } = await API.loadTransactions();
    dispatch(setLoyalty({ userTransactions }));
  } catch (e) {
    throw e;
  }
};

export const loadUserTasks = () => async (dispatch, getState) => {
  const {
    loyalty: { userTasks },
  } = getState();

  if (userTasks.length) {
    return;
  }

  try {
    const res = await API.loadUserTasks();
    dispatch(setLoyalty({ userTasks: res.data.results }));
  } catch (e) {
    throw e;
  }
};

export const tryToLoadTasksDependsOnAuth = (location) => async (dispatch, getState) => {
  const {
    loyalty: { allTasks, favouriteTasks },
  } = getState();
  if (!allTasks.length && !favouriteTasks.length) {
    dispatch(loadTasksDependsOnAuth(location));
  }
};

export const tryToLoadPrizes = () => async (dispatch, getState) => {
  const {
    loyalty: { prizes }
  } = getState();

  if (!prizes.length) {
    return dispatch(loadPrizes());
  }
}

export const loadTasksDependsOnAuth = (location) => async (dispatch, getState) => {
  const {
    auth: { isAuthenticated },
  } = getState();
  const { ordering = SortByEnum.BY_DATE } = QS.parse(location.search);
  isAuthenticated ? dispatch(loadAllTasks({ ordering })) : dispatch(loadTasks({ ordering }));
};

export const loadTasks = (params) => async (dispatch) => {
  try {
    const res = await API.loadTasks(params);
    const allTasks = sortTasks(res.data.results, params.ordering);
    dispatch(setLoyalty({ allTasks }));
  } catch (e) {
    throw e;
  }
};

export const loadAllTasks = (tasksParams, favouriteTasksParams) => async (dispatch) => {
  try {
    const {
      data: { results: allTasks },
    } = await API.loadTasksAuthorized(tasksParams);
    const { data: favouriteTasksBins } = await API.loadFavouriteTasks(favouriteTasksParams);
    const favouriteTaskIds = favouriteTasksBins.reduce((acc, item) => acc.concat(item.task), []);
    const filteredTasks = allTasks.filter((task) => !favouriteTaskIds.includes(task.id));
    const favouriteTasks = allTasks.filter((task) => favouriteTaskIds.includes(task.id));

    dispatch(
      setLoyalty({
        allTasks: sortTasks(filteredTasks, tasksParams.ordering),
        favouriteTasks,
      })
    );
  } catch (e) {
    throw e;
  }
};

export const addToFavourites = (task) => async () => {
  try {
    return await API.addToFavourites([task.id]);
  } catch (e) {
    throw e;
  }
};

export const removeFromFavourites = (task) => async () => {
  try {
    return await API.removeFromFavourites([task.id]);
  } catch (e) {
    throw e;
  }
};

export const submitTask = (taskId, data) => async (dispatch) => {
  try {
    await API.submitTask(taskId, data);
    dispatch(showNotification(taskSubmitted));
  } catch (e) {
    dispatch(
      showNotification(
        new NotificationFail({
          title: "Ошибка",
          message: e.response.data.error,
          icon: attentionIcon,
          time: 2000,
        })
      )
    );
    throw e;
  }
};

export const initLoyalty = () => async (dispatch, getState) => {
  const {
    auth: { accessToken },
  } = getState();

  if (!accessToken) {
    return;
  }

  return await Promise.all([
    dispatch(refreshLoyaltyUser()),
    dispatch(updateLoyaltyWallet()),
    dispatch(loadPrizes()),
    dispatch(loadAllTasks({ ordering: SortByEnum.BY_DATE }))
  ]);
};

export const clearLoyalty = () => async (dispatch) => {
  return await Promise.all([
    dispatch(setLoyalty(initialState)),
    dispatch(loadPrizes())
  ]);
};

export const loadUserPrizes = () => async (dispatch) => {
  try {
    const res = await API.loadUserPrizes();
    dispatch(setLoyalty({ userPrizes: res.data.results }));
  } catch (e) {
    throw e;
  }
};

export const loadPrizes = () => async (dispatch) => {
  try {
    const res = await API.loadPrizes();
    dispatch(setLoyalty({ prizes: res.data.results }));
  } catch (e) {
    throw e;
  }
};

export const takePrize = (prize) => async (dispatch, getState) => {
  try {
    const res = await API.takePrize(prize.id);
    if (res.data) {
      dispatch(
        showNotification(
          new NotificationFail({
            title: `-${prize.points} ${pluralizePoints(prize.points)}`,
            message: "Приобретённый приз вы можете увидеть в разделе “Полученные призы”",
            icon: loyaltyIcon,
            time: 2000,
          })
        )
      );

      const { wallet } = getState().loyalty;
      dispatch(
        setLoyalty({
          wallet: { ...wallet, amount: res.data.wallet },
        })
      );
    }
    return res;
  } catch (e) {
    dispatch(showNotification(somethingGoesWrong));
    throw e;
  }
};
