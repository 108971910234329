import React from 'react'
import SVG from 'react-inlinesvg'
import { Link } from 'react-router-dom'
import {
  UIStepList,
  UIFab,
} from '@UIKit'
import { commonService } from '@services/commonService'
import ArrowRight from '@images/design/arrow-right.svg'


function PrizesPageTop({ onTasksClick, isAuthenticated }) {
  if (isAuthenticated) {
    return (
      <div className="PrizesPage__Description">
         Выполняйте задания, получайте баллы и обменивайте их на партнёрские призы
      </div>
    )
  }

  const isMobile = commonService.isMobileSize()
  const Layout = React.useMemo(() => {
    return isMobile
      ? <div>
          Выполняйте <Link className="Link--gold" to="/loyalty/tasks">задания</Link>
        </div>
      : <>
          Выполняйте задания <UIFab className="StepList__Fab" onClick={ onTasksClick }>
            <SVG src={ ArrowRight } />
          </UIFab>
        </>
  }, [isMobile, isAuthenticated])

  return (
    <UIStepList className="PrizesPage__StepList">
      <div>
        { Layout }
      </div>

      <div>
        Получайте баллы
      </div>

      <div>
        Обменивайте баллы на партнёрские призы
      </div>
    </UIStepList>
  )
}

export default PrizesPageTop
