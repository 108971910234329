import React from 'react';
import { UIInput } from '@UIKit';
import './Autocomplete.scss';
import * as autocompleteState from './AutocompleteState';
import { SuggestionsList } from './SuggestionList';
import { debounce, identity, get } from 'lodash';
import arrowDownIcon from '@images/arrow-down.svg';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export const Autocomplete =
  ({
    suggestions = [],
    handleChange = identity,
    onClick = identity,
    placeholder,
    label,
    className,
    readonly,
    selectedKey,
    invalid,
    listWidth = 200,
    type = 'autocomplete',
    renderIndex,
    alwaysShowAll = false,
    errorMessage,
    input,
    meta,
    required,
    selectedCity
  }) => {
    const [ state, dispatch ] = React.useReducer(
      autocompleteState.autocompleteReducer,
      autocompleteState.initialState
    );
    const inputRef = React.useRef(null);
    const isSelect = type === 'select';
    const isDropdown = type === 'dropdown';

    const updateValue = (value) => input && input.onChange(value);
    const getValue = () => input ? input.value : state.userInput;

    const handleBlur = () => {
      if (!state.showSuggestions) {
        return;
      }

      setTimeout(handleClickAway, 100);
    }

    const retrievedKey = React.useMemo(() => {
      return selectedKey || get(input, 'value', '');
    }, [selectedKey, input]);

    React.useEffect(() => {
      if (!retrievedKey || !suggestions.length) return;

      const foundSuggestion = suggestions
        .find(suggestion => suggestion.key === retrievedKey);

      if (!foundSuggestion) {
        return;
      }

      dispatch(autocompleteState.setState({ userInput: foundSuggestion.value }));
    }, [ retrievedKey, suggestions ]);

    const changeIfExist = debounce((userInput) => {
      const formattedUserInput = userInput.toLowerCase().trim();
      const foundSuggestion = suggestions.find(suggestion => {
        return suggestion.value.toLowerCase() === formattedUserInput;
      });

      if (foundSuggestion) {
        handleChange(foundSuggestion);
      }
    }, 200);

    const filteredSuggestions = value => {
      return suggestions.filter(
        suggestion =>  suggestion == null || String(suggestion.value.toLowerCase()).startsWith( value.toLowerCase() )
      );
    }

    const handleInputChange = e => {
      const userInput = e.currentTarget.value;
      dispatch(autocompleteState.inputChange(userInput, filteredSuggestions(userInput)));
      changeIfExist(userInput);
      updateValue(e);
    }

    const handleSuggesstionSelect = (suggestion) => {
      if (suggestion.key === -1) {
        dispatch(autocompleteState.selectSuggestion(''));
        input && input.onChange('');
        return inputRef.current.focus();
      }

      dispatch(autocompleteState.selectSuggestion(suggestion.value));
      handleChange(suggestion);
      updateValue(suggestion.key);
    }

    // Event fired when the user presses a key down
    const handleKeyDown = e => {
      const { activeSuggestion, filteredSuggestions } = state;

      // User pressed the enter key, update the input and close the
      // suggestions
      if (e.keyCode === 13) {
        const selectedSuggestion = filteredSuggestions[activeSuggestion];
        dispatch(autocompleteState.pressEnter(selectedSuggestion.value));
        handleChange(selectedSuggestion);
      }

      // User pressed the up arrow, decrement the index
      else if (e.keyCode === 38) {
        if (activeSuggestion === 0) {
          return;
        }
        dispatch(autocompleteState.setState({ activeSuggestion: state.activeSuggestion - 1 }));
      }
      // User pressed the down arrow, increment the index
      else if (e.keyCode === 40) {
        if (activeSuggestion - 1 === filteredSuggestions.length) {
          return;
        }
        dispatch(autocompleteState.setState({ activeSuggestion: state.activeSuggestion + 1 }));
      }
    };

    const renderSuggestions = () => {
      const {
        showSuggestions,
        filteredSuggestions
      } = state;

      if(!showSuggestions || !filteredSuggestions.length) {
        return null;
      }

      return (
        <SuggestionsList
          inputRef={inputRef}
          {...state}
          onClick={handleSuggesstionSelect}
          width={listWidth}
          renderIndex={renderIndex}
          isDropdown={isDropdown}
        />
      );
    }

    const handleOpen = () => {
      const { showSuggestions } = state;

      if ((isSelect || isDropdown || alwaysShowAll) && !showSuggestions) {
        dispatch(
          autocompleteState.setState({
            showSuggestions: true,
            filteredSuggestions: suggestions
          })
        );
      } else {
        dispatch(
          autocompleteState.setState({
            showSuggestions: true,
            filteredSuggestions: (alwaysShowAll || isSelect) ? suggestions : filteredSuggestions(getValue())
          })
        );
      }
    }

    const handleClickAway = () => {
      if (!state.showSuggestions) {
        return;
      }

      dispatch(autocompleteState.setState({
        showSuggestions: false,
        filteredSuggestions: []
      }));
    };

    const handleInputClick = (e) => {
      e.stopPropagation();
      handleOpen();
      onClick();
    }

    const handleArrowClick = () => {
      inputRef.current.focus();
      onClick();
    }

    const handleFocus = () => {
      const { showSuggestions } = state;
      if (showSuggestions) {
        return;
      }
      handleOpen();
    }

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={clsx("Autocomplete", className)}>
          <div onClick={handleOpen}>
            <UIInput
              type={type}
              label={label}
              invalid={invalid}
              ref={inputRef}
              isSelect={isSelect}
              isDropdown={isDropdown}
              placeholder={placeholder}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              readOnly={readonly}
              onClick={handleInputClick}
              onFocus={handleFocus}
              onBlur={handleBlur}
              autoComplete="new-password"
              errorMessage={errorMessage}
              required={required}
              input={input}
              meta={meta}
              value={state.userInput}
              selectedCity={selectedCity}
            />
            <div className="Autocomplete__ArrowIcon" onClick={handleArrowClick}><SVG src={arrowDownIcon} /></div>
          </div>
          { renderSuggestions() }
        </div>
      </ClickAwayListener>
    );
}
