import { APP_URL } from '../constants/config';
import api from './config'
import { commonService } from '../services/commonService';

export function fetchPoints(ids) {
  return api(`/points?ids=${ids}`);
}

export function markToDelete(id) {
  return api.post(`/points/${id}/mark_to_delete/`);
}

export function checkIn(id) {
  return api.post(`/points/${id}/checkin/`);
}

export function fetchAddress(search) {
  return api(`https://geocode-maps.yandex.ru/1.x/?format=json&sco=latlong&apikey=${commonService.API_KEY}&kind=locality&geocode=${search}`);
}

export function createPoint(data) {
  return api.post('/points/', data);
}

export function searchPoints(q, from_latitude = '', from_longitude = '', cancelTokenSource) {
  return api(`/points/?search=${q}&from_latitude=${from_latitude}&from_longitude=${from_longitude}`, {
    cancelToken: cancelTokenSource.token
  });
}

export function fetchCategories() {
  return api('/service_function_categories/');
}

export function fetchSubCategories() {
  return api('/service_function_subcategories/');
}

export function fetchServiceFunctions() {
  return api('/service_functions/');
}

export function fetchPolygons(url) {
  return api(`${APP_URL}${url}`);
}

export function fetchOnlinePoints(id, page) {
  return api(`/points/?city_id=${id}&is_online=true&wide=true${page ? '&page=' + page : ''}`);
}

export function fetchUrls() {
  return api('/urls/');
}
