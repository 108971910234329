import { createActions } from 'redux-actions';
import moment from 'moment'
import * as API from '@api/auth';
import { SET_AUTH } from '../constants';
import { CLIENT_ID, CLIENT_SECRET} from '@constants';
import { initLoyalty, clearLoyalty } from '@redux/actions/loyalty';
import { commonService } from '@services/commonService';
import { SubmissionError } from 'redux-form';
import { invalidCredentialsError } from '@UIKit/utils/errors';
import { providerMap } from '@UIKit/utils/maps';
import { showNotification } from '@redux/actions/miscellaneous';
import { forgotPasswordSent } from '@UIKit/utils/notifications';
import { validationErrors } from '@UIKit';
import { isUndefined } from 'lodash';
import { somethingGoesWrong } from '@UIKit/utils/notifications';

export const { setAuth } = createActions(SET_AUTH);

export const authenticate = ({ username, password }) => async (dispatch) => {
  const createdToken = await dispatch(createToken({
    username,
    password,
    grant_type: 'password',
  }));
  dispatch(saveToken(createdToken));
  try {
    await dispatch(initLoyalty());
  } catch(e) {
    dispatch(clearToken(createdToken));
    dispatch(showNotification(somethingGoesWrong))
  }
}

export const signIn = () => async (dispatch, getState) => {
  const { form: { signIn: { values } } } = getState();
  const { username, password } = values;

  try {
    return await dispatch(authenticate({username, password}));
  } catch (e) {
    throw new SubmissionError({
      username: invalidCredentialsError,
      password: invalidCredentialsError
    });
  }
}

export const socialSignIn = ({ provider, accessToken }) => async (dispatch) => {
  const formattedProvider = providerMap.get(provider);
  const createdToken = await dispatch(convertToken({
    backend: formattedProvider,
    token: accessToken
  }));

  dispatch(saveToken(createdToken));

  try {
    return await dispatch(initLoyalty());
  } catch (e) {
    dispatch(clearToken(createdToken));
    dispatch(showNotification(somethingGoesWrong))
  }
}

export const signOut = () => dispatch => {
  dispatch(clearToken());
  dispatch(clearLoyalty());
}

export const signUp = () => async (dispatch, getState) => {
  const { form: { signUp: { values } } } = getState();
  const { email, password, subscription } = values; 

  try {
    await API.registerUser({
      email,
      password,
      subscription
    });
  } catch (e) {
    const errors = e.response.data;
    throw new SubmissionError(errors)
  }
  try {
    await dispatch(authenticate({ username: email, password }))
  } catch (e) {
    throw e;
  }
}

export const signUpWithCompany = (company) => async (dispatch, getState) => {
  const { form: { signUp: { values } } } = getState();
  const { email, password } = values;

  try {
    await API.registerUser({
      email,
      password,
      company
    });
  } catch (e) {
    const errors = e.response.data;
    throw new SubmissionError(errors)
  }
  try {
    await dispatch(authenticate({ username: email, password }))
  } catch (e) {
    throw e;
  }
}

// export const signUpWithCompanyCola = () => async (dispatch, getState) => {
//   const { form: { signUp: { values } } } = getState();
//   const { email, password, company = [4] } = values;
  
//   try {
//     await API.registerUser({
//       email,
//       password,
//       company
//     });
//   } catch (e) {
//     const errors = e.response.data;
//     throw new SubmissionError(errors)
//   }
//   try {
//     await dispatch(authenticate({ username: email, password }))
//   } catch (e) {
//     throw e;
//   }
// }

export const addMoreCompany = (newCompany) => async (dispatch) => {
  try {
    const { data } = await API.getUser();
    const { company } = data;
    dispatch(addUserCompany(company, newCompany));
  } catch (e) {
    throw e;
  }
}

export const addUserCompany = (company, newCompany) => async () => {
  company.push(newCompany);
  try {
    await API.addCompanyUser({ 
      company
    });
  } catch (e) {
    throw e;
  }
}

export const signUpWithCompanyZil = () => async (dispatch, getState) => {
  const { form: { signUp: { values } } } = getState();
  const { email, password, company = [1, 7] } = values;
  
  try {
    await API.registerUser({
      email,
      password,
      company
    });
  } catch (e) {
    const errors = e.response.data;
    throw new SubmissionError(errors)
  }
  try {
    await dispatch(authenticate({ username: email, password }))
  } catch (e) {
    throw e;
  }
}

export const verificationCodeSend = () => async (dispatch) => {
  try {
    await API.sendVerification();
  } catch (e) {
    dispatch(showNotification(somethingGoesWrong));
  }
}

export const verificationCode = (codeReadyForCheck) => async (dispatch, getState) => {
  if (codeReadyForCheck !== undefined) {
    const { verification_code } = {verification_code: codeReadyForCheck};
    await API.checkVerification({ verification_code });
  } else {
    const { form: { verificationCode: { values } } } = getState();
    const { verification_code } = values;
  
    await API.checkVerification({ verification_code });
  }
}

export const changePassword = ({ email, code }) => async dispatch => {
  try {
    await API.changePassword({ email, code });
  } catch (e) {
    dispatch(showNotification(somethingGoesWrong));
  }
}

export const forgotPassword = ({ email }) => async (dispatch) => {
  try {
    await API.resetPassword({ email });
  } catch (e) {
    throw new SubmissionError({
      email: validationErrors.emailIsNotExist
    });
  }
  dispatch(showNotification(forgotPasswordSent));
}

export const createToken = ({...params}) => async () => {
  try {
    const { data } = await API.createToken({
      ...params,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET
    });
    return data;
  } catch(e) {
    throw e;
  }
}

export const convertToken = ({...params}) => async () => {
  try {
    const { data } = await API.convertToken({
      ...params,
      grant_type: 'convert_token',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET
    });
    return data;
  } catch(e) {
    throw e;
  }
}

export const saveToken = createdToken => (dispatch, getState) => {
  const { auth } = getState();
  const { accessToken, refreshToken, scope, expiresIn } = createdToken;
  const savedToken = {
    accessToken,
    refreshToken,
    scope,
    expiresIn,
    receivedDate: Date.now(),
    isAuthenticated: true
  }
  commonService.saveLocalStorage(savedToken);
  dispatch(setAuth({...auth, ...savedToken }));
}

export const clearToken = () => (dispatch, getState) => {
  const { auth } = getState();

  const clearedToken = {
    accessToken: '',
    refreshToken: '',
    expiresIn: '',
    scope: '',
    receivedDate: '',
    isAuthenticated: false
  };
  commonService.saveLocalStorage(clearedToken);
  dispatch(setAuth({...auth, ...clearedToken }));
}

export const updateToken = ({ refreshToken }) => async dispatch => {
  const createdToken = await dispatch(createToken({
    refresh_token: refreshToken,
    grant_type: 'refresh_token'
  }));
  dispatch(saveToken(createdToken));
}

export const handleRefreshToken = () => async dispatch => {
  const { refreshToken, expiresIn, receivedDate } = commonService.readLocalStorage([
    'refreshToken',
    'expiresIn',
    'receivedDate'
  ]);
  const deadline = moment(+receivedDate).add(+expiresIn, 'seconds');
  const now = moment();
  const shouldUpdateToken = now.isBefore(deadline)

  if (shouldUpdateToken) {
    try {
      await dispatch(updateToken({refreshToken}))
    } catch (e) {
      await dispatch(clearToken());
    }
  } else {
    await dispatch(clearToken());
  }
}

export const setGoBackUrl = (goBackUrl) => dispatch => {
  dispatch(setAuth({ goBackUrl }));
}

export const toggleSignOutDialog = (state) => (dispatch, getState) => {
  const { auth: { signOutDialogOpen } } = getState();
  const newState = isUndefined(state) ? !signOutDialogOpen : state;
  dispatch(setAuth({ signOutDialogOpen: newState }));
}
 