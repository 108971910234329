import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const UnauthorizedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route {...rest} render={({ location, ...rest }) => {
        const canActivate = !isAuthenticated || location.hash; // hash is not empty when jwt token comes
        return (
          canActivate
            ? <Component {...rest} location={location} />
            : <Redirect to={`/${location.search}`} />
        );
      }}
    />
  )
}