export const externalLinks = [
  {
    name: "+1Город",
    path: "https://gorod.plus-one.ru/",
    event: "link_to_media",
    form: "city",
    description:
      "Поможет найти полезные места рядом с вами. Также тут можно делать полезные и правильные действия получая баллы программы лояльности",
    links: [
    {
      title: "программа лояльности",
      href: "/loyalty/"
    },
    {
      title: "поиск и карта",
      href: "/categories/"
    }
    ]
  },
  {
    name: "+1Медиа",
    path: "https://plus-one.ru/",
    event: "link_to_media",
    form: "el",
    description:
      "Поможет вам узнать больше об устойчивом развитии. Также тут можно прочитать самые последние новости на важные темы",
    links: [
      {
        title: "сюжеты",
        href: "https://plus-one.ru/story"
      },
      {
        title: "инструкции",
        href: "https://plus-one.ru/manual"
      },
      {
        title: "устойчивое развитие",
        href: "https://plus-one.ru/sustainability"
      },
      {
        title: "блогеры",
        href: "https://plus-one.ru/platform"
      }
    ],
  },
  {
    name: "+1Платформа",
    path: "https://platform.plus-one.ru/",
    event: "link_to_platform",
    form: "sq",
    description:
      "Поможет вам поделиться вашими зананиями с большим миром. Также тут можно получить образование для корпоративных клиентов",
    links: [
      {
        title: "образование",
        href: "https://events.platform.plus-one.ru/courses"
      },
      {
        title: "мероприятия",
        href: "https://events.platform.plus-one.ru/"
      }
    ],
  },
  {
    name: "+1Визионеры",
    path: "https://award.plus-one.ru/",
    event: "link_to_fest",
    form: "el",
    description: "Поможет вам поделиться вашими зананиями с большим миромлиентов",
    links: [
      {
        title: "премия",
        href: "#"
      },
      {
        title: "конференция",
        href: "#"
      }
    ]
  },
  {
    name: "+1Люди",
    path: "https://people.plus-one.ru/",
    event: "link_to_ppl",
    form: "sq",
    description: "Поможет вам разобраться в благотворительности. Также тут можно помочь людям или попросить их помощи",
    links: [
      {
        title: "хочу помочь",
        href: "https://people.plus-one.ru/benefactors?by_location=assistant&search_by=name"
      },
      {
        title: "мне нужна помощь",
        href: "https://people.plus-one.ru/assistances/new"
      },
      {
        title: "справочник нко",
        href: "https://people.plus-one.ru/foundations?by_location=assistant&search_by=name"
      }
    ]
  },
];

