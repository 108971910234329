import React from 'react'
import clsx from 'clsx';
import './Hamburger.scss';
import SVG from "react-inlinesvg";
import login from "@images/svg/login-popup.svg";

export const Hamburger = React.memo(({ className, isPressed, onClick, isHidden }) => {
  const hamburgerClass = clsx("Hamburger", className, isPressed && 'Hamburger--active', isHidden && 'Hamburger--hidden');
  const handleClose = (evt) => {
    evt.stopPropagation();
    !isPressed && onClick();
  }
  return (
    <div className={hamburgerClass} onMouseDown={handleClose}>
      <SVG src={login}/>
    </div>
  )
});
