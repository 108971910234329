import React from "react";
import "./SignOutDialog.scss";
import { UIDialog } from "@UIKit";
import { UIRoundedButton } from "@UIKit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signOut } from "@redux/actions/auth";
import { withRouter } from "react-router-dom";
import { UIAppReturnLoyaltyButton } from "@UIKit";

const SignOutDialog = ({ isOpen, onClose, location, history, signOut }) => {
  const onCancel = () => onClose();

  const onAgree = () => {
    signOut();
    history.push(`/loyalty/prizes${location.search}`);
    onClose();
  };

  return (
    <UIDialog
      isOpen={isOpen}
      onClose={onClose}
      className="SignOutDialog"
      blurred={true}
      zIndex={12000}
      onBackdropClick={onCancel}
    >
      <div className="UIDialog__Wrapper SignOutDialog__Wrapper">
        <h1 className="UIDialog__Title">Покинуть профиль?</h1>
        <p className="UIDialog__Description">
          Вы уверены, что хотите покинуть профиль? Вы потеряете доступ к партнёрским призам, заданиям и личному
          кабинету.
        </p>
        <UIAppReturnLoyaltyButton onClick={onCancel} />
        <UIRoundedButton size="large" color="danger" onClick={onAgree}>
          Покинуть профиль
        </UIRoundedButton>
      </div>
    </UIDialog>
  );
};

const mapStateToDispatch = (dispatch) => bindActionCreators({ signOut }, dispatch);
export default connect(null, mapStateToDispatch)(withRouter(SignOutDialog));
