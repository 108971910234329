import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import "./SliderSlick.scss";
import { commonService } from "../../services/commonService";
import prize from "../../img/prize.jpg";
import { Link } from "react-router-dom";

export default function SimpleSlider(props) {
  const settingsTasks = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  const settingsCategories = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const prizes = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const features = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const prizesSlides = [
    {
      title: "Большой капучино в Макдоналдс",
      status: "заказ передан курьер",
      img: "#",
      id: 2,
    },
    {
      title: "Большой капучинов Макдоналдс",
      status: "заказ передан курьер",
      img: "#",
      id: 2,
    },
    {
      title: "Большой капучинов Макдоналдс",
      status: "заказ передан курьер",
      img: "#",
      id: 3,
    },
  ];

  const handleClick = (category) => {
    if (category.externalUrl) {
      const isAbsolute = commonService.isAbsoluteUrl(category.externalUrl);
      return isAbsolute ? window.open(category.externalUrl, "_blank") : props.goTo(category.externalUrl);
    }
    return props.goTo(`/filter/${category.slug}${props.location.search}`);
  };

  if (props.name === "tasks") {
    return (
      <div>
        <h2 className="slider__title">Задания</h2>
        <Slider className="slider-tasks" {...settingsTasks}>
          {props.dataSlider.map((obj) => (
            <div key={obj.id}>
              <div className="slide__title">{obj.title}</div>
              <div className="slide__description-block">
                <div className="slide__points-block">
                  <div className="slider-poi">{obj.points}</div>
                  <div className="slide__points">баллов</div>
                </div>
                <div className="slide__text">{ReactHtmlParser(obj.content)}</div>
              </div>
              <button className="buttonTask">Выполнить задание</button>
            </div>
          ))}
        </Slider>
      </div>
    );
  } else if (props.name === "categories") {
    return (
      <div>
        <Slider {...settingsCategories}>
          {props.dataSlider.map((category) => (
            <div key={category.id}>
              <div className="slide__wrapper">
                <div className="slide__wrapper-image">
                  <img className="slide__image" onClick={() => handleClick(category)} src={category.image}></img>
                </div>
                <div className="slide__title">{category.title}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  } else if (props.name === "feature") {
    const a = [];
    for (let obj in props.dataSlider) {
      a.push( props.dataSlider[obj]);
    }
    const {cities, filters} = props;

    const setUrl = (feature) => {
      const slug = feature.city?.length ? cities.find(city => feature.city[0] === city.id) : '';
      const task = feature.task ? feature.task : '';
      const service = feature.service ?  filters.find(service => feature.service === service.id) : '';
      
      let link = feature.externalUrl;
      feature.task && (link = `/loyalty/tasks/todotask/${task}?city=${slug.slug ? slug?.slug : ''}`);
      feature.service && (link = `/filter/${service.slug}?city=${slug.slug ? slug?.slug : ''}`);

      return link;
    }

    return (
      <div>
        <Slider {...features} className="slider-feature">
          {a.map((feature) => (
            <div>
             {(feature.task || feature.service) && <Link to={setUrl(feature)} className="slide__wrapper">
                <div className="slide__wrapper-image">
                  <img className="slide__image" src={feature.image}></img>
                </div>
              </Link>}
              {(!feature.task && !feature.service) && <a target="_blank" rel="noreferrer" href={setUrl(feature)} className="slide__wrapper">
                <div className="slide__wrapper-image">
                  <img className="slide__image" src={feature.image}></img>
                </div>
              </a>}
            </div>
          ))}
        </Slider>
      </div>
    );
  } else {
    return (
      <Slider {...prizes} className="slider-prizes mobile">
        {prizesSlides.map((slide) => (
          <div key={slide.id}>
            <div className="slide-prizes__wrapper">
              <div className="slide__wrapper-prizes-image">
                <img className="slide-prizes__image" src={prize} onClick={() => handleClick(slide)}></img>
              </div>
              <div className="slide-prizes__wrapper-text">
                <div className="slide-prizes__title">{slide.title}</div>
                <div className="slide-prizes__status">{slide.status}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}
