import { APP_URL } from '../constants/config';
import api from './config';

export function fetchCities() {
  return api('/cities/');
}

export function fetchTiles() {
  return api('/tiles/');
}

export function fetchTile(url) {
  return api(`${url}`, {
    baseURL: APP_URL,
  });
}
