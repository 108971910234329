import api from './config'

export function getLoyaltyWallet() {
  return api('/loyalty/wallet/');
}

export function changeUser(payload) {
  return api.put('/users/', payload);
}

export function fetchTasks() {
  return api('/loyalty/tasks_front/');
}

export function loadTasks(params) {
  return api.get('/loyalty/tasks/', { params });
}

export function loadTasksAuthorized(params) {
  return api.get('/loyalty/usertasksgroup/', { params });
}

export function loadFavouriteTasks(params) {
  return api.get('/loyalty/favourites/', { params })
}

export function addToFavourites(taskgroup) {
  return api.post(`/loyalty/favourites/`, { taskgroup })
}

export function removeFromFavourites(taskgroup) {
  return api.delete(`/loyalty/favourites/`, { data: { taskgroup } })
}

export function showTask(taskId) {
  return api.get(`/loyalty/tasks/${ taskId }/`)
}

export function submitTask(taskId, data) {
  return api.post(`/loyalty/task_actions/${ taskId }/do_task/`, data)
}

export function loadPrizes() {
  return api.get(`/loyalty/prizes/`)
}

export function loadUserPrizes() {
  return api.get(`/loyalty/userprizes/`)
}

export function loadUserTasks() {
  return api.get(`/loyalty/usertasks/`)
}

export function takePrize(prizeId) {
  return api.post(`/loyalty/prizes/${ prizeId }/take_prize/`)
}
export function loadTransactions() {
  return api.get('/loyalty/transactions/');
}
