import React from "react";
import "./Prix.scss";
import prix_in from "../../../../img/prix_in.jpg";
import { Link } from "react-router-dom";

const Prix = () => {
  return (
    <div className="prix">
      <div className="prix__container">
        <div className="prix__wrapper">
          <div className="prix__right-block">
            <div className="prix_in__wrapper-img">
              <img className="prix_in__img" src={prix_in} />
            </div>
          </div>
          <div className="prix__left-block">
            <div className="prix_in__title">Большой капучино в Макдональдс</div>
            <p>
              Ты стал обладателем приза от «Макдоналдс» - Сандвич «Биг Мак» Чтобы воспользоваться им, зайди в приложение
              Макдоналдс, в разделе "Акции" введи промокод в строке "Есть промокод? Введите!" <br />
              <br />
              После этого приложение сгенерирует QR-код, который ты сможешь предъявить на кассе или в терминале в любом
              удобном тебе Макдоналдс и забрать свой подарок. <br />
              <br /> Срок действия промокода - 31 декабря 2021 года <br />
              <br /> Спасибо, что ты делаешь мир лучше вместе с нами! До связи!
            </p>
            <Link to='/prix_in' className="prix_in__nav default">
              Случайно тыкнул
            </Link>
            <Link to='/loyalty/prize_congratulation' className="prix_in__nav">
              Далее
            </Link>
            <div className="prix__price-text">С вашего счета будет списано 1500 баллов</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prix;
