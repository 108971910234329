import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SVG from "react-inlinesvg";
import close from "@images/svg/close-black.svg";

export default function AlertDialog({ handleClose, handleClickOpen, open }) {
    return (
        <div>
            <Dialog
                 className='qwdqw'
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <SVG src={close} onClick={handleClose}/>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Вся информация, размещенная на веб-сайте и в приложении, включая статьи, тексты, фото-, аудио- и видеоматериалы, иллюстрации, дизайн сайта, а также подбор и расположение материалов является объектом авторских прав и охраняется в соответствии с законодательством о защите авторских прав.</p>
                        <p>Допускается цитирование материалов сайта в некоммерческих целях на другом сайте. Возле текстового блока, либо в его заголовке, либо в конце опубликованной перепечатки должен быть указан источник -  в виде активной, видимой пользователем и не закрытой от индексирования поисковыми системами гиперссылки. Попытки несанкционированного использования материалов преследуются в судебном порядке.</p>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}