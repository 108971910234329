import React from 'react';
import { UIShrinkingButton } from '@UIKit';
import './ShrinkingButtonGroup.scss';
import { useDebounce } from '@hooks/useDebounce';

export const ShrinkingButtonGroup = ({ items }) => {
  const [ hoveredButtonIndx, setHoveredButtonIndx ] = React.useState(0);
  const debouncedHoveredBtnIndx = useDebounce(hoveredButtonIndx, 50);
  const [ timeoutId, setTimeoutId ] = React.useState(null);

  const setHovered = (indx, button) => evt => {
    if (button.shouldStopPropagation && button.shouldStopPropagation(evt)) {
      return;
    }

    clearTimeout(timeoutId);
    indx !== hoveredButtonIndx && setHoveredButtonIndx(indx);
  }

  const isHovered = (indx) => indx === debouncedHoveredBtnIndx;
  React.useEffect(
    () => {
      requestAnimationFrame(() => setHoveredButtonIndx(items.findIndex(item => item.hovered) || 0))
    },
    [ ]
  );

  const handleMouseLeave = () => {
    const forceOpenIndex = items.findIndex(item => item.forceOpen);
    if (~forceOpenIndex) {
      setTimeoutId(setTimeout(() => {
        setHoveredButtonIndx(forceOpenIndex);
      }, 500));
    }
  }

  return (
    <div className="ShrinkingButtonGroup" onMouseLeave={handleMouseLeave} onBlur={handleMouseLeave}>
      { items.map((button, indx) => {
        return (
          <UIShrinkingButton
            key={indx}
            isHovered={isHovered(indx)}
            onMouseEnter={setHovered(indx, button)}
            { ...button }
          >
            { button.content }
          </UIShrinkingButton>
        )
      }) }
    </div>
  )
}
