import React from 'react';
import styled from 'styled-components'
import SVG from 'react-inlinesvg';

const Button = styled.button`
  width: 100%;
  font-size: 14px;
  line-height: 14px;
  padding: 12px 20px;
  background-color: #f4f4f4;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 19px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(229, 229, 229, 0.44);
`

const IconWrapper = styled.div`
  margin-right: 10px;
  max-height: 15px;
  > svg {
    width: 15px;
    height: 15px;
  }
`

export const RoundedButton = ({ children, handleClick, Icon, svgIcon, ...props }) => {
  return (
    <Button type="button"
      {...props}
      onClick={handleClick}>
      {Icon ? <IconWrapper><Icon /></IconWrapper> : null}
      {svgIcon ? <SVG src={svgIcon} /> : null}
      { children }
    </Button>
  )
}
