import React from "react";
import "./BusinessPartnerShip.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";

const BusinessPartnerShip = () => {
  return (
    <div className="business-partner-ship about-sub">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/smi">+1Город для малого бизнеса</Link>
        </div>
        <h1 className="about-sub__title">+1Город для малого бизнеса</h1>
        <div className="about-sub__text-big">
          <p>
            «Вокруг»— антикризисная программа взаимопомощи для локального бизнеса и населения на базе системы лояльности
            приложения +1Город.
          </p>
        </div>
        <div className="about-sub__text">
          <p>
            Локальные предприниматели получают доступ к возможностям карты +1Город: размещение точки на карте,
            информирование локальной аудитории об акциях и скидках, маркетинговые возможности в местных СМИ.
          </p>
          <p>
            +1Город призывает горожан покупать у локального бизнеса. Это позволит им не только сэкономить, но и
            сохранить привычный образ и уровень жизни, и, что не менее важно, это поможет бизнесу пережить кризис
          </p>
        </div>
        <div className="business-partner-ship__primary-block">
          <div className="business-partner-ship__text">Присоединяйся и открой возможности «Вокруг»!</div>
          <div className="business-partner-ship__links">
            <div>
              <a href="https://market.plus-one.ru/vokrug" target="_blank" rel="noreferrer">Подробнее о «Вокруг» для бизнеса</a>
            </div>
            <div>
              <Link to="/about/citizens_partnership">Подробнее о «Вокруг» для жителей</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessPartnerShip;
