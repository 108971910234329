import React from 'react';
import { bindActionCreators } from 'redux';
import './UserAccount.scss';
import SVG from 'react-inlinesvg';
import { UIUserAvatar } from '@UIKit';
import { UIRoundedButton } from '@UIKit';
import profileIcon from '@images/design/profile.svg';
import questsCompleteIcon from '@images/design/quests-complete.svg';
import coinsHistoryIcon from '@images/design/coins-history.svg';
import yourGifts from '@images/design/your-gifts.svg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadUserPrizes, loadUserTasks } from '@redux/actions/loyalty';
import { pluralizePoints } from '@UIKit/utils/pluralize';

const UserAccount = ({
  user,
  wallet,
  toggleSideBar,
  userPrizes,
  userTasks,
  loadUserPrizes,
  loadUserTasks,
  location,
  history
}) => {
  React.useEffect(() => {
    loadUserPrizes()
    loadUserTasks()
  }, [])

  const handleProfileClick = () => {
    if (location.pathname.includes('profile')) {
      history.push(`/${location.search}`);
    }
    setTimeout(() => {
      history.push(`/loyalty/profile${location.search}`);
      toggleSideBar();
    })
  }

  return (
    <div className="UserAccount">
      <button className="UserAccount__Header" onClick={handleProfileClick}>
        <div className="UserAccount__User">
          <div className="UserAccount__UserName">
            { user.fullname }
          </div>
          <div className="UserAccount__UserBalance">
            { `${wallet.amount} ${pluralizePoints(wallet.amount)}` }
          </div>
        </div>
        <UIUserAvatar user={user} className="UserAccount__UserAvatar" />
      </button>
      <div className="UserAccount__Menu">
        <UIRoundedButton
          color="link"
          className="UserAccount__MenuItem"
          to={{pathname: `/loyalty/profile`, search: location.search }}
          onClick={toggleSideBar}
        >
          <div className="AppSideBar__Link">
            <i className="AppSideBar__Icon"><SVG src={profileIcon} /></i> Профиль
          </div>
        </UIRoundedButton>

        { userTasks.length > 0 && (
          <UIRoundedButton color="link"
            className="UserAccount__MenuItem"
            to={{pathname: `/loyalty/user-tasks`, search: location.search }}
            onClick={toggleSideBar}
          >
            <div className="AppSideBar__Link">
              <i className="AppSideBar__Icon"><SVG src={questsCompleteIcon} /></i> Выполненные задания
            </div>
          </UIRoundedButton>
        )}

        <UIRoundedButton
          color="link"
          className="UserAccount__MenuItem"
          to={{pathname: `/loyalty/user-transactions`, search: location.search }}
          onClick={toggleSideBar}
        >
          <div className="AppSideBar__Link">
            <i className="AppSideBar__Icon"><SVG src={coinsHistoryIcon} /></i> История баллов
          </div>
        </UIRoundedButton>

        { userPrizes.length > 0 && (
          <UIRoundedButton
            color="link"
            className="UserAccount__MenuItem"
            to={{pathname: `/loyalty/user-prizes`, search: location.search }}
            onClick={toggleSideBar}
          >
            <div className="AppSideBar__Link">
              <i className="AppSideBar__Icon"><SVG src={yourGifts} /></i> Полученные призы
            </div>
          </UIRoundedButton>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ loyalty: { userPrizes, userTasks } }) => ({ userPrizes, userTasks })

const mapStateToDispatch = (dispatch) => bindActionCreators({
  loadUserPrizes,
  loadUserTasks,
}, dispatch)

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(UserAccount))
