import { SET_REVIEWS, RESET_REVIEWS } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

const initialState = {
  reviews: [],
  modalNewReview: false,
  content: '',
  authorName: '',
  authorEmail: '',
  compliance: false,
  isSent: false,
  error: false
};

export default makeReducer(initialState, SET_REVIEWS, (state, action) => {
  const { type } = action;
  switch (type) {
    case RESET_REVIEWS:
      return initialState;
    default:
      return state;
  }
});
