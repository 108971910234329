import React from 'react';
import { isEqual } from 'lodash';
import clusterIcon from "../img/marker2.svg";
import clusterActiveIcon from "../img/marker_active.svg";
import _debounce from "lodash.debounce";
import { commonService } from '../services/commonService';

const getIcons = (iconHref) => ([
  {
    href: iconHref,
    size: [43, 43],
    offset: [-21, -23]
  },
  {
    href: iconHref,
    size: [50, 50],
    offset: [-25, -25]
  }
]);

const defaultIcons = getIcons(clusterIcon);
const activeIcons = getIcons(clusterActiveIcon);

class PointHighlighter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previouslySelectedIds: []
    }

    this.activeClusters = [];
    this.throttledUpdatePoints = _debounce(this.updatePoints, 100);
  }

  clearActiveState = (objectManager, foundCluster) => {
    objectManager.clusters
      .setClusterOptions(+foundCluster.id, {
        clusterIcons: defaultIcons,
        clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
          '<div style="color: #000000;">{{ properties.geoObjects.length }}</div>'
        )
      });

    objectManager.clusters.setClusterProperties({
      isActive: false
    });
    const filteredClusters = this.activeClusters
      .filter(cluster => cluster.id !== foundCluster.id);
    this.activeClusters = filteredClusters;
  }

  handleActiveCluster = (objectManager, foundCluster) => {
    objectManager.clusters
      .setClusterOptions(+foundCluster.id, {
        clusterIcons: activeIcons,
        clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
          '<div style="color: #FFFFFF;">{{ properties.geoObjects.length }}</div>'
        ),
      });

    objectManager.clusters.setClusterProperties({
      isActive: true
    });

    this.activeClusters = [...this.activeClusters, foundCluster];
  }

  isActive(foundCluster) {
    return !!this.activeClusters.find(cluster => cluster.id === foundCluster.id);
  }

  setActiveState = (objectId, isActive) => {
    let foundFunction = null;

    const { map, pins, serviceFunctions } = this.props;


    map.geoObjects
      .each((objectManager) => {
        if (!(objectManager instanceof ymaps.LoadingObjectManager)) {
          return;
        }

        const foundPin = pins.find(obj => obj.id === objectId)
        const foundObj = objectManager.objects.getAll().find(obj => obj.id === objectId);

        if (!foundObj) {
          return
        }

        if (foundObj) {
          foundFunction = serviceFunctions
            .find(serviceFn => foundObj.properties.functions.includes(serviceFn.id));
        } else if (foundPin) {
          foundFunction = foundPin.functions[0];
        }

        if (!foundFunction) {
          return;
        }

        objectManager.objects
          .setObjectOptions(objectId, {
            iconImageHref: isActive ?
              foundFunction.markerActive :
              foundFunction.marker,
          });

        const clusters = objectManager.clusters.getAll();
        const foundCluster = clusters.find(({features}) => !!features.find(({id}) => objectId === id));

        if(isActive && foundCluster && !this.isActive(foundCluster)) {
          this.handleActiveCluster(objectManager, foundCluster);
        }
        if(!isActive && foundCluster && this.isActive(foundCluster)) {
          this.clearActiveState(objectManager, foundCluster);
        }
      });
  }

  updatePoints = () => {
    this.state.previouslySelectedIds.forEach(objectId => this.setActiveState(objectId, false));
    this.props.selectedPointIds.forEach(objectId => this.setActiveState(objectId, true));
  }

  handleInitiallySelectedEntries() {
    commonService.getObjectManagers(this.props.map)
      .forEach(objectManager => {
        objectManager.objects.events
          .add('objectsadd', this.throttledUpdatePoints);
      });
  }

  componentDidUpdate(prevProps) {
    const {
      selectedPointIds,
      map,
      serviceFunctions
    } = this.props;

    if (!prevProps.map && this.props.map) {
      this.handleInitiallySelectedEntries();
    }

    if (map && serviceFunctions.length &&
      !isEqual(selectedPointIds, this.state.previouslySelectedIds)
    ) {
      this.updatePoints();
      this.setState({previouslySelectedIds: selectedPointIds});
    }

    if (!prevProps.map && this.props.map) {
      map.events.add('boundschange', ({ originalEvent: { oldZoom, newZoom } }) => {
        if (oldZoom !== newZoom) {
          this.updatePoints();
        }
      });
    }
  }

  render() {
    return null;
  }
}
export default PointHighlighter;

