import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form'
import appState from './appState';
import map from './map';
import searchCity from './searchCity';
import points from './points';
import modalPin from './modalPin';
import reviews from './reviews';
import about from './about';
import blog from './blog';
import search from './search';
import onlinePoint from './onlinePoint';
import auth from './auth';
import loyalty from './loyalty';
import miscellaneous from './miscellaneous';
import modalProducts from './modalProducts';
import feature from './feature';

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  appState,
  map,
  searchCity,
  points,
  modalPin,
  modalProducts,
  feature,
  reviews,
  about,
  blog,
  search,
  onlinePoint,
  auth,
  loyalty,
  miscellaneous,
  form: formReducer
});

export default rootReducer;
