import React, {useEffect} from "react";
import { withRouter } from "react-router-dom";
import { UIMenu, UIMenuContent } from "@UIKit";
import { ExternalLinks } from "./ExternalLinks";
import SVG from "react-inlinesvg";
import clsx from "clsx";
import { UIPartnerMedia } from "@UIKit";
import { useDispatch } from "react-redux";
import { setIsOpenProductsModal } from "../../../../redux/actions/modalProducts";
import product from "@images/svg/product-icon.svg";
import odnoklassniki from "@images/svg/moreMenu-odnoklassniki.svg";
import youtube from "@images/svg/moreMenu-youtube.svg";
import vk from "@images/svg/moreMenu-vk.svg";
import facebook from "@images/svg/moreMenu-facebook.svg";
import instagram from "@images/svg/moreMenu-instagram.svg";

export const MoreMenu = withRouter(({ className, location, openCityDialog, history, selectedCity, toggleClick, isOpenModal }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    dispatch(setIsOpenProductsModal());
  };

  useEffect(() => {
    if(isOpen) {
      toggleMenu();
    }

  }, [location.pathname]);

  return (
    <div className={clsx(className, "AppMore")}>
      <span>
        <div onClick={toggleMenu} >
          <SVG src={product} />
        </div>
      </span>
      <UIMenu isOpen={isOpen} className="AppMore__Menu">
        <UIMenuContent>
          <ExternalLinks toggleClick={toggleMenu} className="UIMenu__option UIMenu__option--text" />
          <UIPartnerMedia className="UIMenu__option UIMenu__option--text" />
        </UIMenuContent>
        <div className="AppBar__social-links">
          {/* <a href="/" className="AppBar__social-link">
            <SVG src={facebook} />
          </a> */}
          <a href="https://vk.com/project_plus_one" target="_blank" rel="noreferrer"  className="AppBar__social-link">
            <SVG src={vk} />
          </a>
          <a href="https://www.youtube.com/c/Plusoneru" target="_blank" rel="noreferrer" className="AppBar__social-link">
            <SVG src={youtube} />
          </a>
          <a href="https://m.ok.ru/group/65760602488865" target="_blank" rel="noreferrer" className="AppBar__social-link">
            <SVG src={odnoklassniki} />
          </a>
        </div>
      </UIMenu>
    </div>
  );
});
