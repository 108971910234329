import axios from 'axios';
import { BASE_URL } from '@constants';


const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export const callApi = axios.create({
  headers: DEFAULT_HEADERS,
  baseURL: BASE_URL,
});

callApi.interceptors.request.use(function (config) {
  if (typeof window === 'undefined') {
    return config;
  }

  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    return config;
  }
  
  config.headers.Authorization =  accessToken ? `Bearer ${accessToken}` : '';
  return config;
});

export default callApi;
