import React from "react";
import "./LoyaltyProgram.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";
import phone from "@images/about-l-phone.jpg";

import yes from "@images/svg/yes-about.svg";
import medal from "@images/svg/medal-about.svg";
import letter from "@images/svg/letter-about.svg";
import clock from "@images/svg/clock-about.svg";
import playMarket from "@images/svg/google-play-footer.svg";
import appStore from "@images/svg/app-store-footer.svg";
import phoneJ from "@images/phone-about-l.jpg";

const LoyaltyProgram = () => {
  return (
    <div className="loyaltyProgram">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/loyalty_program">Программа лояльности</Link>
        </div>
        <h1 className="about-sub__title">Программа лояльности</h1>
        <div className="loyaltyProgram__wrapper">
          <div className="loyaltyProgram__inner-wrapper">
            <h2>Что это?</h2>
            <div className="loyaltyProgram__text">
              Программа лояльности — это полезные задания и интересные квесты, которые помогают приобрести полезные
              привычки, совершать полезные для города и окружающей среды действия и получать за это скидки и приятные
              подарки от партнеров проекта. Правила программы лояльности
            </div>
            <h2>Присоединяйся и получай призы!</h2>
            <div className="loyaltyProgram__text">
              Сдавай правильно отходы, добавляй новые сервисы на карту, участвуй в квестах, эковикторинах и других
              заданиях программы лояльности.
            </div>
            <ul>
              <li>
                <span>1</span>Зарегистрируйся в приложении и подтверди свой e-mail.
              </li>
              <li>
                <span>2</span>Выбери и выполни задание.
              </li>
              <li>
                <span>3</span>Загрузи отчет в карточку задания и отправь на модерацию.
              </li>
            </ul>
            <Link className="loyaltyProgram__link" to="/loyalty/new_point">
              Добавить точку
            </Link>
          </div>
          <div className="loyaltyProgram__desk">
            <img src={phoneJ} />
          </div>
        </div>
        <div>
          <h2>Как получить призы?</h2>
          <div className="loyaltyProgram__cards">
            <div className="loyaltyProgram__card">
              <div className="loyaltyProgram__wrapper-img">
                <SVG src={clock}></SVG>
              </div>
              <div className="loyaltyProgram__wrapper-text">
                <h3>Дождись модерации</h3>
                <div>Проверка выполненных заданий занимает не более 48 часов.</div>
              </div>
            </div>
            <div className="loyaltyProgram__card">
              <div className="loyaltyProgram__wrapper-img">
                <SVG src={yes}></SVG>
              </div>
              <div className="loyaltyProgram__wrapper-text">
                <h3>Накопи достаточно баллов</h3>
                <div>Баллы за успешно выполненное задание появляются в личном кабинете сразу после успешной модерации.</div>
              </div>
            </div>
            <div className="loyaltyProgram__card">
              <div className="loyaltyProgram__wrapper-img">
                <SVG src={medal}></SVG>
              </div>
              <div className="loyaltyProgram__wrapper-text">
                <h3>Выбери приз</h3>
                <div>В витрине призов ежедневно доступно огромное количество предложений от партнеров.</div>
              </div>
            </div>
            <div className="loyaltyProgram__card">
              <div className="loyaltyProgram__wrapper-img">
                <SVG src={letter}></SVG>
              </div>
              <div className="loyaltyProgram__wrapper-text">
                <h3>Обменяй баллы</h3>
                <div>Информация о получении приза придёт на твой e-mail.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="loyaltyProgram__prim-wrapper">
          <div className="loyaltyProgram__left">
            <div className="loyaltyProgram__left-text">Вместе с нами развивать устояйчивые практики легко!</div>
            <div className="loyaltyProgram__icons">
              <a href="https://play.google.com/store/apps/details?id=com.poleznygorod.cityplusone&hl=ru&gl=US&showAllReviews=true"><SVG className="loyaltyProgram__play-market" src={playMarket}></SVG></a>
              <a href="https://apps.apple.com/ru/app/%D1%8D%D0%BA%D0%BE-%D0%BF%D0%B5%D1%80%D0%B5%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0-%D1%83%D1%82%D0%B8%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F/id1505256876"><SVG className="loyaltyProgram__icons" src={appStore}></SVG></a>
            </div>
          </div>
          <div className="loyaltyProgram__right">
            <img src={phone}></img>
          </div>
        </div>
        <div className="loyaltyProgram__email">Если остались вопросы, пиши сюда: <a href={`mailto:service@plus-one.ru`}>service@plus-one.ru</a></div>
      </div>
    </div>
  );
};

export default LoyaltyProgram;
