import React from 'react'
import { UIRoundedButton } from '@UIKit'
import arrowTop from '@images/design/arrow-top.svg'
import arrowDown from '@images/design/arrow-down.svg'
import SVG from 'react-inlinesvg'
import './Expander.scss'
import { Collapse } from 'react-collapse';


function Expander({ children }) {
  const [open, setOpen] = React.useState(true)

  const handleToggle = React.useCallback(() => setOpen((open) => !open), [])
  
  return (
    <div className="Expander">
      <div className="Expander__Content">
        <Collapse isOpened={open} initialStyle={{height: 0, overflow: 'hidden'}}>
          { children }
        </Collapse>
      </div>
      <UIRoundedButton
        className="Expander__Button"
        onClick={ handleToggle }
      >
        <SVG src={ open ? arrowTop : arrowDown } />
      </UIRoundedButton>
    </div>
  )
}

export default Expander
