import React from 'react';
import ReactDOM from 'react-dom';

export class Portal extends React.Component {
  constructor({ renderIndex = 0, zIndex, className }) {
    super();

    Portal.zIndex = (zIndex || 10000) + renderIndex;
    Portal.className = className || 'portal-wrapper';

    this.state = {
      zIndex: this.zIndex,
      renderIndex: renderIndex
    }
    
    this.el = this.createElement();
  }

  createElement() {
    if (typeof document === 'undefined') {
      return null;
    }

    const el = document.createElement('div');
    el.className = Portal.className;
    el.style.zIndex = this.state.zIndex;

    return el;
  }

  get zIndex() {
    return Portal.zIndex++;
  }

  get portalEl() {
    if (typeof document === 'undefined') {
      return null;
    }

    return document.getElementById('portal');
  }

  static getDerivedStateFromProps({ renderIndex }, state) {
    return {
      ...state,
      renderIndex,
      zIndex: Portal.zIndex + renderIndex
    };
  }

  componentDidMount() {
    this.portalEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.portalEl.removeChild(this.el);
  }

  render() {
    if (!this.el) {
      return null;
    }

    if (+this.el.style.zIndex !== this.state.zIndex) {
      this.el.style.zIndex = this.state.zIndex;
    }

    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }
}
