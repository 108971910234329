import React from 'react';
import { withRouter } from 'react-router-dom';
import SVG from 'react-inlinesvg';
// import searchIcon from '@images/design/search.svg';
import './Button.scss';
import mapNav from "@images/svg/map-nav.svg";

const ButtonPanelText = React.memo(({ children }) => {
  return <div className="ButtonPanel__Text">{ children }</div>
})

export const AppButtonPanel = withRouter(React.memo(
  ({ selectedCity, openCityDialog }) => {

    return (
      <div className="city-local" onClick={openCityDialog}>
        <SVG src={mapNav} />
        <ButtonPanelText>{ selectedCity && selectedCity.title }</ButtonPanelText>
      </div>
    );
}));
