import { SET_MODAL_PIN, RESET_MODAL_PIN } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

const initialState = {
  showPinModal: false,
  pins: [],
  clusterIds: null,
  func: null,
  isPinLoading: true,
  isMarkedToDelete: false
};

export default makeReducer(initialState, SET_MODAL_PIN, (state, action) => {
  const { type } = action;
  switch (type) {
    case RESET_MODAL_PIN:
      return initialState;
    default:
      return state;
  }
});
