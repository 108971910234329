export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_MAP = 'SET_MAP';
export const GET_CITY = 'GET_CITY';
export const SET_SEARCH_CITY = 'SET_SEARCH_CITY';
export const GET_SEARCH_CITY = 'GET_SEARCH_CITY';
export const SET_POINTS = 'SET_POINTS';
export const SET_MODAL_PIN = 'SET_MODAL_PIN';
export const RESET_MODAL_PIN = 'RESET_MODAL_PIN';
export const GET_REVIEWS = 'GET_REVIEWS';
export const SET_REVIEWS = 'SET_REVIEWS';
export const RESET_REVIEWS = 'RESET_REVIEWS';
export const SEND_REVIEWS = 'SEND_REVIEWS';
export const SET_IS_OPEN_MODAL_PRODUCTS = 'SET_IS_OPEN_MODAL_PRODUCTS';
export const SET_IS_OPEN_MODAL_NAV = 'SET_IS_OPEN_MODAL_NAV';

export const SET_NEW_POINT = 'SET_NEW_POINT';
export const SEND_NEW_POINT = 'SEND_NEW_POINT';
export const RESET_NEW_POINT = 'RESET_NEW_POINT';

export const SET_ABOUT = 'SET_ABOUT';
export const SET_TASKS = 'SET_TASKS';

export const GET_POINTS = 'GET_POINTS';

export const GET_BLOG = 'GET_BLOG';
export const SET_BLOG = 'SET_BLOG';
export const RESET_BLOG = 'RESET_BLOG';

export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';

export const GET_ONLINE_POINTS = 'GET_ONLINE_POINTS';

export const GET_CORDS_CITY = 'GET_CORDS_CITY';

export const GET_URL_POINT = 'GET_URL_POINT';
export const MARK_TO_DELETE = 'MARK_TO_DELETE';

export const GET_PIN = 'GET_PIN';
export const CHECK_TILE = 'CHECK_TILE';
export const SET_ONLINE_POINTS = 'SET_ONLINE_POINTS';

export const SET_AUTH = 'SET_AUTH';
export const SET_LOYALTY = 'SET_LOYALTY';
export const SET_MISCELLANEOUS = 'SET_MISCELLANEOUS';
export const SET_FEATURE = 'SET_FEATURE';
