import { createActions } from 'redux-actions';
import * as API from '../../api/blog';
import { SET_BLOG,  RESET_BLOG } from '../constants';

export const { setBlog, resetBlog } = createActions(SET_BLOG, RESET_BLOG);

export const fetchBlog = id => async dispatch => {
  try {
    const res = await API.fetchBlog(id);
    dispatch(setBlog({ blog: res.data.results }));
    return res;
  } catch (e) {
    throw e;
  }
};
