import React from 'react'
import clsx from 'clsx'
import SVG from 'react-inlinesvg'
import clockIcon from '@images/design/clock.svg'
import stopIcon from '@images/design/stop.svg'
import './Shield.scss'

const ICONS = {
  clock: clockIcon,
  stop: stopIcon,
}

function renderType(type) {
  return <SVG className="Shield__Icon" src={ ICONS[type] } />
}

function Shield({
  className,
  type,
  color,
  children,
}) {
  const layout = React.useMemo(() => {
    if (children) return children
    return renderType(type)
  }, [type, color, children])

  return (
    <div
      className={
        clsx(
          'Shield',
          type && `Shield--${ type }`,
          color && `Shield--${ color }`,
          className
        )
      }
    >
      { layout }
    </div>
  )
}

export default React.memo(Shield)
