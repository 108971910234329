import React from 'react';

export const AppContext = React.createContext({
  map: null,
  setMap: () => {},
  screen: {
    width: 0,
    height: 0,
    isMobile: false
  }
});
