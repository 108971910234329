import Loadable from 'react-loadable';

export const About = Loadable({
  loader: () => import(/* webpackChunkName: "about" */ './components/About/modalAbout'),
  loading: () => null,
  modules: ['about'],
});

export const Filter = Loadable({
  loader: () => import('./components/modalFilter'),
  loading: () => null,
  modules: ['filter'],
})

export const ModalPin = Loadable({
  loader: () => import(/* webpackChunkName: "modalpin" */ './components/modalPin'),
  loading: () => null,
  modules: ['modalpin'],
});

export const SearchResultsPage = Loadable({
  loader: () => import('./components/pages/SearchResults/SearchResultsPage'),
  loading: () => null,
  modules: ['search'],
})

export const ReviewModal = Loadable({
  loader: () => import(/* webpackChunkName: "reviewmodal" */ './components/modalNewReview'),
  loading: () => null,
  modules: ['reviewmodal'],
});

export const BlogModal = Loadable({
  loader: () => import(/* webpackChunkName: "blogmodal" */ './components/modalBlog'),
  loading: () => null,
  modules: ['blogmodal'],
});

export const LoyaltyPrizesPage = Loadable({
  loader: () => import('./components/UIKit/pages/PrizesPage/PrizesPage'),
  loading: () => null,
  modules: ['loyaltyprizespage'],
});

export const PasswordChangedPage = Loadable({
  loader: () => import('./components/UIKit/pages/PasswordChangedPage/PasswordChangedPage'),
  loading: () => null,
  modules: ['passwordchangedpage'],
})

export const LoyaltyUserTransactionsPage = Loadable({
  loader: () => import('./components/UIKit/pages/UserTransactionsPage/UserTransactionsPage'),
  loading: () => null,
  modules: ['loyaltyusertransactionpage'],
});

export const Page404 = Loadable({
  loader: () => import('./components/UIKit/pages/Page404/Page404'),
  loading: () => null,
  modules: ['page404'],
});

export const LoyaltyUserPrizesPage = Loadable({
  loader: () => import('./components/UIKit/pages/UserPrizesPage/UserPrizesPage'),
  loading: () => null,
  modules: ['loyaltyuserprizespage'],
});

export const AddPointPage = Loadable({
  loader: () => import('./components/UIKit/pages/AddPointPage/AddPointPage'),
  loading: () => null,
  modules: ['addpointpage'],
});

export const LoyaltyUserTasksPage = Loadable({
  loader: () => import('./components/UIKit/pages/UserTasksPage/UserTasksPage'),
  loading: () => null,
  modules: ['loyaltyusertaskspage'],
});

export const MobileAppAdPage = Loadable({
  loader: () => import('./components/UIKit/pages/MobileAppAdPage/MobileAppAdPage'),
  loading: () => null,
  modules: ['mobileappadpage'],
})

export const FeedbackPage = Loadable({
  loader: () => import('./components/UIKit/pages/FeedbackPage/FeedbackPage'),
  loading: () => null,
  modules: ['feedbackpage'],
});

export const PolicyPage = Loadable({
  loader: () => import('./components/UIKit/pages/PolicyPage/PolicyPage'),
  loading: () => null,
  modules: ['policypage'],
});

export const LoyaltyTasksPage = Loadable({
  loader: () => import('./components/UIKit/pages/TasksPage/TasksPage'),
  loading: () => null,
  modules: ['taskspage'],
});

export const LoyaltyTaskPage = Loadable({
  loader: () => import('./components/UIKit/pages/TaskPage/TaskPage'),
  loading: () => null,
  modules: ['taskpage'],
});

export const LoyaltyPrizePage = Loadable({
  loader: () => import('./components/UIKit/pages/PrizePage/PrizePage'),
  loading: () => null,
  modules: ['prizepage'],
});


export const ProfilePage = Loadable({
  loader: () => import('./components/UIKit/pages/ProfilePage/ProfilePage'),
  loading: () => null,
  modules: ['profile'],
});

export const SignInPage = Loadable({
  loader: () => import('./components/UIKit/pages/SignInPage/SignInPage'),
  loading: () => null,
  modules: ['signin'],
});

export const SignUpPage = Loadable({
  loader: () => import('./components/UIKit/pages/SignUpPage/SignUpPage'),
  loading: () => null,
  modules: ['signup'],
});

export const SignUpPageThanks = Loadable({
    loader: () => import('./components/UIKit/pages/SignUpPageThanks/SignUpPageThanks'),
    loading: () => null,
    modules: ['signupthanks'],
  });

export const MailVerification = Loadable({
  loader: () => import('./components/UIKit/pages/MailVerification/MailVerification'),
  loading: () => null,
  modules: ['mailverification'],
});

export const MailVerificationFinish = Loadable({
  loader: () => import('./components/UIKit/pages/MailVerificationFinish/MailVerificationFinish'),
  loading: () => null,
  modules: ['mailverificationfinish'],
});

export const MailVerificationNotFinish = Loadable({
  loader: () => import('./components/UIKit/pages/MailVerificationNotFinish/MailVerificationNotFinish'),
  loading: () => null,
  modules: ['mailverificationnotfinish'],
});

export const B2BPage = Loadable({
  loader: () => import('./components/UIKit/landings/B2BPage'),
  loading: () => null,
  modules: ['b2b'],
});

export const B2CPage = Loadable({
  loader: () => import('./components/UIKit/landings/B2CPage'),
  loading: () => null,
  modules: ['b2c'],
});

export const SignUpGeekPicnicPage = Loadable({
    loader: () => import('./components/UIKit/landings/SignUpGeekPicnicPage'),
    loading: () => null,
    modules: ['signupgeekpicnic'],
});

export const SignUpMondelezPage = Loadable({
    loader: () => import('./components/UIKit/landings/SignUpMondelezPage'),
    loading: () => null,
    modules: ['signupgeekpicnic'],
});

export const SignUpCocaColaPage = Loadable({
  loader: () => import('./components/UIKit/landings/SignUpCocaColaPage'),
  loading: () => null,
  modules: ['signupcocacola'],
});

export const AuthCocaColaPage = Loadable({
  loader: () => import('./components/UIKit/pages/AuthCocaColaPage/AuthCocaColaPage'),
  loading: () => null,
  modules: ['authcocacola'],
});

export const SignUpZilPage = Loadable({
  loader: () => import('./components/UIKit/landings/SignUpZil/SignUpZilPage'),
  loading: () => null,
  modules: ['signupzil'],
});

export const SignUpEcoinPage = Loadable({
  loader: () => import('./components/UIKit/landings/EcoinPage/SignUpEcoinPage'),
  loading: () => null,
  modules: ['signupecoin'],
});

export const AuthEcoinPage = Loadable({
  loader: () => import('./components/UIKit/pages/AuthEcoinPage/AuthEcoinPage'),
  loading: () => null,
  modules: ['authecoin'],
});

export const SignUpGraziaPage = Loadable({
  loader: () => import('./components/UIKit/landings/GraziaPage/SignUpGraziaPage'),
  loading: () => null,
  modules: ['signupgrazia'],
});

export const AuthGraziaPage = Loadable({
  loader: () => import('./components/UIKit/pages/AuthGraziaPage/AuthGraziaPage'),
  loading: () => null,
  modules: ['authgrazia'],
});

export const SignUpFaceslacesPage = Loadable({
  loader: () => import('./components/UIKit/landings/FaceslacesPage/SignUpFaceslacesPage'),
  loading: () => null,
  modules: ['signupfaceslaces'],
});

export const AuthFaceslacesPage = Loadable({
  loader: () => import('./components/UIKit/pages/AuthFaceslacesPage/AuthFaceslacesPage'),
  loading: () => null,
  modules: ['authfaceslaces'],
});

export const SignUpNordicweeksPage = Loadable({
  loader: () => import('./components/UIKit/landings/NordicweeksPage/SignUpNordicweeksPage'),
  loading: () => null,
  modules: ['signupnordicweeks'],
});

export const AuthNordicweeksPage = Loadable({
  loader: () => import('./components/UIKit/pages/AuthNordicweeksPage/AuthNordicweeksPage'),
  loading: () => null,
  modules: ['authnordicweeks'],
});

export const WhyPage = Loadable({
  loader: () => import('./components/UIKit/landings/WhyPage'),
  loading: () => null,
  modules: ['why'],
});

export const LoyaltyPage = Loadable({
  loader: () => import('./components/UIKit//pages/LoyaltyPage/LoyaltyPage'),
  loading: () => null,
  modules: ['loyalty'],
});

export const ForgotPasswordPage = Loadable({
  loader: () => import('./components/UIKit/pages/ForgotPasswordPage/ForgotPasswordPage'),
  loading: () => null,
  modules: ['forgot-password'],
});

export const SocialFallbackPage = Loadable({
  loader: () => import('./components/UIKit/pages/SocialFallbackPage/SocialFallbackPage'),
  loading: () => null,
  modules: ['social-fallback'],
});

