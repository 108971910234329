import React, { useState } from "react";
import "./ExternalLink.scss";
import { Link } from "react-router-dom";
import SVG from 'react-inlinesvg';

import yio from '@images/svg/externalLinks-yio.svg'
import loginMap from '@images/svg/externalLinks-login-map.svg'
import arrow from '@images/svg/externalLinks-arrow.svg'

export const ExternalLink = (props) => {
  const [isActive, setIsActive] = useState(false);
  if (props.form === "city") {
    return (
      <div className="accordion-item accordion-item__city">
        <div className="accordion__header">
          <div className="accordion-item__poiner" onClick={() => props.toggleClick()}>
            <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line
                y1="-0.5"
                x2="24.3496"
                y2="-0.5"
                transform="matrix(0.82137 0.570396 -0.82137 0.570396 0 1)"
                stroke="#333333"
              />
              <line
                y1="-0.5"
                x2="24.3496"
                y2="-0.5"
                transform="matrix(0.82137 -0.570396 -0.82137 -0.570396 0 14.8889)"
                stroke="#333333"
              />
            </svg>
          </div>
          <div>
            <svg width="59" height="30" viewBox="0 0 59 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M29.8342 1.21796V28.7785H57.5384V1.21796H29.8342ZM50.1248 22.5758H38.2539V19.3822H42.3888V12.0103C41.7116 13.186 40.1423 13.6298 38.2311 13.7334V10.1446C40.6144 9.84338 42.1674 8.48948 42.7242 6.67889H46.1233V19.3822H50.1248V22.5758Z"
                fill="#060606"
              />
              <path
                d="M15.0779 3.49821e-07C12.0956 4.18991e-07 9.18035 0.879817 6.70074 2.52818C4.22112 4.17655 2.28855 6.51942 1.14743 9.2605C0.00631719 12.0016 -0.292085 15.0177 0.289963 17.9275C0.872011 20.8373 2.30836 23.5101 4.41737 25.6077C6.52638 27.7054 9.21331 29.1338 12.1384 29.7122C15.0634 30.2906 18.0952 29.9931 20.8502 28.8573C23.6053 27.7214 25.9599 25.7984 27.6163 23.3312C29.2727 20.864 30.1564 17.9636 30.1558 14.9968C30.1558 13.0271 29.7658 11.0767 29.008 9.257C28.2502 7.4373 27.1395 5.78392 25.7394 4.3913C24.3392 2.99868 22.677 1.8941 20.8477 1.14063C19.0184 0.387164 17.0578 -0.000425066 15.0779 3.49821e-07ZM22.0292 16.8624H16.9337V22.0255H13.2351V16.8624H8.14292V13.1343H13.2351V7.97452H16.9337V13.1343H22.0292V16.8624Z"
                fill="#060606"
              />
            </svg>
          </div>
          <div className="login-map" onClick={() => props.toggleClick()}>
            <SVG src={loginMap} />
          </div>
        </div>
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <a href={props.path}>{props.children}</a>
          <div className="accordion__more">Подробнее</div>
        </div>
        {(isActive || (!isActive && window.innerWidth >= 1023)) && (
          <div className="accordion-content">
            <div className="accordion__description">{props.description}</div>
            <div className="accordion__links">
              {props.links ? props.links.map((link) => <Link to={link.href} className="accordion__link">{link.title}</Link>) : null}
            </div>
            <a href="/" className="accordion__nav">
              <span>Перейти</span>
              <span>
               <SVG src={arrow} />
              </span>
            </a>
          </div>
        )}
      </div>
    );
  } else if (props.form === "sq") {
    return (
      <div className="accordion-item accordion-item__sq">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <a href={props.path}>{props.children}</a>
          <div>
            <SVG src={yio} />
          </div>
        </div>
        {(isActive || (!isActive && window.innerWidth >= 1023)) && (
          <div className="accordion-content">
            <div className="accordion__description">{props.description}</div>
            <div className="accordion__links">
              {props.links ? props.links.map((link) => <a href={link.href} className="accordion__link">{link.title}</a>) : null}
            </div>
            <a href="/" className="accordion__nav">
              <span>Перейти</span>
              <span>
              <SVG src={arrow} />
              </span>
            </a>
          </div>
        )}
      </div>
    );
  } else if (props.form === "el") {
    return (
      <div className="accordion-item accordion-item__el">
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <a href={props.path}>{props.children}</a>
          <div>
            <SVG src={yio} />
          </div>
        </div>
        {(isActive || (!isActive && window.innerWidth >= 1023)) && (
          <div className="accordion-content">
            <div className="accordion__description">{props.description}</div>
            <div className="accordion__links">
              {props.links ? props.links.map((link) => <a href={link.href} className="accordion__link">{link.title}</a>) : null}
            </div>
            <a href="/" className="accordion__nav">
              <span>Перейти</span>
              <span>
              <SVG src={arrow} />
              </span>
            </a>
          </div>
        )}
      </div>
    );
  }
};
