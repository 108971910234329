import React from 'react'
import clsx from 'clsx'
import { UIRoundedButton, UIInput, UIShield } from '@UIKit'
import { pluralizePoints } from '@UIKit/utils/pluralize'
import { TaskStatuses } from '../../../../enums/task-status'
import TaskFormList from './TaskFormList'


function renderStatus(data) {
  if (data.status === TaskStatuses.MODERATION) {
    return [<UIShield type="clock" color="secondary" />]
  }
  if (data.status === TaskStatuses.DECLINED) {
    return  [
      <UIShield type="stop" color="alert" />,
      'Задание не прошло модерацию'
    ]
  }
  return null
}

function getStatus(data, isPreview) {
  return React.useMemo(() => {
    if (!isPreview) return null
    const [icon, text] = renderStatus(data)
    if (icon) {
      return (
        <div className={ clsx('TaskDialog__Status', text && 'TaskDialog__Status--message') }>
          { icon }
          <span>
            { text }
          </span>
        </div>
      )
    }
  }, [])
}

function TaskForm({
  data,
  files,
  message,
  onFilesChange,
  onMessageChange,
  onSubmit,
  isPreview,
  isValid
}) {
  const shield = getStatus(data, isPreview)

  return (
    <div className="TaskDialog__Content">
      { shield }

      <h2 className="TaskDialog__Title">
        Задание: { data.points } { pluralizePoints(data.points) }
      </h2>

      <div
        className="TaskDialog__Description"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />

      { data.textAnswer &&
        <UIInput
          className="TaskDialog__Message"
          value={ message }
          onChange={ onMessageChange }
          placeholder="Введите текст"
          textarea
        />
      }

      <TaskFormList
        files={ files }
        amount={ data.photoAnswer }
        onFilesChange={ onFilesChange }
      />

      <TaskFormList
        files={ files }
        amount={ data.videoAnswer }
        startIndex={ data.photoAnswer }
        onFilesChange={ onFilesChange }
        placeholder="Загрузите или перетащите видео в окно"
        accept="video"
      />

      <div className="TaskDialog__Controls">
        <UIRoundedButton
          onClick={ onSubmit }
          disabled={ !isValid }
          color="secondary"
          size="large"
        >
          Отправить задание
        </UIRoundedButton>
      </div>
    </div>
  )
}

export default React.memo(TaskForm)
