import React from "react";
import clsx from "clsx";
import { UIExternalLink } from "@UIKit";
import { externalLinks } from "../common";
import { commonService } from '@services/commonService';

export const ExternalLinks = ({ className, toggleClick }) => {
  const handleClick = event => () => {
    commonService.sendEvent({ category: event });
  };

  return externalLinks.map((link, key) => (
    <UIExternalLink
      path={link.path}
      className={clsx("Link", className)}
      to={link.path}
      key={key}
      form={link.form}
      description={link.description}
      links={link.links}
      onClick={handleClick(link.event)}
      toggleClick={toggleClick}
    >
      {link.name}
    </UIExternalLink>
  ));
};
