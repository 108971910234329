import React from "react";
import "./DotsStage_4.scss";
import Action from './Action/Action';
import Measure from './Measure/Measure';
import Company from './Company/Company';
import CommunityOfDogLovers from './CommunityOfDogLovers/CommunityOfDogLovers';
import Other from './Other/Other';
import arrowLeft from "@images/svg/arrow-left-prize.svg";
import SVG from "react-inlinesvg";

const DotsStage_4 = ({ nextStep, prevStep, handleFormData, handleCreatePoint, values, errorNumber, saveAndContinueLater, cookies }) => {
  
  const handleSubmit = () => {
    nextStep();
  }

  const selectForm = () => {
    switch (values.pointTag) {
      case 'service':
        return (
          <Company errorNumber={errorNumber} handleSubmit={handleSubmit} prevStep={prevStep} handleFormData={handleFormData} values={values} cookies={cookies}/>
        );
      case 'event':
        return (
          <Measure handleSubmit={handleSubmit} prevStep={prevStep} handleFormData={handleFormData} values={values} />
        );
      case 'action':
        return (
          <Action handleSubmit={handleSubmit} prevStep={prevStep} handleFormData={handleFormData} values={values} />
        );
      case 'dog':
        return (
          <CommunityOfDogLovers handleSubmit={handleSubmit} prevStep={prevStep} handleFormData={handleFormData} values={values} />
        );
      case 'shop':
        return (
          <Other handleSubmit={handleSubmit} prevStep={prevStep} handleFormData={handleFormData} values={values} />
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className="dots-stage_3">
        <div className="dots-container">
          <a href="/" className="dots__arrowMap"><SVG src={arrowLeft}></SVG>На карту</a>
          <h1 className="dots__title">Добавь свою точку на карту!</h1>
          {selectForm()}
          <div onClick={saveAndContinueLater} className="dots__save-late">Сохранить и продолжить позже</div>
        </div>
      </div>
    </>
  )
};

export default React.memo(DotsStage_4);;
