import React from 'react';
import SocialLogin from 'react-social-login';
import clsx from 'clsx';

export const SocialButton = ({ children, triggerLogin, readOnly, className, onClick, ...rest }) => {
  const handleClick = () => {
    if (readOnly)
      return;

    onClick();
    triggerLogin()
  }
  return (
    <button type="button"
      className={clsx("SocialButton", readOnly && "SocialButton--readOnly", className)}
      onClick={handleClick}
      {...rest}
    >
      <span className="SocialButton__Icon">
        { children }
      </span>
    </button>
  )
}

export default SocialLogin(SocialButton);