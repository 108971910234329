import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { fromEvent, merge, combineLatest, Subject } from 'rxjs';
import { startWith, scan, map, takeUntil, tap } from 'rxjs/operators';
import styled, { css } from 'styled-components';
import { commonService } from '../services/commonService';

const TouchWrapperContainer = styled.div`
  width: 100%;
  // height: 50vh;
  position: relative;

  ${props => {
    return props.singleTouch && css`
      &::before {
        content: ' ';
        background-color: rgba(0, 0, 0, 0.66);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;
      }
    `}
  }
`

const TouchWrapperMessage = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  top: 0;
  z-index: 999;
  left: 0;
`

const TouchWrapperMessageContent = styled.div`
  width: 80%;
  line-height: 30px;
  text-align: center;
`

const getObservables = (el) => {
  const starts$ = fromEvent(el, 'touchstart')
  const moves$ = fromEvent(el, 'touchmove')
  const ends$ = fromEvent(el, 'touchend')

  return { starts$, moves$, ends$ }
}

export const TouchWrapper = forwardRef((props, ref) => {
  const elRef = useRef(null);
  const [singleTouch, setSingleTouch] = useState(false);

  useEffect(() => {
    if (!commonService.inIframe()) {
      return;
    }
    const countHandler$ = new Subject();
    const destroyer$ = new Subject();

    const { starts$, moves$, ends$ } = getObservables(elRef.current);

    moves$
      .pipe(takeUntil(destroyer$))
      .subscribe(() => countHandler$.next(count => ++count));

    const counterMoves$ = countHandler$.pipe(
      scan((count, fn) => fn(count), 0)
    );

    const startsCounter$ = starts$.pipe(map((e) => {
      return (fingerCount) => fingerCount += e.changedTouches.length;
    }));
    const endsCounter$ = ends$
      .pipe(
        map((e) => {
          return (fingerCount) => fingerCount -= e.changedTouches.length;
        }),
        tap(() => countHandler$.next(() => 0))
      );

    const fingerCount$ = merge(startsCounter$, endsCounter$)
      .pipe(
        scan((fingerCount, fn) => fn(fingerCount), 0),
        startWith(0)
      );

    const singleTouch$ = combineLatest(counterMoves$, fingerCount$)
    .pipe(
      map(([countMoves, fingerCount]) => fingerCount === 1 && countMoves > 2)
    );

    singleTouch$
      .pipe(takeUntil(destroyer$))
      .subscribe((isSingleTouch) => {
        setSingleTouch(isSingleTouch)
      });

    return () => {
      destroyer$.next();
    }
  }, [])

  return (
    <TouchWrapperContainer singleTouch={singleTouch} ref={elRef} >
      <div ref={ref} id={'map_container'} className="map_container" />
      {singleTouch && (
        <TouchWrapperMessage>
          <TouchWrapperMessageContent>
            Используйте 2 пальца для передвижения по карте
          </TouchWrapperMessageContent>
        </TouchWrapperMessage>
      )}
    </TouchWrapperContainer>
  )
})
