import React from "react";
import "./DotsStage_2.scss";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import { AppContext } from "../../../../../contexts/AppContext";
import NewMap from "../../../../../components/NewMap";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-left-prize.svg";
import {BpCheckedIcon} from './constants';


const DotsStage_2 = ({ nextStep, prevStep, handleFormData, values, setCookie, saveAndContinueLater }) => {
  return (
    <div className="dots-stage_2">
      <div className="dots-container">
      <a href="/" className="dots__arrowMap"><SVG src={arrowLeft}></SVG>На карту</a>
        <h1 className="dots__title">Добавление точки</h1>
        <div className="dots-stage_2__desc">Что ты хочешь добавить на карту?</div>
        <FormControl className="dots-stage_2__formControl">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={values.pointTag}
            name="radio-buttons-group"
            onChange={handleFormData('pointTag')}
          >
            <FormControlLabel className="dots__radio" value="service" control={<Radio checkedIcon={<BpCheckedIcon />} />} label="Организация" />
            <FormControlLabel className="dots__radio" value="event" control={<Radio checkedIcon={<BpCheckedIcon />} />} label="Меропирятие" />
            <FormControlLabel className="dots__radio" value="action" control={<Radio checkedIcon={<BpCheckedIcon />} />} label="Акцию" />
            {/* <FormControlLabel className="dots__radio" value="dog" control={<Radio checkedIcon={<BpCheckedIcon />} />} label="Сообщество любителей собак" /> */}
            <FormControlLabel value="shop" control={<Radio checkedIcon={<BpCheckedIcon />} />} label="Другое" />
          </RadioGroup>
        </FormControl>
        <div className="dots-stage_2__desc">Проверь, существует ли твоя точка
          на нашей карте.
        </div>
        <div className="dots-stage_2__map">
        <AppContext.Consumer>
              {(context) => {
                return (
                  <>
                    <NewMap {...context}/>
                  </>
                );
              }}
            </AppContext.Consumer>
        </div>
        <div className="dots__flex-container">
          <Link className="dots__button" onClick={nextStep}>Продолжить</Link>
          <Link className="dots__button dots__button--transparent" onClick={prevStep}>Назад</Link>
        </div>
        <div onClick={saveAndContinueLater} className="dots__save-late">Сохранить и продолжить позже</div>
      </div>
    </div>
  );
};

export default React.memo(DotsStage_2);
