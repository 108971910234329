import React from 'react';
import { UIInput } from '@UIKit';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { identity } from 'lodash';
import { Validators } from '@UIKit';
import { createTextMask } from 'redux-form-input-masks';
import './PhoneNumber.scss'

const phoneMask = createTextMask({
  pattern: '(999) 99-99-999',
});

export const PhoneNumber = React.memo(({ value, number, onPhoneChange = identity }) => {
  React.useEffect(() => {
    if (number && !Validators.phoneNumber(number) && number !== value) {
      onPhoneChange(number);
    }
  }, [ number ]);

  return (
    <div className="PhoneNumber__Fields">
      <Field name="code"
        component={UIInput}
        className="DatePicker__Input"
        placeholder="Код"
        readOnly={true}
      />
      <Field name="number"
        component={UIInput}
        className="DatePicker__Input"
        placeholder="Номер телефона"
        { ...phoneMask }
      />
    </div>
  )
});

const mapStateToProps = (state, { value, form }) => {
  return {
    initialValues: {
      code: '+7',
      number: value
    },
    form,
    number: formValueSelector(form)(state, 'number'),
  }
};

export default connect(mapStateToProps, null)(reduxForm({
  validate: values => {
    const errors = {
      values: ' '
    };

    if (values.number) {
      return {};
    }

    return errors;
  }
})(PhoneNumber));
