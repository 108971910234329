import moment from "moment";
import { filter } from "lodash/fp";
import _find from "lodash.find";
import { createActions } from "redux-actions";
import memoize from "fast-memoize";
import * as API from "../../api/points";
import axios from 'axios';

import {
  GET_ONLINE_POINTS,
  RESET_SEARCH_RESULT,
  SET_POINTS,
  SET_SEARCH_RESULT
} from "../constants";
import { fetchCities, setMap } from "./map";
import { setAppState, fetchConfig } from "./appState";
import { FeedbackStepEnum } from "../../enums/feedback-step";
import { SocialStepEnum } from "../../enums/social-step";
import { setModalPin, setFeedbackStep } from '../actions/modalPin';
import { commonService } from "../../services/commonService";
import { handleRefreshToken } from "@redux/actions/auth";
import { initLoyalty } from '@redux/actions/loyalty';
import { showNotification } from "@redux/actions/miscellaneous";
import { messageSent, somethingGoesWrong } from "@UIKit/utils/notifications";
import { SubmissionError } from "redux-form";

export const {
  setSearchResult,
  resetSearchResult,
  setPoints,
  getOnlinePoints
} = createActions(
  SET_SEARCH_RESULT,
  RESET_SEARCH_RESULT,
  SET_POINTS,
  GET_ONLINE_POINTS
);

export const fetchPoints = ids => async dispatch => {
  try {
    const res = await API.fetchPoints(ids);
    const points = res.data.results;
    // dispatch(fetchBlog(ids));
    // dispatch(fetchReviews(ids));

    const pointsWithSteps = points.map(p => ({
      ...p,
      feedbackStep: FeedbackStepEnum.FOUND_INACCURACY,
      socialStep: SocialStepEnum.SHARE_IT,
      // articleUrl: 'https://ivsezaodnogo.ru/foundations/156',
      // fundUrl: 'https://ivsezaodnogo.ru/foundations/156',
      // blogUrl: 'https://platform.plus-one.ru/organizations/223-blagotvoritelnyy-fond-vrachebnoe-bratstvo',
      isCheckedIn: false,
      isContactsShown: false
    }))

    dispatch(setModalPin({
      pins: pointsWithSteps,
      showPinModal: true
    }));

    return res;
  } catch (e) {
    throw e;
  }
};

export const tryToFetchPoints = ids => (dispatch, getState) => {
  const { modalPin: { pins } } = getState();

  if (pins.length) {
    return;
  }

  dispatch(fetchPoints(ids));
}

export const markToDelete = id => async dispatch => {
  try {
    await API.markToDelete(id);
    dispatch(setFeedbackStep(id, FeedbackStepEnum.THANK_YOU));
  } catch (e) {
    dispatch(setFeedbackStep(id, FeedbackStepEnum.THANK_YOU));
    throw e;
  }
}

export const getPointFromUrl = ids => async dispatch => {
  try {
    const res = await dispatch(fetchPoints(ids));
    const [ firstPoint ] = res.data.results;

    if (firstPoint.latitude && firstPoint.longitude) {
      dispatch(
        setMap({
          center: [firstPoint.latitude, firstPoint.longitude],
          currentCenter: [firstPoint.latitude, firstPoint.longitude],
          zoom: 18
        })
      );
    }
    dispatch(setPoints({ isFilter: false }));
    dispatch(setModalPin({
      func: firstPoint.functions[0]
    }));
    return res;
  } catch (e) {
    throw e;
  }
};

let cancelTokenSource;
export const cancelSearch = () => () => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel()
  }
}

export const searchPoints =
  (searchText, from_latitude, from_longitude) => async dispatch => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
      setTimeout(() => dispatch(setAppState({ isSearching: true })));
    }

    cancelTokenSource = axios.CancelToken.source();

    dispatch(setSearchResult({ modalSearchResult: true }));
    dispatch(setAppState({ isSearching: true }));
    try {
      const res = await API.searchPoints(searchText, from_latitude, from_longitude, cancelTokenSource);
      dispatch(setSearchResult({ searchResult: res.data.results }));
      return res;
    } catch (e) {
      throw e;
    } finally {
      cancelTokenSource = null;
      dispatch(setAppState({ isSearching: false }));
    }
  };

export const getPins = ids => dispatch => {
  if (ids.length) {
    dispatch(setModalPin({ clusterIds: ids }));
  }
};

const fetchCategories = () => async dispatch => {
  try {
    const res = await API.fetchCategories();
    const changedCategories = res.data.results.map(category => {
      return {
        ...category,
        isActive: false
        // externalUrl: 'https://platform.plus-one.ru/organizations/220-pivovarennaya-kompaniya-baltika'
      }
    })
    dispatch(setPoints({ categories: changedCategories }));
    return res;
  } catch (e) {
    throw e;
  }
};

export const handleCategorySelection = () => (dispatch, getState) => {
  const { points: { subcategories, categories } } = getState();
  const selected = subcategories.find(subcat => subcat.selected);
  if (!selected) {
    return dispatch(restoreCategorySelection());
  }
  const categoryId = selected.categories[0].id;
  const changedCategories = categories.map(category => ({
    ...category,
    isActive: category.id === categoryId
  }));
  dispatch(setPoints({ categories: changedCategories }));
}

export const restoreCategorySelection = () => (dispatch, getState) => {
  const { points: { categories } } = getState();
  dispatch(
    setPoints({
      categories: categories.map(category => ({...category, isActive: false}))
    })
  );
}

export const selectCategory = (slug) => (dispatch, getState) => {
  const { points: { categories } } = getState();
  const selectedCategory = categories.find(category => category.slug === slug);
  dispatch(
    setPoints({
      categories: categories.map(category => ({...category, isActive: category.id === selectedCategory.id}))
    })
  );
}

export const unselectCategory = () => (dispatch) => {
  dispatch(restoreCategorySelection());
  dispatch(setPoints({ categoryFilters: [] }));
}

export const applySelectionByQuery = (queryStr, slug = '') => (dispatch, getState) => {
  const { points: { subcategories } } = getState();

  const { tagFilter, categoryFilterSlugs, subcategorySlugs } = commonService
    .parseQueryString(queryStr);

  const clearedSubcategories = commonService
    .clearSelectionSubCategories(subcategories)

  const clearedSubcategoriesFilter = clearedSubcategories
    .filter(sub => {
      return (sub.categories[0] !== undefined);
    })
  
  const isSubCatSelected = (sub) => {
    return (!subcategorySlugs.length && slug && (sub.categories[0].slug === slug)) || subcategorySlugs.includes(sub.slug)
  }

  const changedSubcategories = clearedSubcategoriesFilter
    .map(sub => {
      if (slug && sub.categories[0].slug !== slug) {
        return { ...sub };
      }

      return {
        ...sub,
        selected: subcategorySlugs.includes(sub.slug)
      }
    });

  const changedCategoryFilters = changedSubcategories.reduce((filters, sub) => {
    if (!isSubCatSelected(sub)) {
      return filters;
    }

    return sub.servicefunctionSet.reduce((fnSetAcc, fn) => ({
      ...fnSetAcc,
      [fn.id]: {
        ...fn,
        enabled: sub.selected,
        selected: categoryFilterSlugs.includes(fn.slug)
      }
    }), filters);
  }, {});

  dispatch(setPoints({
    tagFilter,
    subcategories: changedSubcategories,
    categoryFilters: changedCategoryFilters
  }));
}

const fetchSubCategories = () => async dispatch => {
  try {
    const res = await API.fetchSubCategories();
    const subcategories = res.data.results
      .map((subcategory) => {
        return {
          ...subcategory,
          servicefunctionSet: [
            ...subcategory.servicefunctionSet.filter(sf => sf.isActive)
          ]
        }
      });

    dispatch(setPoints({ subcategories }));
    return res;
  } catch (e) {
    throw e;
  }
};

export const fetchServiceFunctions = () => async dispatch => {
  try {
    // const res = await axios(`${BASE_URL}/service_functions/`, {
    //   headers: {
    //     ...(process.env.NODE_ENV === "production" && { "X-Forwarded-Host": window.location.host })
    //   }
    // });
    const res = await API.fetchServiceFunctions();
    dispatch(setPoints({ filters: res.data.results }));
    return res;
  } catch (e) {
    throw e;
  }
};

const fetchUrls = () => async dispatch => {
  try {
    const res = await API.fetchUrls();
    dispatch(setAppState({ urls: res.data.results }));
    return res;
  } catch (e) {
    throw e;
  }
};

export const createPoint = (values) => async dispatch => {
  try {
    await API.createPoint(values);
    dispatch(showNotification(messageSent));
  } catch (e) {
    dispatch(showNotification(somethingGoesWrong));
    const errors = e.response.data;
    throw new SubmissionError(errors);
  }
}

export const loadData = () => async dispatch => {
  await dispatch(handleRefreshToken())
  await dispatch(fetchCities());

  await Promise.all([
    dispatch(fetchConfig()),
    dispatch(fetchServiceFunctions()),
    dispatch(fetchCategories()),
    dispatch(fetchSubCategories()),
    dispatch(fetchUrls()),
  ]);
  dispatch(initLoyalty());
  dispatch(updateHasOnlinePoints());

  dispatch(setAppState({ isLoaded: true }));
};

export const loadSubCats = () => async dispatch => {
  await Promise.all([dispatch(fetchSubCategories())]);
};

export const loadOnlinePoints = () => (dispatch, getState) => {
  const {
    map: { city, cities }
  } = getState();
  if (city && cities.length) {
    const cityId = _find(cities, ["title", city]).id;
    dispatch(loadOnlinePointsForCity(city, cityId));
  }
};

export const loadOnlinePointsForCity = (city, cityId) => async (dispatch, getState) => {
  const points = [];
  let page = '';
  while (true) {
    try {
      const res = await API.fetchOnlinePoints(cityId, page);
      points.push(...res.data.results);
      if (!res.data.next) {
        break;
      };
      const params = new URLSearchParams(res.data.next);
      page = params.get('page');
    } catch (e) {
      break;
    }
  }

  let filteredPoints = filter(
    point =>
      point.activeDateTo
        ? moment().isBefore(point.activeDateTo)
        : point.activeDateFrom
        ? moment().isAfter(point.activeDateFrom)
        : true,
    points
  );

  filteredPoints.sort(function(a, b) {
    var nameA = a.title.toLowerCase().replace(/['"«]+/g, ""),
      nameB = b.title.toLowerCase().replace(/['"«]+/g, "");
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  });
  dispatch(setPoints({ cityToOnlinePointsMap: filteredPoints }));
};

export const updateHasOnlinePoints = () => (dispatch, getState) => {
  const {
    points: { onlineServiceOpen },
    map: { city, cities }
  } = getState();
  if (cities.length && city) {
    const hasOnlinePoints = memoize(c => _find(cities, ["title", c]).hasOnlinePoints)(city);
    dispatch(setMap({ hasOnlinePoints }));
    if (!hasOnlinePoints) {
      if (onlineServiceOpen) {
        dispatch(setPoints({ onlineServiceOpen: false }));
      }
    }
  }
};
