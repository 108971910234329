import React from 'react';
import clsx from 'clsx';
import { UIRoundedButton } from '@UIKit';
import { withRouter } from 'react-router-dom';
import UserAccount from './UserAccount/UserAccount';

export const UserAuth = React.memo(({ toggleSideBar, location }) => {
  return (
    <>
      <UIRoundedButton
        color="white"
        size="large"
        to={{
          pathname: `/sign-in`,
          search: location.search,
          state: { previousPath: `${location.pathname}${location.search}` }
        }}
        onClick={toggleSideBar}
      >
        Войти
      </UIRoundedButton>
      <UIRoundedButton
        color="transparent"
        size="large"
        to={{
          pathname: `/sign-up`,
          search: location.search,
          state: { previousPath: `${location.pathname}${location.search}` }
        }}
        onClick={toggleSideBar}
      >
        Регистрация
      </UIRoundedButton>
    </>
  );
})

export const UserPanel = withRouter(({className, user, wallet, ...props}) => {
  return (
    <div className={clsx("UserPanel", className)}>
      { (user && wallet)
        ? <UserAccount user={user} wallet={wallet} {...props} />
        : <UserAuth {...props} /> }
    </div>
  )
})