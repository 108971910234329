import React from 'react'
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
import { UIRoundedButton } from '@UIKit'
import { commonService } from '@services/commonService';
import imageAdd from '@images/design/image-add.svg'
import iconCross from '@images/svg/close-black.svg'
import SVG from 'react-inlinesvg'
import './Dropzone.scss'


const ACCEPT_TYPES = {
  images: 'image/*',
  video: 'video/*',
}


function Preview({ video, src }) {
  return (
    <div className="Dropzone__Preview">
      { video
        ? <video controls>
            <source src={ src } />
          </video>
        : <img src={ src } alt="" />
      }
    </div>
  )
}

function Dropzone({
  value,
  name,
  onChange,
  onDelete,
  placeholder = 'Загрузите или перетащите фото в окно',
  accept = 'images'
}) {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: ACCEPT_TYPES[accept],
    onDrop: (acceptedFiles) => onChange(acceptedFiles, name)
  })

  const preview = value ? URL.createObjectURL(value) : null
  const isVideo = ACCEPT_TYPES[accept] === ACCEPT_TYPES.video
  const isMobileDevice = commonService.isMobileDevice()

  return (
    <section className={ clsx('Dropzone', isMobileDevice && 'Dropzone--mobile') }>
      { preview &&
        <UIRoundedButton
          className="Dropzone__Close"
          color="secondary"
          onClick={ () => onDelete(null, name) }
        >
          <SVG src={ iconCross } />
        </UIRoundedButton>
      }

      <div { ...getRootProps({ className: 'Dropzone__Area' }) }>
        <input { ...getInputProps() } style={{ display: isMobileDevice ? 'block' : 'none' }} />

        { preview
          ? <Preview src={ preview } video={ isVideo } />
          : <div className="Dropzone__Placeholder">
              <SVG
                className="Dropzone__Icon"
                src={ imageAdd }
              />
              { placeholder &&
                <p className="Dropzone__Text">
                  { placeholder }
                </p>
              }
            </div>
        }
      </div>
    </section>
  )
}

export default Dropzone
