import { createActions } from "redux-actions";
import { SET_MODAL_PIN, RESET_MODAL_PIN } from '../constants';
import * as API from '../../api/points';

export const { setModalPin, resetModalPin } = createActions(SET_MODAL_PIN, RESET_MODAL_PIN);

export const showContacts = pointId => (dispatch, getState) => {
  const state = getState();
  const pins = state.modalPin.pins;
  const foundIndex = pins.findIndex(p => p.id === pointId);
  const foundPin = pins[foundIndex];

  if (foundPin.isContactsShown) {
    return;
  }
  dispatch(
    setModalPin({ pins: [
      ...pins.slice(0, foundIndex),
      {
        ...foundPin,
        isContactsShown: true,
      },
      ...pins.slice(foundIndex + 1)
    ]})
  )
}

export const checkIn = pointId => async (dispatch, getState) => {
  const state = getState();
  const pins = state.modalPin.pins;
  const foundIndex = pins.findIndex(p => p.id === pointId);
  const foundPin = pins[foundIndex];

  if (foundPin.isCheckedIn) {
    return;
  }
  try {
    await API.checkIn(pointId);
    dispatch(
      setModalPin({ pins: [
        ...pins.slice(0, foundIndex),
        {
          ...foundPin,
          isCheckedIn: true,
          checkinCount: foundPin.checkinCount + 1,
          dtLastCheckin: Date.now()
        },
        ...pins.slice(foundIndex + 1)
      ]})
    );
  } catch (e) {

  }
}

export const setFeedbackStep = (pointId, feedbackStep) => (dispatch, getState) => {
  const state = getState();
  const pins = state.modalPin.pins;
  const foundIndex = pins.findIndex(p => p.id === pointId);
  const foundPin = pins[foundIndex];
  
  const changedPins = [
    ...pins.slice(0, foundIndex),
    { ...foundPin, feedbackStep },
    ...pins.slice(foundIndex + 1)
  ];
  dispatch(setModalPin({ pins: changedPins}))
}

export const setSocialStep = (pointId, socialStep) => (dispatch, getState) => {
  const state = getState();
  const pins = state.modalPin.pins;
  const foundIndex = pins.findIndex(p => p.id === pointId);
  const foundPin = pins[foundIndex];
  dispatch(setModalPin({ pins: [
    ...pins.slice(0, foundIndex),
    { ...foundPin, socialStep },
    ...pins.slice(foundIndex + 1)
  ]}))
}
