import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, render, isAuthenticated, ...rest }) => {
  const renderComponent = (props) => {
    return render ? render(props) : <Component {...props} />
  }
  return (
    <Route {...rest} render={props => (
      isAuthenticated
        ? renderComponent(props)
        : <Redirect to='/sign-in' />
    )} />
  )
  
}