import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { YMInitializer } from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import getDisplayName from './getDisplayName';
import { isCorrectUrl } from '../../redux/middlewares/analytics';

const tagManagerArgs = {
  gtmId: 'GTM-N6Z7SFV'
};

const withTracker = (WrappedComponent, props) => {
  class WithTracker extends Component {

    componentDidMount() {
      ReactGA.initialize('UA-86584410-12', ['auto', {'allowLinker': true}]);

      if (isCorrectUrl(window.location.pathname + window.location.search)) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }

      TagManager.initialize(tagManagerArgs);
    }

    render() {
      return (
        <>
          <YMInitializer accounts={[49990771]} options={{
            webvisor: true,
            accurateTrackBounce: 60 * 1000
          }} version="2" />
          <WrappedComponent {...this.props} {...props } />
        </>
      );
    }
  }
  WithTracker.displayName = `withTracker(${getDisplayName(WrappedComponent)})`;
  return WithTracker;
};

export default withTracker;
