import './BigFormDots.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { createPoint } from '../../../../redux/actions/points';
import { Validators } from '@UIKit';
import SVG from 'react-inlinesvg';
import arrowLeft from '@images/svg/arrow-left-prize.svg';
import star from '@images/svg/star_req.svg';
import { sortBy, pickBy } from 'lodash';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {BpCheckedIcon} from './constants';
import { useHistory } from "react-router-dom";
import useDevice from "@hooks/useDevice";

const defaultForm =  {
  pointType: 0,
  pointTag: 'service',
  authorName: '',
  authorEmail: '',
  authorPosition: '',
  title: '',
  functions: [],
  city: '',
  address: '',
  phone: '',
  url: '',
  description: '',
  activeDateFrom: '',
  activeDateTo: '',
  socialMediaLink1: '',
  socialMediaLink2: '',
  socialMediaLink3: '',
  socialMediaLink4: '',
  promoCode: '',
  groupTime: '',
  groupSize: '',
  role: 0,
}

const toSuggestion = (filter) => ({ ...filter, key: filter.id, value: filter.title });

const BigFormDots = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [device] = useDevice();

  let [formData, setFormData] = useState({
    pointType: 0,
    pointTag: 'service',
    authorName: '',
    authorEmail: '',
    authorPosition: '',
    title: '',
    functions: [],
    city: '',
    address: '',
    phone: '',
    url: '',
    description: '',
    activeDateFrom: '',
    activeDateTo: '',
    socialMediaLink1: '',
    socialMediaLink2: '',
    socialMediaLink3: '',
    socialMediaLink4: '',
    promoCode: '',
    groupTime: '',
    groupSize: '',
    role: 0,
  });
  const [errorNumber, setErrorNumber] = useState(false);
  const [pointCities, setPointCities] = useState([]);
  const [error, setError] = useState(false);

  const store = useSelector((state) => state);
  
  const handleCreatePoint = () => {
    if (typeof formData.city === 'string') {
      const { id } = pointCities.find((item) => formData.city === item.title);
      formData.city = id;
    }

    formData.functions.length ||  setFormData(formData, formData.functions = [70]);
    formData.phone || setFormData(formData, formData.phone = '+79999999999');
    formData.authorEmail || setFormData(formData, formData.authorEmail = 'service@plus-one.ru');
    formData.authorName || setFormData(formData, formData.authorName = 'service');
    formData.address || setFormData(formData, formData.address = '-');

    const phoneNumbers = formData.phone.split('').filter(element => element !== ' ');

    phoneNumbers.length !== 12 ? setErrorNumber(true) : dispatch(createPoint(pickBy(formData), dispatch));
  };

  const finishFunction = (textInput) => {
    handleCreatePoint();
    if (textInput === 'Добавить еще одну') {
      formData = defaultForm;
      setFormData(formData);
    } else if (textInput === 'Отправить без призов') {
      history.push('/');
    } else if (textInput === 'Авторизоваться и получить призы') {
      history.push('/sign-in');
    }
  }

  const submitFormData = e => {
    const textInput = e.target.innerText;
    e.preventDefault();
    if (formData.pointType) {
      if (Validators.required(formData.title) || Validators.required(formData.address) || Validators.required(formData.city) || Validators.required(formData.authorEmail)) {
        setError(true);
      } else {
        finishFunction(textInput);
      }
    } else {
      if (Validators.required(formData.title) || Validators.required(formData.city) || Validators.required(formData.authorEmail)) {
        setError(true);
      }  else {
        finishFunction(textInput);
      }
    }
  };

  const handleInputData = (input) => (e) => {
    input === 'functions' ? setFormData({ ...formData, [input]: [e.target.value] }) :  setFormData({ ...formData, [input]: e.target.value })
    formData.phone.length === 12 && setErrorNumber(false)
  };

  useEffect(() => {
    setPointCities(sortBy(store.map.cities, ['title']).map(toSuggestion));
  }, [store.map.cities]);

  return (
    <div className='dots-stage_3 bigForm'>
      <div className='dots-container'>
        <a href='/' className='dots__arrowMap'>
          <SVG src={arrowLeft}></SVG>На карту
        </a>
        <h1 className='dots__title'>Добавь свою точку на карту!</h1>
        <div className='dots-action'>
          <div className='dots-action__desc-container'>
            <div className='dots-stage_2__desc desktop'>Добавить организацию</div>
            <div className='dots__coins'>+200 экокоинов</div>
          </div>
          <FormControl className='dots-stage_2__formControl marginZero'>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              className='dots-radio-group'
              onChange={handleInputData('pointType')}
              defaultChecked={formData.pointType}
              defaultValue={formData.pointType}
            >
              <FormControlLabel
                className='dots__radio'
                value={0}
                control={<Radio checkedIcon={<BpCheckedIcon />} />}
                label='Онлайн'
              />
              <FormControlLabel
                className='dots__radio'
                value={1}
                control={<Radio checkedIcon={<BpCheckedIcon />} />}
                label='Оффлайн'
              />
            </RadioGroup>
          </FormControl>
          <Box
            className='dots-stage_3__box'
            component='form'
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete='off'
          >
            <div className='input-wrapper-field'>
              <TextField
                className='dots__input'
                onChange={handleInputData('title')}
                value={formData.title}
                id='standard-basic'
                label='Название компании'
                variant='standard'
              />
              {error && Validators.required(formData.title)}
              <div className='icon-star'>
                <SVG src={star}></SVG>
              </div>
            </div>
            <div className='input-wrapper-field'>
              <TextField
                className='dots__input'
                type='email'
                id='standard-basic'
                value={formData.authorEmail}
                onChange={handleInputData('authorEmail')}
                label='Твой e-mail'
                variant='standard'
              />
              <div className='icon-star'>
                <SVG src={star}></SVG>
              </div>
              {error && (Validators.required(formData.authorEmail) || Validators.email(formData.authorEmail))}
            </div>

            {device === 'desktop' && (
            <div className='input-wrapper-field'>
              <Autocomplete
                id='country-select-demo'
                sx={{ minWidth: '100%', border: 'none' }}
                options={pointCities}
                onChange={handleInputData('city')}
                autoHighlight
                label='Город'
                getOptionLabel={(option) => formData.city ? option.title : ''}
                renderOption={(props, option) => (
                  <Box component='li' key={option.id} value={option.id} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.title}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formData.city}
                    label='Город'
                    onChange={handleInputData('city')}
                    className='dots__input'
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              {error && Validators.required(formData.city)}
              <div className='icon-star'>
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
          {device !== 'desktop' && (
            <div className='input-wrapper-field'>
              {' '}
              <FormControl variant='standard' sx={{ m: 1, minWidth: '100%' }}>
                <InputLabel className='select-label' id='country-select-demo'>
                  Город
                </InputLabel>
                <Select
                  className='dots-select'
                  labelId='country-select-demo'
                  id='country-select-demo'
                  value={formData.city}
                  onChange={handleInputData('city')}
                  label='Выбери Город'
                >
                  {pointCities.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && Validators.required(formData.city)}
              <div className='icon-star'>
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
          {device === 'desktop' && (
            <div className='input-wrapper-field'>
              <Autocomplete
                id='spec-select-demo'
                sx={{ minWidth: '100%', border: 'none' }}
                options={store.points.filters}
                onChange={handleInputData('functions')}
                autoHighlight
                label='Выбери специализацию'
                getOptionLabel={(option) => formData.functions.length ? option.title : ''}
                renderOption={(props, option) => (
                  <Box component='li' value={option.id} sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.title}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Выбери специализацию'
                    value={formData.functions}
                    className='dots__input'
                    onChange={handleInputData('functions')}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </div>
          )}
          {device !== 'desktop' && (
            <div className='input-wrapper-field'>
              <FormControl variant='standard' sx={{ m: 1, minWidth: '100%' }}>
                <InputLabel className='select-label' id='demo-simple-select-standard-label'>
                  Выбери специализацию
                </InputLabel>
                <Select
                  className='dots-select'
                  labelId='demo-simple-select-standard-label'
                  id='spec-select-demo'
                  value={formData.functions}
                  onChange={handleInputData('functions')}
                  label='Выбери специализацию'
                >
                  {store.points.filters.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
            {formData.pointType == 1 && (
              <div className='input-wrapper-field'>
                <TextField
                  className='dots__input'
                  id='standard-basic'
                  onChange={handleInputData('address')}
                  value={formData.address}
                  label='Улица, дом, строение, корпус'
                  variant='standard'
                />
                <div className='icon-star'>
                  <SVG src={star}></SVG>
                </div>
                {error && Validators.required(formData.address)}
              </div>
            )}

            {formData.pointType == 0 && (
              <TextField
                className='dots__input'
                id='standard-basic'
                onChange={handleInputData('address')}
                value={formData.address}
                label='Улица, дом, строение, корпус'
                variant='standard'
              />
            )}

            <div className='input-wrapper-field'>
              <InputMask
                mask='+79999999999'
                value={formData.phone}
                disabled={false}
                maskChar=' '
                id='standard-basic'
                onChange={handleInputData('phone')}
                label='Номер телефона'
                variant='standard'
              >
                {() => <TextField label='Номер телефона' className='dots__input' />}
              </InputMask>
              <span className='ecocoin'>+5 экокоинов</span>
            </div>
            {errorNumber && <div>Некорректный номер</div>}

            {formData.pointType == 1 && (
              <TextField
                className='dots__input'
                id='standard-basic'
                onChange={handleInputData('url')}
                value={formData.url}
                label='Сайт'
                variant='standard'
              />
            )}

            <div className='dots-stage_2__desc social'>Соц. сети</div>
            <div className='dots__social'>
              <div className='input-wrapper-field wi50'>
                <TextField
                  className='dots__input social-input margin'
                  onChange={handleInputData('socialMediaLink1')}
                  value={formData.socialMediaLink1}
                  id='standard-basic'
                  label='вконтакте'
                  variant='standard'
                />
                <span className='ecocoin'>+5 экокоинов</span>
              </div>
              <div className='input-wrapper-field wi50'>
                <TextField
                  className='dots__input social-input'
                  onChange={handleInputData('socialMediaLink2')}
                  value={formData.socialMediaLink2}
                  id='standard-basic'
                  label='соц сеть на f'
                  variant='standard'
                />
                <span className='ecocoin'>+5 экокоинов</span>
              </div>
              <div className='input-wrapper-field wi50'>
                <TextField
                  className='dots__input social-input margin'
                  onChange={handleInputData('socialMediaLink3')}
                  value={formData.socialMediaLink3}
                  id='standard-basic'
                  label='телеграм'
                  variant='standard'
                />
                <span className='ecocoin'>+5 экокоинов</span>
              </div>
              <div className='input-wrapper-field wi50'>
                <TextField
                  className='dots__input social-input'
                  onChange={handleInputData('socialMediaLink4')}
                  value={formData.socialMediaLink4}
                  id='standard-basic'
                  label='соц сеть на i'
                  variant='standard'
                />
                <span className='ecocoin'>+5 экокоинов</span>
              </div>
            </div>
            <div className='input-wrapper-field rec'>
              <div className='dots-stage_2__desc small'>Добавить рекомендацию</div>
              <span className='ecocoin'>+100 экокоинов</span>
            </div>
            <TextareaAutosize
              aria-label='minimum height'
              minRows={3}
              placeholder='Напиши, какие услуги предоставляет организацию и что тебе нравится больше всего'
              style={{ width: '100%', padding: '10px', border: '1px solid #EBEBEB', borderRadius: '8px' }}
              value={formData.description}
              onChange={handleInputData('description')}
            />
          </Box>
          <div className='dots__flex-container'>
            <div className='dots__button' onClick={(e) => submitFormData(e)}>
              Добавить еще одну
            </div>
            <div className='dots__button' onClick={(e) => submitFormData(e)}>
              Авторизоваться и получить призы
            </div>

            <div className='dots__button' onClick={(e) => submitFormData(e)}>
              Отправить без призов
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigFormDots;
