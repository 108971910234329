import React from 'react';
import { UIDialog } from '@UIKit';
import { Redirect, withRouter } from 'react-router-dom';
import { UIRoundedButton } from '@UIKit';
import { UIAppReturnLoyaltyButton } from '@UIKit';
import './NotificationDialog.scss';

const NotificationDialog = ({ location, history, children }) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleClose = () => {
    setIsOpen(false);
  }

  const goLoyalty = () => {
    history.push('/loyalty', location.search);
  }

  if (!isOpen) {
    return <Redirect to={{ pathname: '/', search: location.search }} />
  }

  return (
    <UIDialog
      isOpen={isOpen}
      backdropClassName="NotificationDialog"
      onClose={handleClose}
      timeout={0}
      blurred={true}
      onBackdropClick={handleClose}
    >
      <div className="UIDialog__Wrapper">
        <div className="UIDialog__Content">
          { children }
          <UIAppReturnLoyaltyButton onClick={goLoyalty} />
          <UIRoundedButton size="large" color="danger" onClick={handleClose}>
            Вернуться на главную страницу
          </UIRoundedButton>
        </div>
      </div>
    </UIDialog>
  )
}

export default withRouter(NotificationDialog);
