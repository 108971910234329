import React from 'react';
import { validationErrors } from '@UIKit';
import SVG from 'react-inlinesvg'
import StarIcon from '@images/design/star.svg';
import clsx from 'clsx';

export const DefaultErrorMessage = React.memo(({
  required,
  error,
  touched
}) => {
  return (
    <div className="Input__Validation">
      {
        touched && error
        ? <span>{error}</span>
        : required && validationErrors.required
      }
    </div>
  );
});

export const InputStarredErrorMessage = React.memo(({
  required,
  error,
  touched
}) => {
  const isRequiredMessage = error && validationErrors.required === error;

  return (
    <span className="Input__Validation">
      {!isRequiredMessage && (
        <span className="Input__Validation__Message">{error}</span>
      )}
      <span className="Input__Validation--star">
        <SVG src={StarIcon}
          className={clsx(
            touched && error && "Input__Validation--error",
            required && "Input__Validation--required"
          )}
        />
      </span>
    </span>
  );
});

