import qs from 'querystring'


export function parse(search) {
  return search ? qs.parse(search.replace(/^\?/, '')) : {}
}

export function stringify(params, search) {
  const _p = parse(search)
  return qs.stringify({ ..._p, ...params })
}

export default {
  parse,
  stringify,
}
