import React from "react";
import "./Ask.scss";
import Accordion from "./Accordion"

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";

const Ask = () => {
  return (
    <div className="ask">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/ask">Часто задаваемые вопросы</Link>
        </div>
        <h1 className="about-sub__title">Часто задаваемые вопросы</h1>
        <Accordion></Accordion>
      </div>
    </div>
  );
};

export default Ask;
