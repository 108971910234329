import { SET_SEARCH_CITY } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

const initialState = {
  isSearch: false,
  search: '',
  searchResult: []
};

export default makeReducer(initialState, SET_SEARCH_CITY);
