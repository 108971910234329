import React from 'react';
import { UIShield } from '@UIKit';
import { TaskStatuses } from '../../../enums/task-status'

const TaskCounter = React.memo(({
  task: { status, limitTotal, limitUsed },
  disabled
}) => {
  if (status === TaskStatuses.MODERATION) {
    return <UIShield type="clock" color="secondary" />
  }

  if (status === TaskStatuses.DECLINED) {
    return <UIShield type="stop" color="alert" />
  }

  const color = disabled ? 'primary' : 'secondary';
  return (
    <UIShield color={ color }>
      { limitUsed }/{ limitTotal }
    </UIShield>
  );
})

export default TaskCounter;