import React from 'react';
import clsx from 'clsx';
import './RadioButton.scss';

const RadioButton = ({ input, children, className, radioButtonValue }) => {
  return (
    <label className={clsx("RadioButton__Container", className)}>
      { children }
      <input type="radio"
        {...input}
        className="RadioButton__Default"
        value={radioButtonValue}
        checked={input.value === radioButtonValue}
      />
      <span className="RadioButton__Checkmark"></span>
    </label>
  )
}

export default RadioButton;
