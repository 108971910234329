import React from 'react';
import clsx from 'clsx';
import { UIRoundedButton } from '@UIKit'
import './Fab.scss';

export const Fab = ({ children, onClick, className }) => {
  return (
    <UIRoundedButton
      className={ clsx('Fab', className) }
      onClick={onClick}
    >
      { children }
    </UIRoundedButton>
  )
}
