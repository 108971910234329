import { SET_AUTH } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

export const initialState = {
  accessToken: '',
  refreshToken: '',
  scope: '',
  expiresIn: 0,
  isAuthenticated: false,
  goBackUrl: '',
  signOutDialogOpen: false
};

export default makeReducer(initialState, SET_AUTH);
