import { createStore, applyMiddleware, compose } from 'redux';
// import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { analyticsMiddleware } from '../middlewares/analytics'
import history from './history';
import reducers from '../reducers';
import { commonService } from '@services/commonService';
import { initialState as authInitialState } from '@redux/reducers/auth';

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export default (url = '/', serverState = {}) => {
  // Create a history depending on the environment
  const enhancers = [];
  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  // const sagaMiddleware = createSagaMiddleware();
  const middleware = [
    routerMiddleware(history),
    analyticsMiddleware,
    thunk
  ];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = !isServer ? window.__PRELOADED_STATE__ : serverState;

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  const preloadedState = {
    ...initialState,
    auth: {
      ...authInitialState,
      ...commonService.readLocalStorage([
        'accessToken',
        'expiresIn',
        'refreshToken',
        'scope',
        'isAuthenticated'
      ])
    }
  }

  // Create the store
  const store = createStore(
    reducers(history),
    preloadedState,
    composedEnhancers
  );
  
  if (typeof window !== 'undefined') {
    window.store = store;
  }
  
  return {
    store,
    history
  };
};

//
// export default function configureStore(initialState) {
//   const sagaMiddleware = createSagaMiddleware();
//
//   const middleware = [sagaMiddleware, routerMiddleware(history), thunk, logger];
//
//   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
//
//   const store = createStore(
//     reducers,
//     initialState,
//     composeEnhancers(applyMiddleware(...middleware))
//   );
//   sagaMiddleware.run(rootSaga);
//
//   return store;
// }
