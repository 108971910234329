import api from './config'

export function registerUser(payload) {
  return api.post('/users/', payload);
}

export function getUser() {
  return api.get('/users/');
}

export function sendVerification() {
  return api.post('/users/send_verification/');
}

export function checkVerification(payload) {
  return api.post('/users/check_verification/', payload);
}

export function createToken(payload) {
  return api.post('/auth/token/', payload);
}

export function convertToken(payload) {
  return api.post('/auth/convert-token/', payload);
}

export function resetPassword(payload) {
  return api.post('/users/password_reset/', payload);
}

export function changePassword({ email, code }) {
  return api.get(`/users/password_reset/?email=${email}&code=${code}`);
}

export function addCompanyUser(payload) {
  return api.put('/users/', payload);
}