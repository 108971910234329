import React from "react";
import "./DotsStage_3.scss";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { Validators } from "@UIKit";
import SVG from "react-inlinesvg";
import star from "@images/svg/star_req.svg";
import arrowLeft from "@images/svg/arrow-left-prize.svg";

const DotsStage_3 = ({ nextStep, prevStep, handleFormData, values, saveAndContinueLater }) => {
  const [error, setError] = React.useState(false);
  const submitFormData = (e) => {
    e.preventDefault();
    if (
      Validators.required(values.authorName) ||
      Validators.required(values.authorEmail) ||
      Validators.email(values.authorEmail) ||
      Validators.required(values.authorPosition)
    ) {
      setError(true);
    } else {
      nextStep();
    }
  };

  return (
    <div className="dots-stage_3">
      <div className="dots-container">
      <a href="/" className="dots__arrowMap"><SVG src={arrowLeft}></SVG>На карту</a>
        <h1 className="dots__title">Добавь свою точку на карту!</h1>
        <div className="dots-stage_2__desc">Пожалуйста, заполни контактную информацию.</div>
        <Box
          className="dots-stage_3__box"
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div className="input-wrapper-field">
            <TextField
              className="dots__input"
              id="standard-basic"
              defaultValue={values.authorName}
              onChange={handleFormData("authorName")}
              label="Имя"
              variant="standard"
            />
            <div className="icon-star">
              <SVG src={star}></SVG>
            </div>
            {error && Validators.required(values.authorName)}
          </div>
          <div className="input-wrapper-field">
            <TextField
              className="dots__input"
              type="email"
              id="standard-basic"
              defaultValue={values.authorEmail}
              onChange={handleFormData("authorEmail")}
              label="Твой e-mail"
              variant="standard"
            />
            <div className="icon-star">
              <SVG src={star}></SVG>
            </div>
            {error && (Validators.required(values.authorEmail) || Validators.email(values.authorEmail))}
          </div>
          <TextField
            className="dots__input"
            id="standard-basic"
            defaultValue={values.authorPosition}
            onChange={handleFormData("authorPosition")}
            label="Должность в организации"
            variant="standard"
          />
          {error && Validators.required(values.authorPosition)}
        </Box>
        <div className="dots__flex-container">
          <Link className="dots__button" type="submit" onClick={submitFormData}>
            Продолжить
          </Link>
          <Link className="dots__button dots__button--transparent" onClick={prevStep}>
            Назад
          </Link>
        </div>
        <div onClick={saveAndContinueLater} className="dots__save-late">Сохранить и продолжить позже</div>
      </div>
    </div>
  );
};

export default React.memo(DotsStage_3);
