import React from "react";
import { UIRoundedButton } from "@UIKit";
import loyaltyIcon from "@images/design/loyalty.svg";
import mapIcon from "@images/design/map.svg";
import chatIcon from "@images/design/chat.svg";
import policyIcon from "@images/design/policy.svg";
import logoutIcon from "@images/design/logout.svg";
import SVG from "react-inlinesvg";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { identity } from "lodash";
import "./AppSideBar.scss";
import { connect } from "react-redux";
import { UserPanel } from "./UserPanel";
import OutsideClickHandler from "react-outside-click-handler";
import { bindActionCreators } from "redux";
import { toggleSignOutDialog } from "@redux/actions/auth";
import addPointIcon from '@images/design/add-point.svg';

const AppSideBar = React.memo(
  ({
    className,
    toggleSideBar = identity,
    toggleSignOutDialog = identity,
    user,
    wallet,
    location,
    isAuthenticated
  }) => {

    const handleSignOut = () => {
      toggleSideBar();
      setTimeout(() => {
        toggleSignOutDialog(true);
      }, 0)
    };

    const openPolicy = () => {
      return window.open('https://gorod.plus-one.ru/legal/', '_blank');
    }

    return (
      <>
        <OutsideClickHandler onOutsideClick={toggleSideBar}>
          <div className={clsx("AppSideBar__Content", className)}>
            <div className="AppSideBar__Main">
              <UserPanel className="AppSideBar__Auth" user={user} wallet={wallet} toggleSideBar={toggleSideBar} />
              <div className="AppSideBar__Loyalty">
                <UIRoundedButton
                  color="link"
                  className="link--fadeInOut"
                  to={{ pathname: "/loyalty/prizes", search: location.search }}
                  onClick={toggleSideBar}
                >
                  <div className="AppSideBar__Link">
                    <i className="AppSideBar__Icon">
                      <SVG src={loyaltyIcon} />
                    </i>
                    Потратить баллы
                  </div>
                </UIRoundedButton>
                <UIRoundedButton color="link"
                  className="link--fadeInOut"
                  to={{ pathname: "/loyalty/tasks", search: location.search }}
                  onClick={toggleSideBar}
                >
                  <div className="AppSideBar__Link">
                    <i className="AppSideBar__Icon">
                      <SVG src={mapIcon} />
                    </i>
                    Все задания
                  </div>
                </UIRoundedButton>
              </div>
            </div>
            <div className="AppSideBar__Footer">
              <UIRoundedButton
                color="link"
                className="link--fadeInOut"
                to={{ pathname: "/loyalty/new_point", search: location.search }}
                onClick={toggleSideBar}
              >
                <div className="AppSideBar__Link">
                  <i className="AppSideBar__Icon">
                    <SVG src={addPointIcon} />
                  </i>
                  <span>Добавить точку</span>
                </div>
              </UIRoundedButton>
              <UIRoundedButton
                color="link"
                className="link--fadeInOut"
                to={{ pathname: "/loyalty/feedback", search: location.search }}
                onClick={toggleSideBar}
              >
                <div className="AppSideBar__Link">
                  <i className="AppSideBar__Icon">
                    <SVG src={chatIcon} />
                  </i>
                  <span>Написать в поддержку</span>
                </div>
              </UIRoundedButton>
              <UIRoundedButton
                color="link"
                className="link--fadeInOut"
                onClick={openPolicy}>
                <div className="AppSideBar__Link">
                  <i className="AppSideBar__Icon">
                    <SVG src={policyIcon} />
                  </i>
                  <span>Политика конфиденциальности</span>
                </div>
              </UIRoundedButton>
              {isAuthenticated && (
                <UIRoundedButton color="link" className="link--fadeInOut" onClick={handleSignOut}>
                  <div className="AppSideBar__Link">
                    <i className="AppSideBar__Icon">
                      <SVG src={logoutIcon} className="AppSideBar__SignOutIcon" />
                    </i>
                    <span>Покинуть профиль</span>
                  </div>
                </UIRoundedButton>
              )}
            </div>
          </div>
        </OutsideClickHandler>
      </>
    );
  }
);

const mapStateToProps = ({ loyalty: { user, wallet }, auth: { isAuthenticated } }) => ({ user, wallet, isAuthenticated });
const mapStateToDispatch = (dispatch) => bindActionCreators({ toggleSignOutDialog }, dispatch);
export default connect(mapStateToProps, mapStateToDispatch)(withRouter(AppSideBar));
