import { SET_BLOG, RESET_BLOG } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

const initialState = {
  blog: [],
  openListBlog: false,
  openBlog: false,
  openBg: null
};

export default makeReducer(initialState, SET_BLOG, (state, action) => {
  const { type } = action;
  switch (type) {
    case RESET_BLOG:
      return initialState;
    default:
      return state;
  }
});
