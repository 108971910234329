import { SET_LOYALTY } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

export const initialState = {
  wallet: null,
  user: null,
  favoriteCategories: null,
  favoritePoints: null,
  allTasks: [],
  favouriteTasks: [],
  userTasks: [],
  userPrizes: [],
  prizes: [],
  tasks: []
};

export default makeReducer(initialState, SET_LOYALTY);
