import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

import SVG from "react-inlinesvg";
import plus from "@images/svg/plus-about.svg";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRight: "none",
    borderLeft: "none",
    boxShadow: "none",
    backgroundColor: "#ffffff",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#ffffff",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary className={expanded === "panel1" ? 'expanded-acc' : ''} aria-controls="panel1d-content" id="panel1d-header">
          <Typography className="accordion-header">У меня не работает приложение, что делать?</Typography>
          <div className="accordion-button-plus">
            <SVG className="icon-plus" src={plus}></SVG>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-content">
            Проверь версию приложения, подключение к интернету и разрешение на геолокацию. Скорее всего, что-то из этого отключено. Если это не сработало, напиши нам на электронную почту service@plus-one.ru, мы разберемся и устраним неполадку.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary className={expanded === "panel2" ? 'expanded-acc' : ''} aria-controls="panel2d-content" id="panel2d-header">
          <Typography className="accordion-header">Почему я не могу найти свой город в приложении?</Typography>
          <div className="accordion-button-plus">
            <SVG className="icon-plus" src={plus}></SVG>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-content">
            Обнови приложение, проверь настройки геолокации или выбери свой город вручную. Если в твоем населенном пункте нет ни одной точки, ты можешь это исправить. Добавь на карту сервисы, о которых знаешь.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary className={expanded === "panel3" ? 'expanded-acc' : ''} aria-controls="panel3d-content" id="panel3d-header">
          <Typography className="accordion-header">Почему я не могу найти точку рядом со своим домом?</Typography>
          <div className="accordion-button-plus">
            <SVG className="icon-plus" src={plus}></SVG>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-content">
            Может быть две причины:
            1. Неверно указаны настройки геолокации. В этом случае достаточно обновить приложение и настроить геолокацию.
            2. Рядом может не быть точек в выбранной категории. Мы будем рады, если ты добавишь сервисы, о которых знаешь.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary className={expanded === "panel4" ? 'expanded-acc' : ''} aria-controls="panel4d-content" id="panel4d-header">
          <Typography className="accordion-header">Я нашел на карте неактуальную информацию о сервисе</Typography>
          <div className="accordion-button-plus">
            <SVG className="icon-plus" src={plus}></SVG>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-content">
            Карта постоянно обновляется пользователями. Пришли информацию, и мы дополним описание сервиса или удалим неактуальную точку.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
        <AccordionSummary className={expanded === "panel5" ? 'expanded-acc' : ''} aria-controls="panel5d-content" id="panel5d-header">
          <Typography className="accordion-header">Мало точек в моем городе</Typography>
          <div className="accordion-button-plus">
            <SVG className="icon-plus" src={plus}></SVG>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-content">
            Мы работаем над увеличением сервисов по всем городам и ты можешь нам в этом помочь. Просто добавь полезный сервис/организацию на карту через мобильное приложение (в меню есть функция «добавить точку») или наш сайт.
            Чтобы поблагодарить горожан за помощь в добавлении точек, мы сделали специальное задание «Новые горизонты» в программе лояльности. За добавление каждой точки ты получишь баллы, которые можно обменять на приятные призы.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
        <AccordionSummary className={expanded === "panel6" ? 'expanded-acc' : ''} aria-controls="panel6d-content" id="panel6d-header">
          <Typography className="accordion-header">Почему мое задание отклонили?</Typography>
          <div className="accordion-button-plus">
            <SVG className="icon-plus" src={plus}></SVG>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-content">
            Модератор может отклонить задание, если не соблюдены правила его выполнения - превышен лимит выполнений или не соблюдены требования к заданию. Внимательно прочитай описание задания и попробуй еще раз.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
        <AccordionSummary className={expanded === "panel7" ? 'expanded-acc' : ''} aria-controls="panel7d-content" id="panel7d-header">
          <Typography className="accordion-header">Почему так долго проверяют задания?</Typography>
          <div className="accordion-button-plus">
            <SVG className="icon-plus" src={plus}></SVG>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-content">
            Модерация занимает 48 часов. Не переживай, совсем скоро модератор проверит твое задание.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
        <AccordionSummary className={expanded === "panel8" ? 'expanded-acc' : ''} aria-controls="panel8d-content" id="panel8d-header">
          <Typography className="accordion-header">Почему я не могу купить все призы, которые хочу?</Typography>
          <div className="accordion-button-plus">
            <SVG className="icon-plus" src={plus}></SVG>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="accordion-content">
            Некоторые призы имеют свои ограничения, которые всегда указываются в описании. Это может быть лимит на несколько покупок одного и того же приза или доставка призов только в пределах одного города. Иногда  условием получения приза является выполнение определенного задания. В описании такого приза указано, что нужно сделать, чтобы появилась возможность его получить.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
