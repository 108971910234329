import { createActions } from 'redux-actions';
import * as API from '../../api/points';
import { SET_NEW_POINT, RESET_NEW_POINT } from '../constants';

export const { setNewPoint, resetNewPoint } = createActions(SET_NEW_POINT, RESET_NEW_POINT);

export const addNewPoint = () => async (dispatch, getState) => {
  const { newPoint, map: { city }} = getState();
  const tmpErr = {...newPoint.errors};
  let flag = false;
  Object.keys(newPoint).forEach(item => {
    if((!newPoint[item] || newPoint[item] === '' || newPoint[item].length === 0) && item in tmpErr) {
      tmpErr[item] = true;
      flag = true;
    }
  });
  if(newPoint.authorEmail.indexOf('@') === -1 || newPoint.authorEmail.indexOf('.') === -1) {
    tmpErr.authorEmail = true;
    flag = true;
  }
  if (!newPoint.phone) {
    tmpErr.phone = true;
  }
  if (newPoint.phone && !/\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/.test(newPoint.phone)) {
    tmpErr.phone = true;
    flag = true;
  }
  if (!newPoint.activeDateFrom && newPoint.tag === 'event') {
    tmpErr.activeDateFrom = true;
  }
  // if(newPoint.email.length > 0 && (newPoint.email.indexOf('@') === -1 || newPoint.email.indexOf('.') === -1)) {
  //   tmpErr.email = true;
  //   flag = true;
  // }

  if(!flag) {
    const isFullAddress = /^Россия, город/.test(newPoint.address);
    const composedAddress = isFullAddress ?
      newPoint.address :
      city === '' ? newPoint.address : `Россия, город ${city}, ${newPoint.address}`;

    let resAddress = '',
    results = [];

    try {
      resAddress = await API.fetchAddress(composedAddress);
      results = resAddress.data.response.GeoObjectCollection.featureMember;
    } catch (e) {
      tmpErr.address = true;
    }

    if(results.length === 0) {
      tmpErr.address = true;
    } else {
      const pos = results[0].GeoObject.Point.pos.split(' ');
      let data = {
        point_tag: newPoint.tag,
        // categories: newPoint.categories.length > 0 ? newPoint.categories.map(item => item.value) : null,
        functions:  newPoint.functions.length > 0 ? newPoint.functions.map(item => item.value) : null,
        // pointType: newPoint.pointType.value,
        title: newPoint.title,
        ...newPoint.description && {description: newPoint.description},
        latitude: pos[0],
        longitude: pos[1],
        url: newPoint.url !== "" ? newPoint.url : null,
        phone: newPoint.phone.replace(/\s/g, ''),
        // email: newPoint.email !== "" ? newPoint.email : null,
        authorName: newPoint.authorName !== "" ? newPoint.authorName : null,
        authorEmail: newPoint.authorEmail,
        // authorPhone: newPoint.authorPhone.replace(/\s/g, ''),
        // activeDateFrom: newPoint.activeDateFrom !== "" ? moment(newPoint.activeDateFrom).format('YYYY-MM-DD') : null,
        // activeDateTo: newPoint.activeDateTo !== "" ? moment(newPoint.activeDateTo).format('YYYY-MM-DD') : null,
        address: newPoint.address
      };

      if (newPoint.tag === 'event') {
        data = {
          ...data,
          startDate: newPoint.activeDateFrom,
          endDate: newPoint.activeDateTo
        }
      }

      try {
        const res = await API.createPoint(data);
        dispatch(setNewPoint({
          isSent: true
        }));
        return res;
      } catch (e) {
        dispatch(setNewPoint({
          errors: tmpErr
        }));
        throw e;
      }
    }
  } else {
    dispatch(setNewPoint({ errors: tmpErr }));
    throw new Error();
  }
};
