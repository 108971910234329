import * as API from '@api/miscellaneous';
import { createActions } from 'redux-actions';
import { reset } from 'redux-form'
import { SET_MISCELLANEOUS } from '@redux/constants';
import { messageSent } from '@UIKit/utils/notifications';
import { commonService } from '@services/commonService';

export const { setMiscellaneous } = createActions(SET_MISCELLANEOUS);

export const showNotification = (notification) => async (dispatch) => {
  dispatch(setMiscellaneous({ notification: null }))
  dispatch(setMiscellaneous({ notification }))
}

export const sendFeedback = () => async (dispatch, getState) => {
  const { form: { feedback: { values } } } = getState();
  const feedback = {
    ...values,
    platform: commonService.getFormFactor()
  }

  try {
    await API.sendFeedback(feedback);
    dispatch(showNotification(messageSent));
    dispatch(reset('feedback'));
  } catch (e) {
  }
}
