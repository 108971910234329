import React from 'react';
import './AppPartners.scss';
import { commonService } from '@services/commonService';

export const AppPartners = ({cities}) => {
  const handleClick = (city) => () => {
    commonService.sendEvent({
      category: 'about_partner',
      action: `User clicks on ${city.title}`
    });
  }

  return (
    <div className="AppPartners">
      <div className="AppPartners__Container">
        <h1 className="AppPartners__Title">Партнерские СМИ</h1>
        <div className="AppPartners__Content">
          { cities.map(city => {
            return (
              <a href={city.smiUrl}
                target="_blank"
                className="AppPartners__Card"
                key={city.slug}
                rel="noopener noreferrer"
                onClick={handleClick(city)}
              >
                <div className="AppPartners__Logo">
                  <img src={ city.smiLogo } className={`AppPartners__Logo--${city.slug}`}/>
                </div>
                <div className='AppPartners__title'>
                  { city.title }
                </div>
                {/* <UIRoundedButton className="AppPartners__Button" color="partner">
                  { city.title }
                </UIRoundedButton> */}
              </a>
            )
          }) }
        </div>
      </div>
    </div>
  )
}
