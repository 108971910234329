import React from "react";
import "./Other.scss";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Link } from "react-router-dom";
import { Validators } from "@UIKit";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import Autocomplete,  { createFilterOptions }  from "@mui/material/Autocomplete";
import SVG from "react-inlinesvg";
import star from "@images/svg/star_req.svg";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import useDevice from "@hooks/useDevice";

const Other = ({ handleSubmit, handleFormData, prevStep, values }) => {
  const [device] = useDevice();
  const [error, setError] = React.useState(false);
  const submitFormData = (e) => {
    e.preventDefault();
    if (
      Validators.required(values.city) ||
      Validators.required(values.address)
    ) {
      setError(true);
    } else {
      handleSubmit();
    }
  };
  const spec = useSelector((state) => state.points.filters);
  const cities = useSelector((state) => state.map.cities);
  const [pointCities, setPointCities] = React.useState([]);
  const toSuggestion = (filter) => ({ ...filter, key: filter.id, value: filter.title });

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.title,
  });

  React.useEffect(() => {
    setPointCities(sortBy(cities, ["title"]).map(toSuggestion));
  }, [cities]);

  return (
    <div className="action">
      <div className="dots-action">
        <div className="dots-action__desc-container">
          <div className="dots-stage_2__desc desktop">Другое</div>
          <div className="dots__coins">+200 экокоинов</div>
        </div>
        <Box
          className="dots-stage_3__box"
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div className="input-wrapper-field">
            <TextField
              className="dots__input"
              id="standard-basic"
              label="Что ты хочешь добавить на карту?"
              variant="standard"
            />
            <div className="icon-star">
              <SVG src={star}></SVG>
            </div>
          </div>
          {device === 'desktop' && (
            <div className="input-wrapper-field">
              <Autocomplete
                id="country-select-demo"
                sx={{ minWidth: "100%", border: "none" }}
                filterOptions={filterOptions}
                options={spec}
                onChange={handleFormData("functions")}
                autoHighlight
                label="Выбери специализацию"
                getOptionLabel={(option) => values.functions ? option.title : ''}
                renderOption={(props, option) => (
                  <Box component="li" value={option.id} sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.title}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Выбери специализацию"
                    defaultValue={values.functions}
                    className="dots__input"
                    onChange={handleFormData("functions")}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {error && Validators.required(values.functions)}
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
          {device !== 'desktop' && (
            <div className="input-wrapper-field">
              <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel className="select-label" id="demo-simple-select-standard-label">
                  Выбери специализацию
                </InputLabel>
                <Select
                  className="dots-select"
                  labelId="demo-simple-select-standard-label"
                  id="country-select-demo"
                  defaultValue={values.functions}
                  onChange={handleFormData("functions")}
                  label="Выбери специализацию"
                >
                  {spec.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && Validators.required(values.functions)}
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
          {device === 'desktop' && (
            <div className="input-wrapper-field">
              <Autocomplete
                id="country-select-demo"
                sx={{ minWidth: "100%", border: "none" }}
                filterOptions={filterOptions}
                options={pointCities}
                onChange={handleFormData("city")}
                autoHighlight
                label="Город"
                getOptionLabel={(option) => values.city ? option.title : ''}
                renderOption={(props, option) => (
                  <Box component="li" value={option.id} sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.title}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Город"
                    defaultValue={values.city}
                    onChange={handleFormData("city")}
                    className="dots__input"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              {error && Validators.required(values.city)}
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
           {device !== 'desktop' && (
            <div className="input-wrapper-field">
              {" "}
              <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel className="select-label" id="country-select-demo">
                  Город
                </InputLabel>
                <Select
                  className="dots-select"
                  labelId="country-select-demo"
                  id="country-select-demo"
                  defaultValue={values.city}
                  onChange={handleFormData("city")}
                  label="Выбери специализацию"
                >
                  {pointCities.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && Validators.required(values.city)}
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
           <div className="input-wrapper-field">
            <TextField className="dots__input" id="standard-basic" label="Сайт" variant="standard" />
            <span className="ecocoin">+5 экокоинов</span>
          </div>
          <TextField
            className="dots__input"
            id="standard-basic"
            onChange={handleFormData("address")}
            defaultValue={values.address}
            label="Улица, дом, строение, корпус"
            variant="standard"
          />
          {error && Validators.required(values.address)}
          <div className="input-wrapper-field rec">
          <div className="dots-stage_2__desc small">Добавить рекомендацию</div>
          <span className="ecocoin">+100 экокоинов</span></div>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder="Напиши, какие услуги предоставляет организацию и что тебе нравится больше всего"
            style={{ width: "100%", padding: "10px", border: "1px solid #EBEBEB", borderRadius: "8px" }}
            onChange={handleFormData("description")}
            defaultValue={values.description}
          />
        </Box>
        <div className="dots__flex-container">
          <Link className="dots__button" onClick={submitFormData}>
            Продолжить
          </Link>
          <Link className="dots__button dots__button--transparent" onClick={prevStep}>
            Назад
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Other;
