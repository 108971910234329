import React from 'react'
import clsx from 'clsx'
import { UIRoundedButton } from '@UIKit'
import SVG from 'react-inlinesvg'
import loyaltyIcon from '@images/design/loyalty.svg'
import { initial } from 'lodash'
import './PrizeCard.scss'

const PrizeCard = ({
  className,
  data,
  onSelect = initial,
  isUserPrizes,
  disabled,
}) => {
  const [ showDetails, setShowDetails ] = React.useState(true);
  
  const prize = React.useMemo(
    () => isUserPrizes ? data.group : data,
    [data, isUserPrizes]
  );

  const handleClick = React.useCallback(
    () => onSelect(data),
    [data, onSelect]
  );

  React.useEffect(
    () => {
      setShowDetails(prize.isCta || (!disabled && isUserPrizes))
    },
    [ prize, isUserPrizes, disabled ]
  );

  return (
    <div className={ clsx('PrizeCard', className) }>
      <div
        className="PrizeCard__Image"
        style={{ backgroundImage: prize.image && `url(${ prize.image })` }}
      />
      <div className="PrizeCard__Content">
        <UIRoundedButton
          className="PrizeCard__Button"
          onClick={ handleClick }
          disabled={ !prize.isCta && disabled }
          color="secondary"
        >
          { showDetails
              ? 'Подробнее'
              :  <><SVG src={ loyaltyIcon } /> { prize.points }</>
          }
        </UIRoundedButton>
      </div>
    </div>
  )
}

export default React.memo(PrizeCard)
