import { orderBy } from 'lodash';

export const SortByEnum = {
  BY_DATE: 'dtCreated',
  BY_POINTS: 'points',
}

export const sortTasks = (tasks, ordering) => {
  if (ordering !== 'points') {
    return tasks;
  }

  return orderBy(tasks, 'points', 'desc')
}