import React from 'react';
import './Input.scss';
import clsx from 'clsx';
import { uniqueId, identity } from 'lodash';
import TextareaAutosize from 'react-autosize-textarea';
import { DefaultErrorMessage } from './ErrorMessages';
import { useSelector } from 'react-redux';

const FormField = React.forwardRef(({ textarea, ...props }, forwardedRef) => {
  return textarea
    ? <TextareaAutosize {...props} ref={forwardedRef} />
    : <input {...props} ref={forwardedRef}></input>
})

const Input = React.forwardRef(({
  placeholder,
  value,
  className,
  onClick = identity,
  onChange = identity,
  onBlur = identity,
  onFocus = identity,
  children,
  meta: { touched, error } = {},
  invalid,
  input,
  readOnly,
  isSelect,
  inputCity,
  isDropdown,
  tabIndex = 1,
  required,
  label,
  textarea,
  selectedCity,
  errorMessage: ErrorMessage,
  ...props
}, forwardedRef) => {
  const getValue = () => input ? input.value : value;
  const store = useSelector(state => state);

  const [ id ] = React.useState(uniqueId('Input_'));
  const [ focused, setFocused ] = React.useState(false);
  const [ blurred, setBlurred ] = React.useState(true);
  const [ inputValue, setInputValue ] = React.useState(getValue());
  const [ withIcon ] = React.useState(() => React.Children.count(children) > 0);

  React.useEffect(() => {
    !focused && getValue() && setFocused(true);
    blurred && !getValue() && setFocused(false);
  }, [ getValue() ]);

  const handleClick = e => {
    setFocused(true);
    onClick(e)
  }

  const handleBlur = e => {
    !getValue() && setFocused(false);
    setBlurred(true);
    onBlur(e);
    input && input.onBlur(e);
  }

  const handleChange = e => {
    setInputValue(e.target.value);
    onChange(e);
    input && input.onChange(e);
  }

  React.useEffect(() => {
    selectedCity?.title ? setInputValue(selectedCity?.title) : setInputValue('');
  }, [selectedCity]);

  const handleFocus = e => {
    onFocus(e);
    setFocused(true);
    setBlurred(false);
    input && input.onFocus(e)
  }

  const handleAutoFill = React.useCallback(e => {
    if (e.animationName === 'onAutoFillStart') {
      setFocused(true);
    }
  })

  React.useEffect(() => {
    (inputCity && store.map.city) ? setInputValue(store.map.city) : setInputValue('');
    props.userCode && setInputValue(props.userCode);
  }, [props.inputvaluedefault]);

  React.useEffect(() => {
    if (forwardedRef && forwardedRef.current) {
      forwardedRef.current.value = inputValue;
    }
  }, [inputValue]);

  return (
    <div className={clsx(
      "Input",
      focused && "Input--focused",
      touched && error && "Input--invalid",
      invalid && "Input--invalid",
      readOnly && "Input--readOnly",
      isSelect && "Input--select",
      isDropdown && "Input--dropdown",
      ErrorMessage && "Input--custom-error",
      className
    )}
    >
      <FormField
        {...props}
        {...input}
        id={id}
        ref={forwardedRef}
        className={clsx("Input__Control", className)}
        onClick={handleClick}
        onAnimationEnd={handleAutoFill}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        value={inputValue}
        spellCheck="false"
        readOnly={readOnly || isSelect || isDropdown}
        tabIndex={tabIndex}
        textarea={ textarea }
      />
      <label
        htmlFor={id}
        className={clsx("Input__Label", withIcon && 'Input__Label--with-icon')}
        spellCheck="false"
      >
        { focused && label ? label : placeholder || label }
      </label> 
      { ErrorMessage
          ? <ErrorMessage required={required} error={error} touched={touched} />
          : <DefaultErrorMessage required={required} error={error} touched={touched} /> }
      <div className="Input__Icon">{ children }</div>
    </div>
  );
})



export default Input;
