import React, { useState } from "react";
import "./Contacts.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Validators } from "@UIKit";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import InputMask from "react-input-mask";
import Checkbox from "@mui/material/Checkbox";
import { sendFeedback } from '@redux/actions/miscellaneous';
import { useDispatch } from "react-redux";
import * as API from '@api/miscellaneous';

const Contacts = () => {
  const [checkBOx, setCheckBOx] = useState(false);
  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [errorMail, setErrorMail] = useState('');
  const [formData, setFormData] = useState({
    authorName: "",
    authorEmail: "",
    authorPhone: "",
    content: ""
  });

  const changeChBox = (event) => {
    setCheckBOx(event.target.checked);
  };

  const options = [
    {
      label: "Тема 1",
      value: "thema1",
    },
    {
      label: "Тема 2",
      value: "thema2",
    },
    {
      label: "Тема 3",
      value: "thema3",
    },
    {
      label: "Тема 4",
      value: "thema4",
    },
  ];

  const emailValidate = (formData.authorEmail === '' || formData.authorEmail.indexOf('@') === -1 || formData.authorEmail.indexOf('.') === -1);

  const handleInputData = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });

    !emailValidate && setErrorMail('');
  };
  

  const submitFormData = async (e) => {
    e.preventDefault();
    try {
      if (Validators.required(formData.authorName) ||
        Validators.required(formData.authorPhone) ||
        Validators.required(formData.authorEmail) ||
        Validators.required(formData.content) ||
        emailValidate) {
        setError(true);
        emailValidate && setErrorMail('Некорректный email');
      } else {
        if (!sending) {
          await API.sendFeedback(formData);
          setSending(true);
        }
      }
    } catch (e) {
    }
  }

  return (
    <div className="contacts">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/contacts">Контакты</Link>
        </div>
        <h1 className="about-sub__title">Контакты</h1>
        <div className="contacts__wrapper">
          <div className="contacts__left-wrapper">
            <div className="contacts__left">
              <div className="contacts__bold-text">Программа лояльности</div>
              <div className="contacts__normal-text">
                Есть вопросы? Напиши нам <a href={`mailto:service@plus-one.ru`}>service@plus-one.ru</a>. Мы ответим в течение 48 часов.
              </div>
            </div>
            <div className="contacts__left">
              <div className="contacts__bold-text">Сотрудничество с региональной Медиасетью</div>
              <div className="contacts__normal-text">Почта для связи: <a href={`mailto:o.grishina@plus-one.ru`}>o.grishina@plus-one.ru</a></div>
            </div>
            <div className="contacts__left">
              <div className="contacts__bold-text">Пресс-служба</div>
              <div className="contacts__normal-text">
                По вопросам информационного сотрудничества обращайтесь в нашу пиар-дирекцию <a href={`mailto:pr@plus-one.ru`}>pr@plus-one.ru</a>
              </div>
            </div>
            <div className="contacts__left">
              <div className="contacts__bold-text">Сотрудничество</div>
              <div className="contacts__normal-text">Почта для связи: <a href={`mailto:o.grishina@plus-one.ru`}>o.grishina@plus-one.ru</a></div>
            </div>
            <div className="contacts__left">
              <div className="contacts__bold-text">Для органов государственной власти</div>
              <div className="contacts__normal-text"><a href={`mailto:info@plus-one.ru`}>info@plus-one.ru</a></div>
            </div>
          </div>
          <div className="contacts__right">
            <div className="contacts__right-wrapper">
              <form>
                <h3 className="form__title">Форма обратной связи</h3>
                <TextField
                  className="dots__input"
                  id="standard-basic"
                  onChange={handleInputData("authorName")}
                  defaultValue={formData.authorName}
                  label="Имя"
                  variant="standard"
                />
                {error && (Validators.required(formData.authorName))}
                <TextField
                  className="dots__input"
                  id="standard-basic"
                  onChange={handleInputData("authorEmail")}
                  defaultValue={formData.authorEmail}
                  label="Email"
                  variant="standard"
                  error={errorMail ? true : false}
                />
                {errorMail}
                <InputMask
                  mask="+79999999999"
                  value={formData.phone}
                  disabled={false}
                  maskChar=" "
                  id="standard-basic"
                  onChange={handleInputData("authorPhone")}
                  defaultValue={formData.authorPhone}
                  label="Телефон"
                  variant="standard"
                >
                  {() => <TextField label="Номер телефона" className="dots__input" />}
                </InputMask>
                {error && (Validators.required(formData.authorPhone))}
                {/* <Autocomplete
                  id="country-select-demo"
                  sx={{ minWidth: "100%", border: "none" }}
                  options={options}
                  onChange={handleInputData("topic")}
                  autoHighlight
                  label="Тема"
                  getOptionLabel={(option) => console.log(options)}
                  renderOption={(props, option) => (
                    <Box component="li" value={option.value} sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Тема"
                      defaultValue={formData.topic}
                      onChange={handleInputData("topic")}
                      className="dots__input"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                /> */}
                <div className="contacts-label">Твой вопрос</div>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Example..."
                  style={{ width: "100%", padding: "10px", border: "1px solid #EBEBEB", borderRadius: "8px" }}
                  defaultValue={formData.content}
                  onChange={handleInputData("content")}
                />
                {error && (Validators.required(formData.content))}
                <div className="form-checkbox-contacts">
                  <FormControlLabel
                    control={<Checkbox onChange={(event) => changeChBox(event)} checked={checkBOx} />}
                    label="Соглашаюсь с офертой и обработкой персональных данных"
                  />
                </div>
                <button className={`about-sub__button contacts-b ${sending ? 'whiteBtn' : ''}`} onClick={submitFormData}>{sending ? 'Отправлено' : 'Отправить'}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
