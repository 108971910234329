import React from 'react';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import defaultAvatarIcon from '@images/design/default-avatar.svg';
import './UserAvatar.scss';

export const UserAvatar = ({ className, user }) => {
  return (
    <div className={clsx('UserAvatar', className)}>
      <div className="UserAvatar__Container">
        { user && user.imageUrl
          ? <img src={user.imageUrl} />
          : <SVG src={defaultAvatarIcon} /> }
      </div>
    </div>
  )
}