import React from 'react';
import clsx from 'clsx';
import './RoundedButton.scss';
import { NavLink } from 'react-router-dom';

const ButtonWrapper = ({ children, to, href, ...props }) => {
  if (href)
    return <a { ...props } href={ href }>{ children }</a>

  return to
    ? <NavLink activeClassName="active" to={to} {...props}>{children}</NavLink>
    : <button type="button" {...props}>{children}</button>
};

export const RoundedButton = React.memo(({
  className,
  color = 'primary',
  size = '',
  children,
  ...props
}) => {
  return (
    <ButtonWrapper
      className={clsx(
        "UIRoundedButton",
        `UIRoundedButton--${color}`,
        size && `UIRoundedButton--${size}`,
        className
      )}
      {...props}
    >
      { children }
    </ButtonWrapper>
  );
})
