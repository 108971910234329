import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loadPrizes, takePrize, tryToLoadPrizes } from "@redux/actions/loyalty";
import { UIRoundedButton, UIPrizesList, UIPortal } from "@UIKit";
import PrizesPageTop from "./PrizesPageTop";
import "./PrizesPage.scss";

function filterAvailablePrizes(prizes) {
  const isAvailable = (prize) => prize.isEnough || prize.isCta;
  const availablePrizes = prizes.filter(isAvailable);
  const remainingPrizes = prizes.filter((prize) => !isAvailable(prize));

  return [availablePrizes, remainingPrizes];
}

const PrizesPage = ({ prizes, isAuthenticated, history, location, tryToLoadPrizes }) => {
  const [availablePrizes, setAvailablePrizes] = React.useState([]);
  const [remainingPrizes, setRemainingPrizes] = React.useState([]);

  React.useEffect(() => {
    tryToLoadPrizes();
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated) {
      setAvailablePrizes(prizes);
      setRemainingPrizes([]);
      return;
    }

    const [availablePrizes, remainingPrizes] = filterAvailablePrizes(prizes);
    setAvailablePrizes(availablePrizes);
    setRemainingPrizes(remainingPrizes);
  }, [prizes, isAuthenticated]);

  const handleTasksClick = React.useCallback(() => history.push("/loyalty/tasks"), []);

  const handlePrizeSelect = React.useCallback(
    (prize) => history.push(`/loyalty/prizes/tobuyprize/${prize.id}` + location.search),
    []
  );

  return (
    <>
      <div className="PrizesPage">
        <div className="PrizesPage__Content">
          <h2 className="PrizesPage__Title">Потратить баллы</h2>

          <PrizesPageTop onTasksClick={handleTasksClick} isAuthenticated={isAuthenticated} />
        </div>

        <UIPrizesList
          list={availablePrizes}
          className="PrizesPage__PrizesList"
          onSelect={handlePrizeSelect}
          disabled={!isAuthenticated}
        />

        {remainingPrizes.length > 0 && (
          <>
            <div className="PrizesPage__Content">
              <h2 className="PrizesPage__Title">Не хватает баллов</h2>

              <div className="PrizesPage__Description">
                Выполните еще несколько заданий, чтобы заработать баллы на более ценные партнёрские призы
              </div>
            </div>

            <UIPrizesList list={remainingPrizes} disabled />
          </>
        )}
      </div>

      <UIPortal className="PrizesPage__Portal">
        <div className="PrizesPage__ButtonWrapper">
          <UIRoundedButton className="PrizesPage__Button" color="king" onClick={handleTasksClick}>
            Перейти к заданиям
          </UIRoundedButton>
        </div>
      </UIPortal>
    </>
  );
};

const mapStateToProps = ({ loyalty: { prizes }, auth: { isAuthenticated } }) => {
  return {
    prizes,
    isAuthenticated,
  };
};

const mapStateToDispatch = (dispatch) =>
  bindActionCreators(
    {
      loadPrizes,
      takePrize,
      tryToLoadPrizes,
    },
    dispatch
  );

export default connect(mapStateToProps, mapStateToDispatch)(PrizesPage);
