import React from "react";
import "./Team.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";
import phoneCity from "@images/phone-city.jpg";

import golovanodM from "@images/golovanod_m.jpg";
import gandurinaO from "@images/gandurina_o.jpg";
import makaedN from "@images/makaed_n.jpg";
import sobolevE from "@images/sobolev_e.jpg";
import kanishevA from "@images/kanishev_a.jpg";
import hisamudinovaU from "@images/hisamudinova_u.jpg";

const Team = () => {
  return (
    <div className="team">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/team">Команда</Link>
        </div>
        <h1 className="about-sub__title">Команда</h1>
        <div className="team__wrapper">
          <div className="team__card">
            <div className="team__wrapper-img">
              <img src={golovanodM}/>
            </div>
            <div className="team__name">Максим Голованов</div>
            <div className="team__profession">Руководитель проекта</div>
          </div>
          <div className="team__card">
            <div className="team__wrapper-img">
              <img  src={makaedN}/>
            </div>
            <div className="team__name">Наталья Макаед</div>
            <div className="team__profession">Руководитель программы лояльности</div>
          </div>
          <div className="team__card">
            <div className="team__wrapper-img">
              <img src={gandurinaO}/>
            </div>
            <div className="team__name">Ольга Гандурина</div>
            <div className="team__profession">Руководитель программы лояльности</div>
          </div>
          <div className="team__card">
            <div className="team__wrapper-img">
              <img src={sobolevE}/>
            </div>
            <div className="team__name">Евгений Соболев</div>
            <div className="team__profession">руководитель группы поддержки</div>
          </div>
          <div className="team__card">
            <div className="team__wrapper-img">
              <img src={kanishevA}/>
            </div>
            <div className="team__name">Антон Канищев</div>
            <div className="team__profession">Менеджер по работе с партнёрами</div>
          </div>
          <div className="team__card">
            <div className="team__wrapper-img">
              <img src={hisamudinovaU} />
            </div>
            <div className="team__name">Юлия Хисамудинова</div>
            <div className="team__profession">Пиар менеджер</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
