import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { Link } from 'react-router-dom';
import phone from '../../img/phone.jpg';
import phoneDesktop from '../../img/phone-desktop.jpg';
import magnitPartner from "../../img/magnit.png";

// import logo1 from '../img/svg/+1_black.svg'
// import logoP from '../img/svg/logo-p.svg'

import { fetchAbout, setAbout } from '../../redux/actions/about';
import { fetchCities } from "../../redux/actions/map";
import withTracker from '../HOC/withTracker';
import { AppPartners } from '@UIKit/AppPartners/AppPartners';
import { commonService } from '@services/commonService';
import SVG from 'react-inlinesvg';

import playMarket from '../../img/svg/play-market-about.svg';
import appStore from '../../img/svg/app-store-about.svg';

import dotsAbout from '../../img/svg/dots-about.svg';
import categoriesAbout from '../../img/svg/categories-about.svg';
import citiesAbout from '../../img/svg/cities-about.svg';

import bottleAbout from '../../img/svg/bottle-about.svg';
import groupDotsAbout from '../../img/svg/group-dots-about.svg';
import handHeartAbout from '../../img/svg/hand-heart-about.svg';
import heartAbout from '../../img/svg/heart-about.svg';
import shortsAbout from '../../img/svg/shorts-about.svg';
import storeAbout from '../../img/svg/store-about.svg';
import arrowLeft from '../../img/svg/arrow-about.svg';
import './about.scss';
import svhePartner from "@images/about-logo-svhe.jpg";
import mtsBankPartner from "@images/about-logo-mts-bank.jpg";
import mcdPartner from "@images/about-logo-macd.jpg";
import vkusvillPartner from "@images/about-logo-vkusvil.jpg";

import booksshopPartner from "@images/about-logo-booksshop.jpg";
import botavikosPartner from "@images/about-logo-botavikos.jpg";
import brucePartner from "@images/about-logo-bruce.jpg";
import chomPartner from "@images/about-logo-chom.jpg";
import ecodomPartner from "@images/about-logo-ecodom.jpg";

import ecoschoolPartner from "@images/about-logo-ecoschool.jpg";
import ecotovariPartner from "@images/about-logo-ecotovari.jpg";
import gggPartner from "@images/about-logo-ggg.jpg";
import levranaPartner from "@images/about-logo-levrana.jpg";
import litresPartner from "@images/about-logo-litres.jpg";

import mirPartner from "@images/about-logo-mir.jpg";
import neopurePartner from "@images/about-logo-neopure.jpg";
import nsPartner from "@images/about-logo-ns.jpg";
import organicshopPartner from "@images/about-logo-organicshop.jpg";
import ozonPartner from "@images/about-logo-ozon.jpg";

import pgPartner from "@images/about-logo-pg.jpg";
import primePartner from "@images/about-logo-prime.jpg";
import redPenPartner from "@images/about-logo-red-pen.jpg";
import reebokPartner from "@images/about-logo-reebok.jpg";
import treugPartner from "@images/about-logo-treug.jpg";

import tutoronlinePartner from "@images/about-logo-tutoronline.jpg";
import vigetarianPartner from "@images/about-logo-vigetarian.jpg";
import vnovPartner from "@images/about-logo-vnov.jpg";
import whynotPartner from "@images/about-logo-whynot.jpg";

const frontload = async ({ fetchAbout, fetchCities }) => {
  await fetchAbout();
  await fetchCities();
};

@connect(({ about, appState, map }) => ({
  ...about,
  ...appState,
  cities: map.cities.filter(city => city.smiUrl != null),
}), {
  setAbout,
  fetchAbout,
  fetchCities
})
@frontloadConnect(frontload, { onMount: true, onUpdate: false })
@withTracker
class ModalAbout extends PureComponent {
  static propTypes = {
    text: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string
    })).isRequired,
    setAbout: PropTypes.func,
    cities: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string,
      hasOnlinePoints: PropTypes.bool,
      id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      slug: PropTypes.string,
      smiLogo: PropTypes.string,
      smiTitle: PropTypes.string,
      smiUrl: PropTypes.string,
      title: PropTypes.string,
    })).isRequired,
  };

  createMarkup = text => ({ __html: text });

  handleEmailClick = () => {
    commonService.sendEvent({ category: 'about_mail' });
  }



  render() {
    const { cities } = this.props;
    return (
      <div className='page-about'>
        <div className="city-info">
            <div className='CONTAINER'>
              <div className='first-block mobile'>
                <div className='breadcrumbs'><Link to={location => ({ ...location, pathname: "/" })}>Главная</Link><SVG src={arrowLeft}></SVG><Link to='/about'>О проекте</Link></div>
                <div className='about__text f'>+1Город — это интерактивный помощник, который позволяет быстро и просто найти социальные и экологические сервисы в любой точке России. </div>
                <div className='about__img'><img src={phone} /></div>
                <div className='about__store'>
                  <a href="https://play.google.com/store/apps/details?id=com.poleznygorod.cityplusone">
                    <SVG src={playMarket} />
                  </a>
                  <a href="https://apps.apple.com/ru/app/%D1%8D%D0%BA%D0%BE-%D0%BF%D0%B5%D1%80%D0%B5%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0-%D1%83%D1%82%D0%B8%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F/id1505256876">
                    <SVG src={appStore} />
                  </a>
                </div>
              </div>
              <div className='desktop'>
                <div className='first-block'>
                  <div className='breadcrumbs'><Link to={location => ({ ...location, pathname: "/" })}>Главная</Link><SVG src={arrowLeft}></SVG><Link to='/about'>О проекте</Link></div>
                  <div className='about__inner-wrapper'>
                    <div className='about-leftblock'>
                      {this.props.text.length && this.props.text.map((item, i) => item.slug === "about1" ? <div className='about__text' key={"i"} dangerouslySetInnerHTML={this.createMarkup(item.content)}></div> : null )}
                      <div className='about__store'>
                        <a href="https://play.google.com/store/apps/details?id=com.poleznygorod.cityplusone&hl=ru&gl=US&showAllReviews=true"><SVG src={playMarket} /></a>
                        <a href="https://apps.apple.com/ru/app/%D1%8D%D0%BA%D0%BE-%D0%BF%D0%B5%D1%80%D0%B5%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0-%D1%83%D1%82%D0%B8%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F/id1505256876"><SVG src={appStore} /></a>
                      </div>
                    </div>
                    <div className='about__img'><img src={phoneDesktop} /></div>
                  </div>
                </div>
              </div>
              <div className='about__city-numbers'>
                <h2>+1Город в цифрах</h2>
                <ul>
                  <li>
                    <span><SVG src={citiesAbout} /></span>
                    <div className='about__numbers-dots'>
                      {this.props.text.length && this.props.text.map((item, i) => item.slug === "about2mob" ? <div className='about__numbers' key={"i"} dangerouslySetInnerHTML={this.createMarkup(item.content)}></div> : null )}
                      <div className='about__p-dots'>полезных точек</div>
                    </div>
                  </li>
                  <li>
                    <span><SVG src={categoriesAbout} /></span>
                    <div className='about__numbers-dots'>
                      {this.props.text.length && this.props.text.map((item, i) => item.slug === "about3mob" ? <div className='about__numbers' key={"i"} dangerouslySetInnerHTML={this.createMarkup(item.content)}></div> : null )}
                      <div className='about__p-dots'>городов и населенных пунктов</div>
                    </div>
                  </li>
                  <li>
                    <span><SVG src={dotsAbout} /></span>
                    <div className='about__numbers-dots'>
                      {this.props.text.length && this.props.text.map((item, i) => item.slug === "about_img_web" ? <div className='about__numbers' key={"i"} dangerouslySetInnerHTML={this.createMarkup(item.content)}></div> : null )}
                      <div className='about__p-dots'>категорий полезных сервисов</div>
                    </div>
                  </li>
                </ul>
              </div>
              {this.props.text.length && this.props.text.map((item, i) => item.slug === "about2web" ? <div className='about__text project' key={"i"} dangerouslySetInnerHTML={this.createMarkup(item.content)}></div> : null )}
              <div className='about__help-list'>
                <h2>Узнайте с помощью +1Город</h2>
                <ul>
                  <li>
                    <span>
                      <SVG src={bottleAbout} />
                    </span>
                    <div>где можно сдать использованные батарейки, пластик, шины и другие виды отходов, чтобы их гарантированно увезли на переработку</div>
                  </li>
                  <li>
                    <span>
                      <SVG src={shortsAbout} />
                    </span>
                    <div>куда принести ненужную одежду</div>
                  </li>
                  <li>
                    <span>
                      <SVG src={storeAbout} />
                    </span>
                    <div>в каких магазинах можно купить товары социальных предпринимателей</div>
                  </li>
                  <li>
                    <span>
                      <SVG src={heartAbout} />
                    </span>
                    <div>где можно оказать или получить помощь в трудной жизненной ситуации</div>
                  </li>
                  <li>
                    <span>
                      <SVG src={handHeartAbout} />
                    </span>
                    <div>как поддержать проверенный благотворительный фонд</div>
                  </li>
                  <li>
                    <span>
                      <SVG src={groupDotsAbout} />
                    </span>
                    <div>и многое другое</div>
                  </li>
                </ul>
              </div>
              <div className='container-about'>
                {this.props.text.length && this.props.text.map((item, i) => item.slug === "about3web" ? <div className='about__text f about__cooperation' key={"i"} dangerouslySetInnerHTML={this.createMarkup(item.content)}></div> : null )}
                <div className='about__cooperation-wrapper'>
                  <a target="_blank" rel='noreferrer' href="https://admin.poleznygorod.ru/media/django-summernote/2022-07-11/32058375-1231-4a5e-bd3e-1078d8e95d17.pdf" className='button-about'>Скачать презентацию о проекте</a>
                  <p>Мобильное приложение +1Город было создано с использованием средств, предоставленных Фондом президентских грантов на развитие гражданского общества.</p>
                </div>
              </div>
              <div className='about__partners'>
               <h2 className='about__subtitle'>Партнеры</h2>
                <div className='about__partners-wrapper'>
                  <div className='about__partner-wrapper-img'><img src={magnitPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={svhePartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={mtsBankPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={mcdPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={vkusvillPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={ozonPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={pgPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={gggPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={litresPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={vigetarianPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={primePartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={nsPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={reebokPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={ecodomPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={organicshopPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={botavikosPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={whynotPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={brucePartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={ecotovariPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={redPenPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={mirPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={neopurePartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={booksshopPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={tutoronlinePartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={chomPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={levranaPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={ecoschoolPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={treugPartner}/></div>
                  <div className='about__partner-wrapper-img'><img loading="lazy" src={vnovPartner}/></div>
                </div>
              </div>
            </div>
        </div>
      </div >
    )
  }
}

export default ModalAbout;
