import React from "react";
import facebookIcon from "@images/design/oauth/facebook.svg";
import facebookColorIcon from "@images/design/oauth/facebook-color.svg";
import googleIcon from "@images/design/oauth/google.svg";
import googleColorIcon from "@images/design/oauth/google-color.svg";
import vkIcon from "@images/design/oauth/vk.svg";
import vkColorIcon from "@images/design/oauth/vk-color.svg";
import SVG from "react-inlinesvg";
import "./SocialAuth.scss";
import SocialButton from "./SocialButton";
import VKButton from "./VKButton";
import { Switch, Route, useRouteMatch, withRouter } from "react-router-dom";
import RedirectPage from "./RedirectPage";
import { APP_URL, VK_ID_CLIENT, FACEBOOK_ID_CLIENT, GOOGLE_ID_CLIENT } from "@constants";
import clsx from 'clsx';
import { identity } from 'lodash';
import { providerMap } from '@UIKit/utils/maps';

const SocialAuth = React.memo(({
  onLoginFailure = identity,
  onLoginSuccess = identity,
  className,
  onSocialLogin,
  social
}) => {
  const { path } = useRouteMatch();
  const [ readOnly, setReadOnly ] = React.useState(false);
  const [ indx, setIndx ] = React.useState(0);

  React.useEffect(() => {
    setReadOnly(Boolean(social))
  }, [ social ])

  const handleLoginSuccess = ({ provider, token: { accessToken } }) => {
    onLoginSuccess({ provider, accessToken });
  };

  const hasSocial = (provider) => {
    return social.includes(providerMap.get(provider))
  }

  const handleLoginFailure = () => {
    setIndx(indx + 1);
    onLoginFailure();
  }

  const handleClick = (network) => {
    return () => {
      onSocialLogin(network);
    }
  }

  return (
    <>
      <div className={clsx("SocialAuth", className)}>
        <p className="SocialAuth__Title">
          { readOnly ? 'Связанные сети' : 'Войти с помощью' }
        </p>

        <ul className="SocialAuth__List">
          <li className="SocialAuth__ListItem">
            <SocialButton
              provider="facebook"
              appId={FACEBOOK_ID_CLIENT}
              onLoginSuccess={handleLoginSuccess}
              onLoginFailure={handleLoginFailure}
              readOnly={readOnly}
              key={indx}
              className="SocialButton--fb"
              onClick={handleClick('fb')}
            >
              <SVG src={readOnly && hasSocial('facebook') ? facebookColorIcon : facebookIcon} />
            </SocialButton>
          </li>

          <li className="SocialAuth__ListItem">
            <SocialButton
              provider="google"
              appId={GOOGLE_ID_CLIENT}
              onLoginSuccess={handleLoginSuccess}
              onLoginFailure={handleLoginFailure}
              readOnly={readOnly}
              key={indx + 1}
              className="SocialButton--google"
              onClick={handleClick('google')}
            >
              <SVG src={readOnly && hasSocial('google') ? googleColorIcon : googleIcon} />
            </SocialButton>
          </li>
          <li className="SocialAuth__ListItem">
            <VKButton
              provider="vkontakte"
              appId={VK_ID_CLIENT}
              redirectURI={`${APP_URL}${path}/redirect`}
              onLoginSuccess={handleLoginSuccess}
              readOnly={readOnly}
              onClick={handleClick('vk')}
            >
              <SVG src={readOnly && hasSocial('vkontakte') ? vkColorIcon : vkIcon} />
            </VKButton>
          </li>
        </ul>
      </div>
      <Switch>
        <Route path={`${path}/redirect`}>
          <RedirectPage />
        </Route>
      </Switch>
    </>
  );
});

export default withRouter(SocialAuth);
