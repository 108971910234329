import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';
import { LOCATION_CHANGE } from 'connected-react-router';

const regexps = [
  /\/about(\?city=)?/,
  /\/new(\?city=)?/,
  /\/filter\/otvetstvenno-potreblyat(\?city=)?/,
  /\/filter\/hochu-pomoch-poluchit-pomosch(\?city=)?/,
  /\/filter\/interesno-provesti-vremya(\?city=)?/,
  /\/points\/fromcatalog\/\d+(\?city=)?/,
  /\/points\/fromquery\/\d+(\?city=)?/,
  /\/points\/frommap\/\d+(\?city=)?/,
  /^\/\?city=\w+&search=\S+?&lat=\S+?&long=\S+$/,
  /\/b2c(\?city=)?/,
  /\/b2b(\?city=)?/,
  /\/why(\?city=)?/,
  /\/sign-in(\?city=)?/,
  /\/sign-up(\?city=)?/,
  /\/sign-up-thanks(\?city=)?/,
  /\/mail-verification(\?city=)?/,
  /\/mail-verification-finish(\?city=)?/,
  /\/mail-verification-not-finish(\?city=)?/,
  /\/geekpicnic\/sign-ip(\?city=)?/,
  /\/coca-cola\/sign-ip(\?city=)?/,
  /\/coca-cola\/auth(\?city=)?/,
  /\/ecoin\/sign-ip(\?city=)?/,
  /\/ecoin\/auth(\?city=)?/,
  /\/grazia\/sign-ip(\?city=)?/,
  /\/grazia\/auth(\?city=)?/,
  /\/faceslaces\/sign-ip(\?city=)?/,
  /\/faceslaces\/auth(\?city=)?/,
  /\/nordicweeks\/sign-ip(\?city=)?/,
  /\/nordicweeks\/auth(\?city=)?/,
  /\/loyalty(\?city=)?/
]

export const isCorrectUrl = (pageUrl) => regexps.some(regexp => {
  return regexp.test(pageUrl);
})

const trackPage = pageUrl => {
  if (!isCorrectUrl(pageUrl)) {
    return;
  }

  ReactGA.pageview(pageUrl);
  setTimeout(() => ym('hit', pageUrl));
};

export const analyticsMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE) {
    const { pathname, search } = action.payload.location;
    const nextPage = `${pathname}${search}`;

    trackPage(nextPage);
  }
  return next(action);
};

