import { SET_POINTS } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

const initialState = {
  points: [],
  filteredPoints: null,
  filters: [],
  pointObjects: [],
  categoryFilters: [],
  categories: [],
  subcategories: [],
  pointsTypes: [],
  categoriSelect: '',
  tagFilter: false,
  isOpenFilterModal: false,
  onlineServiceOpen: false,
  isFilter: true,
  needUpdate: false,
  cityToOnlinePointsMap: [],
  noPointsInCity: false,
  selectedPointIds: [],
  fromRubricator: false,
  hidePoints: null
};

export default makeReducer(initialState, SET_POINTS);
