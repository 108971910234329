import React from 'react'
import SVG from 'react-inlinesvg'
import { UIRoundedButton } from '@UIKit'
import basketman from '@images/design/basketman.svg'
import appStore from '@images/design/mobile/apple-store.svg'
import googlePlay from '@images/design/mobile/google-play.svg'
import { mobileApps } from '@services/commonService'
import './CardMobile.scss'


function CardMobile({ isMobile }) {
  return (
    <div className="CardMobile">
      <div className="CardMobile__Description">
        <p className="CardMobile__Title">
          Установи приложение +1Город!
        </p>

        <p className="CardMobile__Text">
          Установите мобильное приложение +1Город, чтобы мгновенно выполнять задания и обменивать баллы на призы прямо со своего телефона!
        </p>
      </div>

      <div className="CardMobile__Image">
        <SVG src={ basketman } />
      </div>

      <div className="CardMobile__Apps">
        { isMobile
          ? <UIRoundedButton
              href={ 'http://onelink.to/v7sa3d' }
              target="_blank"
              size="large"
            >
              Скачать приложение
            </UIRoundedButton>
          : <>
              <a href={ mobileApps.iOS } target="_blank" rel="noopener noreferrer">
                <SVG src={ appStore } />
              </a>
              <a href={ mobileApps.Android } target="_blank" rel="noopener noreferrer">
                <SVG src={ googlePlay } />
              </a>
            </>
        }
      </div>

    </div>
  )
}

export default CardMobile
