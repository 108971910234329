import {SET_APP_STATE} from '../constants/index';
import * as API from '../../api/appState';

export const setAppState = payload => ({ type: SET_APP_STATE, payload });

export const fetchConfig = () => async dispatch => {
  const { data: config } = await API.fetchConfig();

  dispatch(setAppState({
    config,
    shouldShowAnnouncement: !!config.crossmenuText
  }));
}
