import React from "react";
import { withRouter } from "react-router-dom";
import { push, replace } from "connected-react-router";
import { find, filter, compose, chunk as chunkFp } from "lodash/fp";
import _debounce from "lodash.debounce";
import _isEqual from "lodash.isequal";
import _flatMap from "lodash.flatmap";
import _chunk from "lodash.chunk";
import _sortBy from "lodash.sortby";
import _find from "lodash.find";
import baseDebug from "debug";
import turfFlip from "@turf/flip";
import { point as turfPoint } from "@turf/helpers";
import turfDistance from "@turf/distance";
import { connect } from "react-redux";
import { setMap } from "../redux/actions/map";
import { setPoints, fetchPoints, getPins, loadOnlinePoints, setSearchResult } from "../redux/actions/points";
import { setAppState } from "../redux/actions/appState";
import { getQueryVariable } from "../utils/queryHelper";
import clusterIcon from "../img/marker2.svg";
import { commonService } from "../services/commonService";
import { initializeMap } from "../redux/actions/map";
import { NoPointDialog } from "./NoPointDialog";
import { size, intersection } from "lodash";
import { TouchWrapper } from "./TouchWrapper";
import { take } from "rxjs/operators";
import { APP_URL, MAX_ZOOM, MIN_ZOOM } from '../constants/config';
const debug = baseDebug("gorod:NewMap");

function mapProps(state) {
  return {
    serviceFunctions: state.points.filters,
    noPointsInCity: state.points.noPointsInCity,
    mapIsReady: state.map.isReady,
    zoom: state.map.zoom,
    tiles: state.map.tiles,
    center: state.map.center,
    slug: state.map.slug,
    filterFunction: Object.keys(state.points.categoryFilters).length > 0 ? state.points.categoryFilters : null,
    tag: state.points.tagFilter || null,
    cities: state.map.cities,
    city: state.map.city,
    modalPin: state.modalPin,
    filteredPoints: state.points.filteredPoints,
    cityToOnlinePointsMap: state.points.cityToOnlinePointsMap,
    wasCitySelected: state.appState.wasCitySelected,
    isLoaded: state.appState.isLoaded,
    appConfig: state.appState.config
  };
}

const CITY_LEVEL_ZOOM = 12;
@connect(
  mapProps,
  {
    initializeMap,
    dispatchSetMap: setMap,
    dispatchSetPoints: setPoints,
    dispatchSetAppState: setAppState,
    setSearchResult,
    getPins,
    fetchPoints,
    loadOnlinePoints,
    replacePath: replace,
    pushPath: push
  }
)
@withRouter
class NewMap extends React.PureComponent {
  containerRef = React.createRef();
  chunks = [];
  totalFeatures = 0;

  constructor(props) {
    super(props);
    this._handleCenterChange = _debounce(this._handleCenterChange, 500);
    this.throttledAddPoints = _debounce(() => requestAnimationFrame(this.addPoints), 500);
    this.finishPointLoadingChanges$ = commonService.finishPointLoadingChanges();
    this.finishPointLoadingChanges$.subscribe();
  }

  iconContentLayout(shadow, temporary, star, activeStar) {
    if (shadow) {
      if (temporary && star) {
        return ymaps.templateLayoutFactory.createClass(
          `<div class="mapIconShadowed">` +
            (activeStar ? `<div class="mapIconActiveStar"></div>` : `<div class="mapIconStar"></div>`) +
            `<div class="mapIconTemp" style="transform: translate(10px, -25px);">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.57 21.57">
                <circle cx="10.79" cy="10.79" r="10.79" fill="#fff"/>
                <path d="M10.8 18.82a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm0-15.07a7 7 0 1 0 7 7 7 7 0 0 0-7-7z"/>
                <path d="M14.4 13.83l-4.11-2.78V5.64h1v4.88L14.96 13l-.56.83z"/>
              </svg>
            </div>
          </div>`
        );
      }
      if (temporary) {
        return ymaps.templateLayoutFactory.createClass(
          `<div class="mapIconShadowed">
            <div class="mapIconTemp">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.57 21.57">
                <circle cx="10.79" cy="10.79" r="10.79" fill="#fff"/>
                <path d="M10.8 18.82a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm0-15.07a7 7 0 1 0 7 7 7 7 0 0 0-7-7z"/>
                <path d="M14.4 13.83l-4.11-2.78V5.64h1v4.88L14.96 13l-.56.83z"/>
              </svg>
            </div>
          </div>`
        );
      }
      if (star) {
        return ymaps.templateLayoutFactory.createClass(
          `<div class="mapIconShadowed">` +
            (activeStar ? `<div class="mapIconActiveStar"></div>` : `<div class="mapIconStar"></div>`) +
            `</div>`
        );
      }
      return ymaps.templateLayoutFactory.createClass(
        `<div class="mapIconShadowed">
      </div>`
      );
    } else {
      if (temporary && star) {
        return ymaps.templateLayoutFactory.createClass(
          (activeStar ? `div class="mapIconActiveStar"></div>` : `<div class="mapIconStar"></div>`) +
            `<div class="mapIconTemp" style="transform: translate(10px, -25px);">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.57 21.57">
              <circle cx="10.79" cy="10.79" r="10.79" fill="#fff"/>
              <path d="M10.8 18.82a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm0-15.07a7 7 0 1 0 7 7 7 7 0 0 0-7-7z"/>
              <path d="M14.4 13.83l-4.11-2.78V5.64h1v4.88L14.96 13l-.56.83z"/>
            </svg>
          </div>`
        );
      }
      if (temporary) {
        return ymaps.templateLayoutFactory.createClass(
          `<div class="mapIconTemp">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.57 21.57">
              <circle cx="10.79" cy="10.79" r="10.79" fill="#fff"/>
              <path d="M10.8 18.82a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm0-15.07a7 7 0 1 0 7 7 7 7 0 0 0-7-7z"/>
              <path d="M14.4 13.83l-4.11-2.78V5.64h1v4.88L14.96 13l-.56.83z"/>
            </svg>
          </div>`
        );
      }
      if (star) {
        return ymaps.templateLayoutFactory.createClass(
          activeStar ? `<div class="mapIconActiveStar"></div>` : `<div class="mapIconStar"></div>`
        );
      }
    }
  }

  get iconContentLayoutStar() {
    return ymaps.templateLayoutFactory.createClass(
      `<div class="mapIconShadowed">
        <div class="mapIconStar">
        </div>
      </div>`
    );
  }

  loadYMap() {
    commonService.loadYMaps(document.body).then(() => {
      this.props.dispatchSetMap({ isReady: true });
    });
  }

  componentDidMount() {
    window.onBeforeCallback = this.onBeforeCallback.bind(this);

    const { location, dispatchSetMap, loadOnlinePoints } = this.props;
    const citySlug = getQueryVariable(location, "city");

    this.loadYMap();

    if (!this._citiesStruct && this.props.cities.length) {
      this._citiesStruct = commonService.createCitiesStruct(this.props.cities);
    }
    if (citySlug) {
      const isNotPoint = _isEqual(-1, location.pathname.indexOf("points"));
      const cityBySlug = find({ slug: citySlug }, this._citiesStruct);
      if (cityBySlug) {
        dispatchSetMap({
          city: cityBySlug.name,
          fullCity: cityBySlug,
          ...(isNotPoint && { center: cityBySlug.center, currentCenter: cityBySlug.center })
        });
        loadOnlinePoints();
      }
    }

    document.addEventListener("click", event => {
      if (event.target.closest(".clusterer-list__hint")) {
        this.handleClustererListHintClick(event);
      }
    });
  }

  handleClustererListHintClick(event) {
    const { location, pushPath } = this.props;

    const pointEl = event.target.parentElement.parentElement;
    const pointId = +pointEl.id;

    pushPath(`/points/${pointId}${location.search}`);
  }

  _showClosest() {
    const bounds = this.map.getBounds();
    const center = turfPoint(this.map.getCenter());
    const visibleObjects = [];
    const closest = {
      object: null,
      dist: -1
    };

    this.objectManager.objects.each(object => {
      const objectState = this.objectManager.getObjectState(object.id);
      if (!objectState.isFilteredOut) {
        const [x, y] = object.geometry.coordinates;
        const [[x1, y1], [x2, y2]] = bounds;
        if (x >= x1 && x <= x2 && y >= y1 && y <= y2) {
          visibleObjects.push(object);
        } else {
          const dist = turfDistance(center, turfPoint(object.geometry.coordinates));
          if (dist < closest.dist || closest.dist === -1) {
            closest.dist = dist;
            closest.object = object;
          }
        }
      }
    });

    if (visibleObjects.length === 0 && closest.object) {
      this.props.dispatchSetMap({
        center: closest.object.geometry.coordinates,
        currentCenter: closest.object.geometry.coordinates,
        zoom: 18
      });
    }
  }

  addPoints = () => {
    const { filterFunction, tag } = this.props;
    const { dispatchSetPoints, dispatchSetAppState } = this.props;
    let filteredPoints = [];
    this.pointObjects = this.objectManager.objects.getAll();
    let p_o = this.pointObjects;
    let onlinePoints = this.props.cityToOnlinePointsMap;

    if (tag) {
      p_o = p_o.filter(p => p.properties.tag === tag);
      onlinePoints = onlinePoints.filter(p => p.pointTag === tag);
    }

    let chunks = null;
    let filters = [];

    if (filterFunction) {
      const fnKeys = Object.keys(filterFunction);
      const selection = fnKeys.filter(fnKey => {
        return filterFunction[fnKey].selected;
      });

      if (selection.length) {
        filters = selection.map(Number);
      } else {
        fnKeys.forEach(function(key) {
          filters = filters.concat(+key).map(Number);
        });
      }
    } else {
      filters = null;
    }

    if (filterFunction) {
      chunks = compose(
        chunkFp(30000),
        filter(({ properties }) => {
          return Boolean(size(intersection(properties.functions, filters)));
        })
      )(p_o);
      if (chunks[0]) {
        const center = turfPoint(this.map.getCenter());
        let f_p = chunks[0];
        let f_p_nearest = [];
        f_p.forEach(object => {
          const [x, y] = object.geometry.coordinates;
          const [x_c, y_c] = center.geometry.coordinates;

          object.approx_dist = Math.abs(x_c - x) + Math.abs(y_c - y) / 2;

          if (object.approx_dist < 1) {
            f_p_nearest.push(object);
          }
        });

        filteredPoints = f_p_nearest.sort(function(a, b) {
          if (a.approx_dist > b.approx_dist) {
            return 1;
          }
          if (a.approx_dist < b.approx_dist) {
            return -1;
          }
          return 0;
        });
        filteredPoints = filteredPoints.slice(0, 100);
      }

      const filteredOnlinePoints = onlinePoints.filter(onlinePoint => filters.includes(onlinePoint.functions[0].id))
      let r = filteredOnlinePoints.concat(filteredPoints);
      dispatchSetPoints({ filteredPoints: r });
    }

    this.handleFilterPoints(filters);

    dispatchSetAppState({ pointsLoaded: true });
  };

  composeBelongedFn(filters, filterFn = () => true) {
    return (point) => {
      if (!filters || !point) {
        return true && filterFn();
      }
      return Boolean(size(intersection(point.properties.functions, filters))) && filterFn();
    }
  }

  handleFilterPoints(filters) {
    this.objectManager.setFilter(
      this.composeBelongedFn(filters, this.objectManager.getFilter())
    );
    this.declusterizedObjectManager.setFilter(
      this.composeBelongedFn(filters, this.declusterizedObjectManager.getFilter())
    );
  }

  initializeMap() {
    const el = this.containerRef.current;
    const { modalPin, center, location } = this.props;

    this.props.initializeMap(location);

    this.map = new ymaps.Map(
      el,
      {
        center: this.props.center,
        currentCenter: this.props.center,
        zoom: this.props.zoom,
        controls: []
      },
      {
        yandexMapDisablePoiInteractivity: true,
        maxZoom: MAX_ZOOM,
        minZoom: MIN_ZOOM
      }
    );

    this.map.events.add("boundschange", e => {
      this.props.dispatchSetAppState({ mapChangedBounds: true });

      const zoom = e.originalEvent.newZoom;
      if (this.props.zoom !== zoom) {
        this.props.dispatchSetMap({ zoom });
      }
      if (this._citiesStruct && zoom >= CITY_LEVEL_ZOOM) {
        this._handleCenterChange(e.get("newCenter"));
      }

      this.setClusterizeObjectManagers(zoom >= MAX_ZOOM - 1);
    });

    this.objectManagerConfig = {
      clusterize: false,
      geoObjectHideIconOnBalloonOpen: false,
      geoObjectHasHint: true,
      geoObjectOpenHintOnHover: true
    }

    this.declusterizedObjectManager = new ymaps.LoadingObjectManager(
      `${APP_URL}/api/points_in_tile/?b=%b&declusterize=true`,
      this.objectManagerConfig
    );

    this.objectManager = new ymaps.LoadingObjectManager(
      `${APP_URL}/api/points_in_tile/?b=%b`,
      {
        splitRequests: false,
        ...this.objectManagerConfig,
        clusterize: true,
        clusterIcons: [
          {
            href: clusterIcon,
            size: [43, 43],
            offset: [-21, -23]
          },
          {
            href: clusterIcon,
            size: [50, 50],
            offset: [-25, -25]
          }
        ],
        zIndexHover: -1,
        gridSize: 128,
        clusterNumbers: [99],
        clusterIconColor: "#f9d605",
        clusterGroupByCoordinates: false,
        clusterDisableClickZoom: true,
        clusterOpenBalloonOnClick: false,
        clusterBalloonPanelMaxMapArea: 0,
        // clusterBalloonContentLayout: customBalloonContentLayout,
        clusterHideIconOnBalloonOpen: false,
        clusterHasBalloon: false
      });

    this.objectManager.objects.events.add('objectsadd', () => {
      this.throttledAddPoints();
    });

    this.declusterizedObjectManager.objects.events.add('objectsadd', () => {
      this.throttledAddPoints();
    });

    this.map.geoObjects.add(this.objectManager);
    this.map.geoObjects.add(this.declusterizedObjectManager);

    this.props.setMap(this.map);

    const handleObjectClick = (e) => {
      const objectId = e.get('objectId');
      this.props.dispatchSetPoints({ selectedPointIds: [ objectId ]});
      this._handlePointClick(objectId);
    }

    this.objectManager.objects.events.add('click', handleObjectClick);
    this.declusterizedObjectManager.objects.events.add('click', handleObjectClick);

    this.objectManager.clusters.events.add("click", this.handleClusterPointClick);

    if (this.props.tiles.length && this.props.serviceFunctions.length) {
      this._updatePoints();
    }

    // if (filterFunction) {
    //   this.objectManager.setFilter(function(object) {
    //     return object.properties.functions.indexOf(filterFunction) !== -1;
    //   });
    // }

    this._setCityByCenter(center);

    setTimeout(() => {
      if (modalPin.pin) {
        this._toggleMarker(modalPin.pin, true);
      }
    }, 1000);

    this.handleScrollBehaviour();
  }

  setClusterizeObjectManagers(declusterize) {
    this.objectManager.options.set('clusterize', !declusterize);
    this.declusterizedObjectManager.options.set('clusterize', !declusterize);
  }

  handleClusterPointClick = e => {
    const { location } = this.props;

    const ids = e
      .get("overlay")
      .getData()
      .features.map(x => x.id);

    if (this.map.getZoom() === MAX_ZOOM) {
      this.props.dispatchSetPoints({ selectedPointIds: ids })
      this.props.getPins(ids);
      const [pointId] = ids;
      const pinTranslit = commonService.toTranslit(this.map, pointId);
      this.props.pushPath(`/points/frommap/${ids}-${pinTranslit}${location.search}`);
    } else {
      const coords = e.get('coords');
      const zoom = this.map.getZoom();
      this.map.setCenter(coords, Math.min(zoom + 2, MAX_ZOOM), {
        duration: 400,
        checkZoomRange: true
      });
    }
  };

  handleScrollBehaviour() {
    if (!commonService.inIframe()) {
      return;
    }

    if (commonService.isMobileDevice()) {
      this.map.behaviors.disable("drag");
    }
    this.map.behaviors.disable("scrollZoom");
  }

  componentDidUpdate(prevProps) {
    debug("did update");
    const mapIsReadyChange = !prevProps.mapIsReady && this.props.mapIsReady;
    const isLoadedChange = !prevProps.isLoaded && this.props.isLoaded;

    if (!prevProps.wasCitySelected && this.props.wasCitySelected) {
      this.totalFeatures = 0;
    }

    if (
      (mapIsReadyChange && !this.map && this.props.isLoaded) ||
      (isLoadedChange && this.props.mapIsReady && !this.map)
    ) {
      ymaps.ready(() => this.initializeMap());
    }

    if (this.map) {
      if (
        (prevProps.tiles !== this.props.tiles && !_isEqual(prevProps.tiles, this.props.tiles)) ||
        (prevProps.serviceFunctions !== this.props.serviceFunctions &&
          !_isEqual(prevProps.serviceFunctions, this.props.serviceFunctions))
      ) {
        if (this.props.serviceFunctions.length && this.props.tiles.length) {
          this._updatePoints();
        }
      }
      if (prevProps.cityToOnlinePointsMap.length !== this.props.cityToOnlinePointsMap.length) {
        const addPointsFn = typeof requestIdleCallback === "function" ? requestIdleCallback : requestAnimationFrame;
        addPointsFn(this.addPoints);
      }

      if (this.props.center !== prevProps.center && this.props.zoom !== this.map.getZoom() && this._citiesStruct) {
        debug("set center", this.props.center, this.props.zoom);
        this.map.setCenter(this.props.center, this.props.zoom);
      } else if (this.props.center !== prevProps.center && this._citiesStruct) {
        debug("set center", this.props.center, CITY_LEVEL_ZOOM);
        this.map.setCenter(this.props.center, CITY_LEVEL_ZOOM);
      } else if (this.props.zoom !== this.map.getZoom()) {
        this.map.setZoom(this.props.zoom, { duration: 300 });
      }
      if (
        (this.props.filterFunction !== prevProps.filterFunction && this.pointObjects) ||
        (this.props.tag !== prevProps.tag && this.pointObjects)
      ) {
        const addPointsMethod = typeof requestIdleCallback === "function" ? requestIdleCallback : requestAnimationFrame;
        addPointsMethod(this.addPoints);
        this._showClosest();
      }

      if (!this._citiesStruct && this.props.cities.length) {
        this._createCitiesStruct();
      }
      if (this.props.modalPin.pin !== prevProps.modalPin.pin) {
        if (this.props.modalPin.pin) {
          this._toggleMarker(this.props.modalPin.pin, true);
        }

        if (prevProps.modalPin.pin) {
          this._toggleMarker(prevProps.modalPin.pin, false);
        }
      }
    }
  }

  _toggleMarker({ id, functions, logo, activeDateTo }, opened) {
    if (opened) {
      this.objectManager.objects.each(object => {
        if (!object.properties.functions.includes(functions[0].id)) {
          this.objectManager.objects.setObjectOptions(object.id, {
            iconContentLayout: this.iconContentLayout(true, object.properties.temporary, object.properties.star)
          });
        } else {
          this.objectManager.objects.setObjectOptions(object.id, {
            iconContentLayout: this.iconContentLayout(false, object.properties.temporary, object.properties.star)
          });
        }
      });
    } else {
      this.objectManager.objects.each(object => {
        this.objectManager.objects.setObjectOptions(object.id, {
          iconContentLayout: this.iconContentLayout(false, object.properties.temporary, object.properties.star)
        });
      });
    }

    if (!logo) {
      let object = this.objectManager.objects.getById(id);
      if (object) {
        this.objectManager.objects.setObjectOptions(id, {
          iconLayout: "default#imageWithContent",
          iconImageHref: opened ? functions[0].markerActive : functions[0].marker,
          iconImageSize: logo ? [44, 42] : [43, 43],
          iconImageOffset: logo ? [-22, -42] : [-22, -43],
          iconContentLayout: this.iconContentLayout(false, object.properties.temporary, object.properties.star, opened)
        });
      }
    }
  }


  render() {
    return (
      <>
        <TouchWrapper ref={this.containerRef}></TouchWrapper>
        {this.props.noPointsInCity && (
          <NoPointDialog
            handleClick={this.handleNoPointDialogClick}
            handleClose={this.handleNoPointDialogClose}
          />
        )}
      </>
    );
  }

  handleNoPointDialogClick = () => {
    const { history, location } = this.props;
    history.push(`/new?${location.search}`)
  }

  handleNoPointDialogClose = () => {
    this.props.dispatchSetPoints({ noPointsInCity: false})
  }

  getIconSize(logo) {
    const { appConfig } = this.props;

    if (appConfig.iconSize) {
      return {
        iconImageSize: [appConfig.iconSize, appConfig.iconSize],
        iconImageOffset: [- appConfig.iconSize / 2, - appConfig.iconSize]
      }
    }

    return {
      iconImageSize: logo ? [44, 42] : [43, 43],
      iconImageOffset: logo ? [-22, -42] : [-22, -43]
    };
  }

  _pointOptionsMarker(point, { logo }) {
    const func = _find(this.props.serviceFunctions, ["id", point.properties.functions[0]]);

    if (func || logo) {
      return {
        iconLayout: "default#imageWithContent",
        ...(point.properties.temporary && { iconContentLayout: this.iconContentLayout }),
        iconContentLayout: this.iconContentLayout(false, point.properties.temporary, point.properties.star),
        iconImageHref: logo || func.marker,
        markerImageHref: func.marker,
        markerFilterImageHref: func.markerFilter,
        ...this.getIconSize(logo)
      };
    }
    return {};
  }

  onBeforeCallback(callbackName, data) {
    const { wasCitySelected } = this.props;

    const formattedData = {
      ...data,
      features: data.features.map(feature => this.mapPoint(feature))
    }
    window[callbackName](formattedData);
    this.props.dispatchSetMap({ objectsOnMap: true });
    if (wasCitySelected) {
      this.totalFeatures += data.features.length;
      this.finishPointLoadingChanges$
        .pipe(take(1))
        .subscribe(() => this.handleCitySelection());
    }
  }

  handleCitySelection = () => {
    const { dispatchSetAppState, dispatchSetPoints } = this.props;
    dispatchSetAppState({ wasCitySelected: false });
    dispatchSetPoints({ noPointsInCity: !this.totalFeatures });
  }

  mapPoint(point) {
    return {
      type: "Feature",
      id: point.properties.id,
      geometry: {
        type: "Point",
        coordinates: point.geometry.coordinates
      },
      properties: {
        clusterCaption: "загрузка",
        functions: point.properties.functions,
        description: point.properties.description,
        tag: point.properties.tag,
        temporary: point.properties.temporary,
        declusterize: point.properties.declusterize,
        star: point.properties.star,
        title: point.properties.title,
        ...(point.properties.title && {
          hintContent: `<div class="hint">${point.properties.title}</div>`
        })
      },
      options: {
        ...this._pointOptionsMarker(point, point.properties),
        openEmptyBalloon: false,
        hintCloseTimeout: null
      }
    };
  }

  _updatePoints() {
    // invariant(!this.pointObjects, "points already added");
    if (this.pointObjects) return false;
    debug("update points");

    const knownFunctions = this.props.serviceFunctions.map(x => x.id);

    const center = turfPoint(this.map.getCenter());
    const tilePoints = _flatMap(this.props.tiles, tile => {
      return tile.properties.data.map(point => ({
        ...point,
        distanceToCenter: turfDistance(center, turfPoint(turfFlip(point).geometry.coordinates))
      }));
    }).filter(point => {
      for (const functionId of point.properties.functions) {
        if (knownFunctions.indexOf(functionId) !== -1) {
          return true;
        }
      }
      return false;
    });

    this.pointObjects = _sortBy(tilePoints, "distanceToCenter")
      .map(point => this.mapPoint(point));

    this.chunks = _chunk(this.pointObjects, 500);

    this.props.dispatchSetPoints({ pointObjects: this.pointObjects });

    const addPointsMethod = typeof requestIdleCallback === "function" ? requestIdleCallback : requestAnimationFrame;

    addPointsMethod(this.addPoints);
  }

  _handlePointClick = pointId => {
    const { pushPath, location, setSearchResult, dispatchSetPoints } = this.props;
    const pinTranslit = commonService.toTranslit(this.map, pointId);
    pushPath(`/points/frommap/${pointId}-${pinTranslit}/${location.search}`);
    setSearchResult({ searchQuery: null, modalSearchResult: false });
    dispatchSetPoints({ fromRubricator: false });
    this.map.balloon.close();
  };

  _setCityByCenter(coordinates) {
    const {
      loadOnlinePoints,
      dispatchSetMap,
      replacePath,
      location
    } = this.props;

    const center = turfPoint(coordinates);
    const closestCity = commonService.findClosestCity(center, this._citiesStruct);
    const citySlug = commonService.determineCity(location);
    if (this.props.city !== closestCity.name || citySlug !== closestCity.slug) {
      dispatchSetMap({ city: closestCity.name, fullCity: closestCity });
      loadOnlinePoints();
      const urlParams = new URLSearchParams(location.search);
      urlParams.set('city', closestCity.slug);
      replacePath(`${location.pathname}?${urlParams.toString()}`);
    }
  }

  _handleCenterChange(newCenter) {
    const { dispatchSetMap, wasCitySelected } = this.props;
    dispatchSetMap({ currentCenter: newCenter });
    if (!wasCitySelected) {
      return;
    }
    this.geocodeMe(newCenter);
    // dispatchSetAppState({ wasCitySelected: false });
  }

  geocodeMe(newCenter) {
    return ymaps
      .geocode(newCenter, {
        kind: "locality",
        results: 1
      })
      .then(r => {
        this._setCityByCenter(r.geoObjects.get(0).geometry.getCoordinates());
      })
      .catch(() => {
        this._setCityByCenter(newCenter);
      });
  }
}

export default NewMap;
