import React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';
import { Frontload } from 'react-frontload';
import './styles/index.scss';
import './fonts/index.scss';
import App from "./App";
import { debounce } from 'lodash';
import initReactFastClick from 'react-fastclick';
import { CookiesProvider } from "react-cookie";

// import registerServiceWorker from './registerServiceWorker';
import configureStore  from "./redux/store";

(function adjustViewPort() {
  const adjust = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  window.addEventListener('resize', debounce(adjust, 100));

  adjust();
})();

initReactFastClick();

try {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
} catch (err) {
  // pass
}

const { store,history } = configureStore();
window['store'] = store;
const Application = (
  <Provider store={store}>
      <ConnectedRouter history={history}>
      <CookiesProvider>
        <Frontload noServerRender={true}>
            <App />
        </Frontload>
        </CookiesProvider>
      </ConnectedRouter>
  </Provider>
);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, rootElement);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, rootElement);
}
