import React from "react";
import "./Prize_delivery.scss";
import SVG from "react-inlinesvg";
import arrow from "@images/svg/arrow-left-prize.svg";
import { Link } from "react-router-dom";

const Prize_delivery = () => {

  return (
    <div className="prize_delivery">
      <div className="prize_congratulation__container">
        <Link to="prize_congratulation">
          <SVG className="prize_congratulation__arrow" src={arrow} />
        </Link>
        <div className="prix_in__title mobile">Доставка!</div>
        <div className="prix_in__title desktop">Адрес доставки</div>
        <form className="prize_delivery__form">
          <div className="prize_delivery__input">
            <label>адрес:</label>
            <input type="text" placeholder="Город, улица, дом, квартира" />
          </div>
          <div className="prize_delivery__input">
            <label>телефон:</label>
            <input type="tel" placeholder="+7" />
          </div>
          <div className="prize_delivery__textarea">
            <label>комментарий для курьера:</label>
            <textarea value="Позвонить в домофон, подняться на 3 этаж." />
          </div>
          <Link to="/prize_thank" type="submit" className="prix_in__nav">
            получить
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Prize_delivery;
