import React from "react";
import "./Company.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Link } from "react-router-dom";
import { Validators } from "@UIKit";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import Autocomplete,  { createFilterOptions }  from "@mui/material/Autocomplete";
import SVG from "react-inlinesvg";
import star from "@images/svg/star_req.svg";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import InputMask from "react-input-mask";
import useDevice from "@hooks/useDevice";
import {BpCheckedIcon} from './constants';

const Company = ({ handleSubmit, handleFormData, prevStep, values, errorNumber, cookies }) => {
  const [device] = useDevice();
  const spec = useSelector((state) => state.points.filters);
  const cities = useSelector((state) => state.map.cities);
  const [pointCities, setPointCities] = React.useState([]);
  const toSuggestion = (filter) => ({ ...filter, key: filter.id, value: filter.title });

  React.useEffect(() => {
    setPointCities(sortBy(cities, ["title"]).map(toSuggestion));
  }, [cities]);

  const [error, setError] = React.useState(false);
  const submitFormData = (e) => {
    e.preventDefault();
    if (values.pointType == 0) {
      if (
        Validators.required(values.title) ||
        Validators.required(values.url) ||
        Validators.required(values.functions) ||
        Validators.required(values.city)
      ) {
        setError(true);
      } else {
        handleSubmit();
      }
    } else {
      if (
        Validators.required(values.title) ||
        Validators.required(values.address) ||
        Validators.required(values.functions) ||
        Validators.required(values.city)
      ) {
        setError(true);
      } else {
        handleSubmit();
      }
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option.title,
  });

  return (
    <div className="action">
      <div className="dots-action">
        <div className="dots-action__desc-container">
          <div className="dots-stage_2__desc desktop">Добавить организацию</div>
          <div className="dots__coins">+200 экокоинов</div>
        </div>

        <FormControl className="dots-stage_2__formControl marginZero">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            className="dots-radio-group"
            onChange={handleFormData("pointType")}
            defaultChecked={values.pointType}
            defaultValue={values.pointType}
          >
            <FormControlLabel
              className="dots__radio"
              value={0}
              control={<Radio checkedIcon={<BpCheckedIcon />} />}
              label="Онлайн"
            />
            <FormControlLabel
              className="dots__radio"
              value={1}
              control={<Radio checkedIcon={<BpCheckedIcon />} />}
              label="Оффлайн"
            />
          </RadioGroup>
        </FormControl>
        <Box
          className="dots-stage_3__box"
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div className="input-wrapper-field">
            <TextField
              className="dots__input"
              onChange={handleFormData("title")}
              defaultValue={values.title}
              id="standard-basic"
              label="Название компании"
              variant="standard"
            />
            {error && Validators.required(values.title)}
            <div className="icon-star">
              <SVG src={star}></SVG>
            </div>
          </div>

          {device === 'desktop' && (
            <div className="input-wrapper-field">
              <Autocomplete
                id="country-select-demo"
                sx={{ minWidth: "100%", border: "none" }}
                filterOptions={filterOptions}
                options={pointCities}
                onChange={handleFormData("city")}
                autoHighlight
                label="Город"
                getOptionLabel={(option) => values.city ? option.title : ''}
                renderOption={(props, option) => (
                  <Box component="li" value={option.id} sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.title}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Город"
                    defaultValue={values.city}
                    onChange={handleFormData("city")}
                    className="dots__input"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              {error && Validators.required(values.city)}
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
           {device !== 'desktop' && (
            <div className="input-wrapper-field">
              {" "}
              <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel className="select-label" id="country-select-demo">
                  Город
                </InputLabel>
                <Select
                  className="dots-select"
                  labelId="country-select-demo"
                  id="country-select-demo"
                  defaultValue={values.city}
                  onChange={handleFormData("city")}
                  label="Выбери Город"
                >
                  {pointCities.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && Validators.required(values.city)}
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
          {device === 'desktop' && (
            <div className="input-wrapper-field">
              <Autocomplete
                id="country-select-demo"
                sx={{ minWidth: "100%", border: "none" }}
                options={spec}
                onChange={handleFormData("functions")}
                filterOptions={filterOptions}
                autoHighlight
                label="Выбери специализацию"
                getOptionLabel={(option) => values.functions ? option.title : ''}
                renderOption={(props, option) => (
                  <Box component="li" value={option.id} sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.title}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Выбери специализацию"
                    defaultValue={values.functions}
                    className="dots__input"
                    onChange={handleFormData("functions")}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              {error && Validators.required(values.functions)}
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
           {device !== 'desktop' && (
            <div className="input-wrapper-field">
              <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel className="select-label" id="demo-simple-select-standard-label">
                  Выбери специализацию
                </InputLabel>
                <Select
                  className="dots-select"
                  labelId="demo-simple-select-standard-label"
                  id="country-select-demo"
                  defaultValue={values.functions}
                  onChange={handleFormData("functions")}
                  label="Выбери специализацию"
                >
                  {spec.map((item) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && Validators.required(values.functions)}
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
            </div>
          )}
          {values.pointType == 1 && (
            <div className="input-wrapper-field">
              <TextField
                className="dots__input"
                id="standard-basic"
                onChange={handleFormData("address")}
                defaultValue={values.address}
                label="Улица, дом, строение, корпус"
                variant="standard"
              />
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
              {error && Validators.required(values.address)}
            </div>
          )}

          {values.pointType == 0 && (
            <TextField
              className="dots__input"
              id="standard-basic"
              onChange={handleFormData("address")}
              defaultValue={values.address}
              label="Улица, дом, строение, корпус"
              variant="standard"
            />
          )}

          <div className="input-wrapper-field">
            <InputMask
              mask="+79999999999"
              value={values.phone}
              disabled={false}
              maskChar=" "
              id="standard-basic"
              onChange={handleFormData("phone")}
              defaultValue={values.phone}
              label="Номер телефона"
              variant="standard"
            >
              {() => <TextField label="Номер телефона" className="dots__input" />}
            </InputMask>
            <span className="ecocoin">+5 экокоинов</span>
          </div>
          {errorNumber && <div>Некорректный номер</div>}

          {values.pointType == 0 && (
            <div className="input-wrapper-field">
              <TextField
                className="dots__input"
                id="standard-basic"
                onChange={handleFormData("url")}
                defaultValue={values.url}
                label="Сайт"
                variant="standard"
              />
              <div className="icon-star">
                <SVG src={star}></SVG>
              </div>
              {error && Validators.required(values.url)}
            </div>
          )}

          {values.pointType == 1 && (
            <TextField
              className="dots__input"
              id="standard-basic"
              onChange={handleFormData("url")}
              defaultValue={values.url}
              label="Сайт"
              variant="standard"
            />
          )}

          <div className="dots-stage_2__desc social">Соц. сети</div>
          <div className="dots__social">
            <div className="input-wrapper-field wi50">
              <TextField
                className="dots__input social-input margin"
                onChange={handleFormData("socialMediaLink1")}
                defaultValue={values.socialMediaLink1}
                id="standard-basic"
                label="вконтакте"
                variant="standard"
              />
              <span className="ecocoin">+5 экокоинов</span>
            </div>
            <div className="input-wrapper-field wi50">
              <TextField
                className="dots__input social-input"
                onChange={handleFormData("socialMediaLink2")}
                defaultValue={values.socialMediaLink2}
                id="standard-basic"
                label="соц сеть на f"
                variant="standard"
              />
              <span className="ecocoin">+5 экокоинов</span>
            </div>
            <div className="input-wrapper-field wi50">
              <TextField
                className="dots__input social-input margin"
                onChange={handleFormData("socialMediaLink3")}
                defaultValue={values.socialMediaLink3}
                id="standard-basic"
                label="телеграм"
                variant="standard"
              />
              <span className="ecocoin">+5 экокоинов</span>
            </div>
            <div className="input-wrapper-field wi50">
              <TextField
                className="dots__input social-input"
                onChange={handleFormData("socialMediaLink4")}
                defaultValue={values.socialMediaLink4}
                id="standard-basic"
                label="соц сеть на i"
                variant="standard"
              />
              <span className="ecocoin">+5 экокоинов</span>
            </div>
          </div>
          <div className="input-wrapper-field rec">
            <div className="dots-stage_2__desc small">Добавить рекомендацию</div>
            <span className="ecocoin">+100 экокоинов</span>
          </div>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Напиши, какие услуги предоставляет организацию и что тебе нравится больше всего"
            style={{ width: "100%", padding: "10px", border: "1px solid #EBEBEB", borderRadius: "8px" }}
            defaultValue={values.description}
            onChange={handleFormData("description")}
          />
        </Box>
        <div className="dots__flex-container">
          <Link className="dots__button" onClick={submitFormData}>
            Продолжить
          </Link>
          <Link className="dots__button dots__button--transparent" onClick={prevStep}>
            Назад
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Company;
