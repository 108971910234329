import { push, replace } from "connected-react-router";
import React, { PureComponent } from "react";
import clsx from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _isEqual from "lodash.isequal";
import baseDebug from "debug";
import OutsideClickHandler from "react-outside-click-handler";
import { detectMe } from "../redux/actions/map";
import { setAppState } from "../redux/actions/appState";
import searchIcon from "@images/svg/search-black.svg";
import SVG from "react-inlinesvg";
import { setMap, fetchCities, fetchTiles, checkTile } from "../redux/actions/map";
import { setNewPoint } from "../redux/actions/newPoint";
import SimpleSlider from "../../src/components/SliderSlick/SliderSlick";
import {
  setPoints,
  fetchPoints,
  searchPoints,
  setSearchResult,
  loadOnlinePoints,
  getPointFromUrl,
  loadData,
  fetchServiceFunctions,
  applySelectionByQuery,
  handleCategorySelection,
  unselectCategory,
} from "../redux/actions/points";
import { setIsOpenProductsModal } from "../redux/actions/modalProducts";
import { getFetchData } from "../redux/actions/feature";
import { fetchTasks } from "../redux/actions/loyalty";
import { setModalPin } from "../redux/actions/modalPin";
import { fetchBlog } from "../redux/actions/blog";
import img from "../img/review-example.png";
import NewMap from "../components/NewMap";
import MetaPage from "./MetaPage";
import { AppContext } from "../contexts/AppContext";
import { commonService } from "../services/commonService";
import PointHighlighter from "./PointHighlighter";
import GeoLocationControl from "./GeoLocationControl";
import BusinessPromotion from "./BusinessPromotions/BusinessPromotion";
import { UIAppBar } from "@UIKit";
import { UINotification } from "@UIKit";
import { UIFooter } from "@UIKit";
const debug = baseDebug("gorod:App");

@connect(
  ({
    appState,
    map,
    isOpen,
    feature,
    modalProducts,
    points,
    modalPin,
    newPoint,
    about,
    search,
    onlinePoint,
    loyalty,
    miscellaneous,
  }) => ({
    ...appState,
    ...map,
    ...points,
    ...modalPin,
    ...newPoint,
    ...about,
    ...search,
    ...onlinePoint,
    ...loyalty,
    modalProducts,
    feature,
    isOpen,
    notification: miscellaneous.notification,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getFetchData,
        fetchTasks,
        setAppState,
        setMap,
        setIsOpenProductsModal,
        fetchCities,
        fetchTiles,
        setModalPin,
        setNewPoint,
        fetchBlog,
        setSearchResult,
        setPoints,
        fetchPoints,
        searchPoints,
        checkTile,
        loadOnlinePoints,
        getPointFromUrl,
        loadData,
        goTo: push,
        replace: replace,
        goToPoint: (id, citySlug) => push(`/points/${id}?city=${citySlug}`),
        goToCity: (url, citySlug) => replace(`${url}?city=${citySlug}`),
        fetchServiceFunctions,
        applySelectionByQuery,
        handleCategorySelection,
        unselectCategory,
        detectMe,
      },
      dispatch
    )
)
class Main extends PureComponent {
  state = {
    isServicesOn: true,
  };

  componentDidMount = async () => {
    if (typeof window !== "object") {
      return;
    }
    await this.props.fetchServiceFunctions();

    const hasSearchQuery = commonService.hasSearchQuery(this.props.location.search);

    if (hasSearchQuery && !this.props.searchResult.length) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const searchText = queryParams.get("search");
      const currentCenter = [parseFloat(queryParams.get("lat")), parseFloat(queryParams.get("long"))];
      this.props.setMap({ currentCenter });
      this.props.setSearchResult({ searchTextToDisplay: searchText });
      this.handleSearch(searchText, currentCenter, true);
    }
    // this.handleCityQueryParams();
  };

  handleCityQueryParams() {
    const { slug, location, history } = this.props;

    const searchParams = new URLSearchParams(location.search);
    const hasCity = !!searchParams.get("city");

    if (slug && !hasCity) {
      history.push({
        pathname: location.pathname,
        search: `?city=${slug}`,
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (!_isEqual(this.props.bounds, prevProps.bounds)) {
      debug("bounds change %o -> %o", prevProps.bounds, this.props.bounds);
      if (this.props.staticTile && this.props.bounds.length > 0) {
        this.props.checkTile(this.props.bounds);
      }
    }

    if (
      (!prevProps.subcategories.length && this.props.subcategories.length) ||
      (!prevProps.categories.length && this.props.categories.length)
    ) {
      this.props.applySelectionByQuery(this.props.location.search);
      this.props.handleCategorySelection();
    }
  };

  updateWindowDimensions = () => {
    this.props.setAppState({ wWidth: window.innerWidth, wHeight: window.innerHeight });
  };

  changeZoom = (z) => {
    const { zoom } = this.props;
    const newZoom = zoom + z * 1;
    if (newZoom > 1 && newZoom <= 16) {
      this.props.setMap({ zoom: newZoom });
    }
  };

  getImg = (func, point) => {
    const { filters } = this.props;
    const funcCur = filters.find((filt) => filt.id === func);
    if (funcCur && funcCur.markerActive && this.props.pin && this.props.pin.id === point.properties.id) {
      return funcCur.markerActive;
    }
    if (funcCur) {
      return funcCur.marker;
    }
    return img;
  };

  setREf = (ref) => {
    if (ref && !this.mapRef) {
      this.mapRef = ref;
      const bounds = ref.getBounds();
      this.props.setMap({ bounds, staticBounds: bounds });
    }
  };

  setSearchControlRef = () => {
    const bounds = this.mapRef.getBounds();
    // const zoom = this.mapRef.getZoom();
    if (this.props.staticTile) {
      this.props.checkTile(bounds);
    }
  };

  changeSearch = ({ target }) => {
    this.props.setSearchResult({ searchText: target.value });
  };

  startSearchPoint = (value) => {
    this.props.setSearchResult({ searchText: value });
    const { searchText, searchPoints, currentCenter } = this.props;
    searchPoints(searchText, currentCenter[0], currentCenter[1]);
  };

  onEnter = (event) => {
    const { searchText, currentCenter, setSearchResult } = this.props;

    if (event.key === "Enter") {
      setSearchResult({ searchTextToDisplay: searchText });
      this.handleSearch(searchText, currentCenter, true);
    }
  };

  getSearchResults = () => {
    const { searchText, currentCenter, setSearchResult } = this.props;
    setSearchResult({ searchTextToDisplay: searchText });
    this.handleSearch(searchText, currentCenter, true);
  };

  handleSearch(searchText, [latitude, longitude], composeQuery) {
    let searchQuery = null;
    const {
      history,
      location: { search },
    } = this.props;

    if (composeQuery) {
      const composedQuery = commonService.composeSearchQuery(searchText, search, [latitude, longitude]);
      searchQuery = `/search/?${composedQuery}`;
      history.push(searchQuery);
      this.props.setSearchResult({
        searchQuery,
        searchText,
      });
    }
    this.props.setPoints({ selectedPointIds: [] });
    this.props.setAppState({
      filterIsOpen: false,
      isSearching: true,
    });
    this.props.searchPoints(searchText, latitude, longitude);
  }

  openCLoseOS = () => {
    this.props.setPoints({ onlineServiceOpen: !this.props.onlineServiceOpen });
    this.props.loadOnlinePoints();
  };

  closeOnlineService = () => {
    const { setPoints } = this.props;
    setPoints({ onlineServiceOpen: false });
  };

  closeAllModal = () => {
    const tmpArr = [...this.props.categories];
    tmpArr.forEach((cater) => (cater.active = false));
    this.props.setPoints({ categories: tmpArr, onlineServiceOpen: false });
  };

  filterPoint = (func) => {
    const { categories, filters } = this.props;
    const idFilters = filters.filter((filte) => filte.categories.find((cat) => cat.id === categories[0].id));
    let p = undefined;
    idFilters.forEach((f) => {
      if (f.id === func) {
        p = f;
      }
    });
    return p;
  };

  checkPoint = (point) => {
    return point.state === "active" && point.activeDateTo
      ? moment().isBefore(point.activeDateTo)
      : point.activeDateFrom
      ? moment().isAfter(point.activeDateFrom)
      : true;
  };

  openNewPointModal = () => {
    const { setNewPoint } = this.props;
    setNewPoint({ showModalNewPin: true });
    this.closeAllModal();
  };

  handleServiceClick = (pointId) => () => {
    const { goToPoint, fullCity } = this.props;
    goToPoint(pointId, fullCity.slug);
  };

  _onClickServiceOn = () => {
    this.setState({ isServicesOn: true });
  };

  _onClickServiceOff = () => {
    this.setState({ isServicesOn: false });
  };

  isMapOnlyVisible() {
    const { showPinModal, showModalNewPin, showModalAbout } = this.props;

    return !showModalAbout && !showModalNewPin && !showPinModal;
  }

  isModalAboutVisible() {
    return this.props.showModalAbout;
  }

  isNavbarHidden() {
    return this.props.showModalAbout;
  }

  isOnlineServiceHidden() {
    const { showPinModal, showModalNewPin } = this.props;

    return showPinModal || showModalNewPin;
  }

  renderGeoLocationControl() {
    const { noPointsInCity, detectMe, me } = this.props;

    return (
      <AppContext.Consumer>
        {(context) => <GeoLocationControl {...context} detectMe={detectMe} me={me} noPointsInCity={noPointsInCity} />}
      </AppContext.Consumer>
    );
  }

  renderControlButtons() {
    const { zoom } = this.props;
    return (
      <div className={"controls-map"}>
        <div className="controls">
          <div
            className={"controls__item controls__item--plus" + (zoom < 18 ? " controls__item--active" : "")}
            onClick={() => this.changeZoom(1)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 13.81 13.81">
              <title>Asset 5</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M8.08,13.81H5.73V8.08H0V5.73H5.73V0H8.08V5.73h5.73V8.08H8.08Z" />
                </g>
              </g>
            </svg>
          </div>
          <div
            className={"controls__item controls__item--minus" + (zoom > 1 ? " controls__item--active" : "")}
            onClick={() => this.changeZoom(-1)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width={11} height={3} viewBox="0 0 11.06 2.35">
              <title>Asset 7</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <path d="M11.06,0V2.35H0V0Z" />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  }

  renderBusinessPromotion = () => {
    return (
      <div
        className={clsx(
          "business-promotion-container",
          this.props.isMobileSearchMenuOpened && "business-promotion-container--mobile-search"
        )}
      >
        <BusinessPromotion {...this.props} />
      </div>
    );
  };

  renderSearchForm() {
    const { searchText } = this.props;

    const isNoSearch = this.props.location.search.includes("=no-search");

    return (
      <div className={isNoSearch ? "search hidden" : "search"}>
        <div className="search__form">
          <input
            onChange={this.changeSearch}
            onKeyPress={this.onEnter}
            onClick={this.closeAllModal}
            value={searchText}
            className="search__field"
            type="text"
            placeholder="Поиск..."
            spellCheck="false"
          />
          <button className="search__icon" onClick={this.getSearchResults}>
            <SVG src={searchIcon} />
          </button>
        </div>
      </div>
    );
  }

  renderNavbar() {
    return <UIAppBar {...this.props} props={this.props} />;
  }

  renderSomeServiceList() {
    const { cityToOnlinePointsMap, city } = this.props;

    return (
      <div className="services-block services-block--online">
        <div className="services-block__info">
          <div className="services-block__list">
            <Scrollbars
              autoHeight
              autoHeightMax={420}
              renderTrackVertical={(props) => <ul {...props} className="track-vertical-custom" />}
            >
              {cityToOnlinePointsMap[city].map((point) => (
                <li className={"services-block__list-item"} key={point.id} onClick={this.handleServiceClick(point.id)}>
                  <div className={"services-block__list-item-div"}>
                    <div className="services-block__list-item-image">
                      <img src={point.logo || this.getImg(point.functions[0].id, { properties: { id: point } })} />
                    </div>
                    {point.title}
                  </div>
                </li>
              ))}
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }

  // renderCategory(category, len) {
  //   const { location, goTo, unselectCategory, screen } = this.props;
  //   const handleClick = () => {
  //     if (category.externalUrl) {
  //       const isAbsolute = commonService.isAbsoluteUrl(category.externalUrl);
  //       return isAbsolute ?
  //         window.open(category.externalUrl, '_blank') :
  //         goTo(category.externalUrl);
  //     }

  //     return goTo(`/filter/${category.slug}${location.search}`);
  //   }

  //   const handleUnselect = (e) => {
  //     const { location } = this.props;
  //     e.stopPropagation();
  //     unselectCategory();
  //     goTo(`?${commonService.clearFilterQuery(location)}`)
  //   }

  //   const clearIconClassName = classNames({
  //     'services-block__clearIconWrapper': true,
  //     'services-block__clearIconWrapper--mobile': commonService.isMobileDevice()
  //   });

  //   return (
  //     <div
  //       key={category.id}
  //       className="services-block__button"
  //       style={{width: screen.isMobile ? `${100 / len}%` : '100%'}}
  //     >
  //       { category.isActive && (
  //         <div className={clearIconClassName}>
  //           <span className="services-block__clearIcon" onClick={handleUnselect}></span>
  //         </div>
  //       )}
  //       <img src={category.image} alt="review" onClick={handleClick} />
  //       <div className="services-block__title" style={{color: category.color}}>
  //         {category.title}
  //       </div>
  //     </div>
  //   );
  // }

  featureBlock = () => {
    return (
      <SimpleSlider
        dataSlider={this.props.feature}
        name="feature"
        goTo={this.props.goTo}
        location={this.props.location}
        cities={this.props.cities}
        filters={this.props.filters}
        allTasks={this.props.allTasks}
      ></SimpleSlider>
    );
  };

  renderOnlineServices() {
    const { onlineServiceOpen, hasOnlinePoints, cityToOnlinePointsMap, city } = this.props;

    const { isServicesOn } = this.state;
    // const isCategoriesVisible = isServicesOn && !this.isOnlineServiceHidden() && !modalSearchResult && !filterIsOpen && !commonService.locationSearch.includes('=no-categories');

    return (
      <OutsideClickHandler disabled={!onlineServiceOpen} onOutsideClick={this.closeOnlineService}>
        {this.isMapOnlyVisible() &&
          hasOnlinePoints &&
          onlineServiceOpen &&
          cityToOnlinePointsMap[city] &&
          this.renderSomeServiceList()}
        {!isServicesOn && (
          <div className="services-button-block">
            <button className="services-button-block__button" onClick={this._onClickServiceOn} />
          </div>
        )}
        {/* {isCategoriesVisible && (
          <div className="services-block-wrapper">
            <div className="services-block-wrapper__close-mobile" onClick={this._onClickServiceOff} />
            <div className="services-block-container">
              {categories.map((category) => {
                return this.renderCategory(category, categories.length)
              })}
            </div>
            <div className="services-block-wrapper-close" onClick={this._onClickServiceOff} />
          </div>
        )} */}
      </OutsideClickHandler>
    );
  }

  render() {
    const {
      hostName,
      urls,
      selectedPointIds,
      filters: serviceFunctions,
      pointObjects,
      pins,
      notification,
      modalProducts,
      feature,
      location,
    } = this.props;

    const isNoList = this.props.location.search.includes("=no-list");
    const isNoFooter = this.props.location.search.includes("=no-footer") || this.props.location.pathname.includes("new_vokrug_kazan");

    return (
      <>
        <MetaPage
          className={"Main" + (modalProducts.isOpen || modalProducts.isOpenMenu ? " hidden" : "") + (isNoList  ? ' noList' : '') + (location.pathname.startsWith("/map") ? ' map' : '') + (location.pathname.startsWith("/categories") ? ' categories' : '') + (location.pathname.startsWith("/loyalty/tasks") ? ' tasks' : '') + (location.pathname.startsWith("/loyalty/prizes") ? ' prizes' : '')}
          hostName={hostName}
          urls={urls}
        >
          {(location.pathname === "/" || location.pathname.startsWith("/search/") || location.pathname.startsWith("/filter/") || location.pathname.startsWith("/points/") || location.pathname.startsWith("/map") || location.pathname.startsWith("/categories")) && this.renderControlButtons()}
          {(location.pathname === "/" || location.pathname.startsWith("/search/") || location.pathname.startsWith("/filter/") || location.pathname.startsWith("/points/") || location.pathname.startsWith("/map") || location.pathname.startsWith("/categories")) && this.renderGeoLocationControl()}
          {!this.isNavbarHidden() && this.renderNavbar()}
          {!this.isOnlineServiceHidden() && this.renderOnlineServices()}
          <UINotification notification={notification}></UINotification>
          {(location.pathname === "/" || location.pathname.startsWith("/search/") || location.pathname.startsWith("/filter/") || location.pathname.startsWith("/points/") || location.pathname.startsWith("/map") || location.pathname.startsWith("/categories")) && (
            <AppContext.Consumer>
              {(context) => {
                return (
                  <>
                    <NewMap {...context}/>
                    <PointHighlighter
                      {...context}
                      selectedPointIds={selectedPointIds}
                      serviceFunctions={serviceFunctions}
                      pointObjects={pointObjects}
                      pins={pins}
                    />
                    {location.pathname === "/" && this.featureBlock()}
                  </>
                );
              }}
            </AppContext.Consumer>
          )}
           {!isNoList && (location.pathname === "/" || location.pathname.startsWith("/search/") || location.pathname.startsWith("/filter/") || location.pathname.startsWith("/points/") || location.pathname.startsWith("/map") || location.pathname.startsWith("/categories") || location.pathname.startsWith("/loyalty/prizes")) && (
            <Scrollbars className="scrollBar" autoHeight autoHeightMax={1600} style={{ width: "390px" }}>
              {!this.isModalAboutVisible() && this.renderBusinessPromotion()}
            </Scrollbars>
          )}
          {this.props.routes}
          { !isNoFooter && <UIFooter />}
        </MetaPage>
      </>
    );
  }
}

export default Main;
