import React from 'react';
import { identity } from 'lodash';
import { commonService } from '@services/commonService';
import { UIInput } from '@UIKit';

export const AdaptiveDate = ({ onClick = identity, ...props}) => {
  const [ isMobile ] = React.useState(() => commonService.getMobileOperatingSystem(), []);
  const handleClick = React.useCallback(() => {
    isMobile && commonService.scrollIt(155, 300, 'easeOutQuad')
  }, [isMobile]);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.defaultValue = '';
    }
  }, [inputRef.current])

  const handleChange = (evt) => {
    props.input.onChange(evt);

    const target = evt.target;
    setTimeout(()=>{
      target.defaultValue = "";
    }, 100);
  }

  return (
    <>
      { isMobile
        ? <UIInput type="date" { ...props } onChange={handleChange} onClick={handleClick} />
        : <UIInput type="text" { ...props } onClick={handleClick} />
      }
    </>
  );
}
