import { SET_APP_STATE } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

const initialState = {
  isLoaded: false,
  pointsLoaded: true,
  filterIsOpen: false,
  isSearching: false,
  filterSlug: null,
  wasCitySelected: true,
  config: [],
  isAnnoucementVisible: true,
  shouldShowAnnouncement: false,
  isMobileSearchMenuOpened: false,
  mapChangedBounds: false
};

export default makeReducer(initialState, SET_APP_STATE);
