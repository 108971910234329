import React from 'react'
import clsx from 'clsx'
import SVG from 'react-inlinesvg'
import { UIDialog, UIRoundedButton } from '@UIKit'
import loyaltyIcon from '@images/design/loyalty.svg'
import { commonService } from '@services/commonService'
import { pluralizePoints } from '@UIKit/utils/pluralize'
import './PrizeDialog.scss'

function PrizeDialog({
  data,
  onClose,
  onSubmit,
  onRedirect,
  isOpen,
  isUserPrizes,
}) {
  const handleSubmit = React.useCallback(
    () => {
      onSubmit(data);
      commonService.sendEvent({
        category: 'task_send',
        action: `User is going to buy the prize ${data.id}`
      });
    },
    [data, onSubmit]
  );

  const handleClose = React.useCallback(() => {
    onClose()
  }, [])

  const isMobile = commonService.isMobileSize();

  if (!data) {
    return null;
  }

  return (
    <UIDialog
      className={ clsx('PrizeDialog', isUserPrizes && 'UserPrizeDialog') }
      onClose={ handleClose }
      isOpen={ isOpen }
      onBackdropClick={ handleClose }
      blurred
    >
      <div className="PrizeDialog__Content">
        <div className="PrizeDialog__Image">
          <img src={ data.image } alt="" />
        </div>

        <div className="PrizeDialog__Description">
          <p className="PrizeDialog__Title">
            { data.title }
          </p>

          { isUserPrizes
            ? <div className="PrizeDialog__Text">
                { data.code }
              </div>
            : <div
                className="PrizeDialog__Text"
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
          }
        </div>
      </div>

      { !isUserPrizes &&
        <>
          { data.isCta
            ? <>
                <UIRoundedButton
                  className="PrizeDialog__Button"
                  onClick={ onRedirect }
                  color="king"
                >
                  Перейти к заданиям
                </UIRoundedButton>
                <UIRoundedButton
                  className="PrizeDialog__Button"
                  onClick={ handleClose }
                  size="large"
                >
                  { isMobile
                    ? 'Партнёрские призы'
                    : 'Вернуться к партнёрским призам'
                  }
                </UIRoundedButton>
              </>
            : <UIRoundedButton
                className="PrizeDialog__Button"
                onClick={ handleSubmit }
                color="king"
              >
                Приобрести за <SVG src={ loyaltyIcon } /> { data.points } { !isMobile && pluralizePoints(data.points) }
              </UIRoundedButton>
          }
        </>
      }
    </UIDialog>
  )
}

export default React.memo(PrizeDialog)
