import React from 'react';
import useWindowSize from '@hooks/useWindowSize';

export const DEVICE = {
  PHONE: 'phone',
  TABLET: 'tablet',
  DESKTOP: 'desktop'
}

export const DEVICE_LIST = {
  [DEVICE.PHONE]: [320, 767],
  [DEVICE.TABLET]: [768, 1023],
  [DEVICE.DESKTOP]: [1024]
}

export const DEVICES = Object.keys(DEVICE_LIST);

function getDevice(currentWidth) {
  return DEVICES
    .find(device => {
      const [ leftSide, rightSide ] = DEVICE_LIST[device];
      return leftSide && rightSide
        ? leftSide <= currentWidth && currentWidth < rightSide
        : leftSide <= currentWidth;
    });
}

function useDevice() {
  const [ windowSize ] = useWindowSize();
  const { width } = windowSize;

  const [device, setDevice] = React.useState(getDevice(width));

  React.useEffect(() => {
    setDevice(getDevice(width));
  }, [width]);

  return [ device ];
}

export default useDevice;
