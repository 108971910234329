import React from 'react';
import { UIDialog } from '@UIKit';
import { withRouter } from 'react-router-dom';
import searchCityIcon from '@images/design/search-city.svg';
import SVG from 'react-inlinesvg';
import './CityDialog.scss';
import { connect } from 'react-redux';
import { UIAdaptiveAutocomplete } from '@UIKit';
import { bindActionCreators } from 'redux';
import { setMap, goToCity } from '@redux/actions/map';
import { loadOnlinePoints, setPoints } from '@redux/actions/points';
import { setAppState } from '@redux/actions/appState';
import { sortBy } from 'lodash';
import { UIRoundedButton } from '@UIKit';
import { commonService } from '@services/commonService';

const toSuggestion = city => ({ ...city, key: city.id, value: city.title });
const CityDialog = React.memo(({
  isOpen,
  onClose,
  cities,
  selectedCity: previouslySelectedCity,
  location,
  setMap,
  loadOnlinePoints,
  setAppState,
  goToCity,
  setPoints,
  renderIndex,
  map
}) => {
  const [ suggestions, setSuggestions ] = React.useState([]);
  const [ selectedCity, setSelectedCity ] = React.useState(null);
  const [ isMobile ] = React.useState(() => commonService.getMobileOperatingSystem(), []);
  const [ label ] = React.useState(
    isMobile ? 'Ваш город' : 'Введите название города'
  )

  React.useEffect(() => {
    setSuggestions(sortBy(cities, ['title']).map(toSuggestion));
  }, [cities]);

  const handleChange = selectedCity => {
    setSelectedCity(selectedCity);
  }
  const chooseCity = () => {
    setMap({
      city: selectedCity.title,
      center: [selectedCity.latitude, selectedCity.longitude],
      currentCenter: [selectedCity.latitude, selectedCity.longitude],
      zoom: 12,
      slug: selectedCity.slug
    });
    loadOnlinePoints();
    setAppState({ wasCitySelected: true });
    goToCity(location.pathname, selectedCity.slug);
    setPoints({ noPointsInCity: false });
    onClose();
    commonService.clearTileTree(map);
    // set button bg color grey only when dialog is closed
    setTimeout(() => setSelectedCity(null));
  }

  const selectedCityId = (selectedCity && selectedCity.id) ||
    (previouslySelectedCity && previouslySelectedCity.id);

  return (
    <UIDialog
      isOpen={isOpen}
      onClose={onClose}
      className="CityDialog"
      blurred={true}
      renderIndex={renderIndex}
      onBackdropClick={onClose}
    >
      <div className="CityDialog__Content">
        <h1 className="CityDialog__Title">Выберите город</h1>
        <p className="CityDialog__Description">
          Выберите город в котором вы живете, чтобы мы подобрали актуальные для вас места и задания
        </p>
        <div className="CityDialog__Image"><SVG src={searchCityIcon}/></div>
        <UIAdaptiveAutocomplete
          type="autocomplete"
          className="CityDialog__Autocomplete"
          label={label}
          selectedKey={selectedCityId}
          suggestions={suggestions}
          selectedCity={selectedCity}
          handleChange={handleChange}
          listWidth={215}
          renderIndex={renderIndex}
        />
      </div>
      <UIRoundedButton
        className="CityDialog__Button"
        color="king"
        disabled={!selectedCity}
        onClick={chooseCity}
      >
        Выбрать
      </UIRoundedButton>
    </UIDialog>
  )
});

const mapStateToProps = ({ map: { cities } }) => ({ cities });
const mapStateToDispatch = dispatch => bindActionCreators({
  setMap,
  loadOnlinePoints,
  setAppState,
  goToCity,
  setPoints
}, dispatch);
export default withRouter(connect(mapStateToProps, mapStateToDispatch)(CityDialog));
