import React from 'react';
import { SocialButton } from './SocialButton';
import { connect } from 'react-redux';
import { commonService } from '@services/commonService';

const VKButton = ({ children, appId, readOnly, redirectURI, provider, slug, onClick, onLoginSuccess }) => {
  const handleLogin = () => {
    if (typeof window === 'undefined' || readOnly) {
      return;
    }
    const redirectUrl = [
      'https://oauth.vk.com/authorize?',
      `client_id=${appId}&`,
      `redirect_uri=${redirectURI}&`,
      `scope=email,offline,notifications&`,
      'response_type=token&',
      `state=${provider},${slug}&`,
      'v=5.110'
    ].join('');

    window.handleAccessToken = (accessToken) => {
      onLoginSuccess({ provider, token: { accessToken } });
    }
    commonService.popupCenter({url: redirectUrl, title: 'VK', w: 700, h: 550 });
  };

  return (
    <SocialButton
      triggerLogin={handleLogin}
      className="SocialButton--vk"
      readOnly={readOnly}
      onClick={onClick}
    >
      { children }
    </SocialButton>
  )
}
const mapStateToProps = ({ map: { slug } }) => ({ slug })
export default connect(mapStateToProps)(VKButton)
