import React from 'react';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import logo from '../../img/og.png';
import { APP_URL } from "../../constants/config";
import { unescape } from 'lodash';
import stripHtml from "string-strip-html";

const defaultTitle = '+1Город (Полезный Город) — онлайн-карта и наружная навигация по полезным городским сервисам';
const defaultDescription =
  'Найдите адреса ближайших точек, где можно сдать раздельно мусор, батарейки или градусники на переработку, отдать ненужную одежду или помочь продуктами, где стать донором или волонтером';
const defaultSep = ' | ';

const decode = str => stripHtml(unescape(str)).result;

export const getMetaTags = (
  {
    title,
    description,
    image,
    contentType,
    noCrawl,
    published,
    updated,
    category,
    tags,
    keywords
  },
  pathname,
  getParameters,
  urls,
  hostName
) => {
  const SITE_URL = APP_URL;
  const defaultImage = `${SITE_URL}${logo}`;
  const theTitle = title
    ? (title + defaultSep + defaultTitle)
    : defaultTitle;
  const theDescription = description
    ? description
    : defaultDescription;

  const theImage = image ? `${SITE_URL}${image}` : defaultImage;

  let url_tags = undefined
  if (urls) {
    let url = pathname+getParameters
    urls.forEach(u => {
      if (url === '/' + u.url) {
        url_tags = u
      }
    })
  }

  const metaTags = [
    { itemprop: 'name', content: decode(url_tags ? url_tags.metaName : theTitle) },
    { itemprop: 'description', content:  decode(url_tags ? url_tags.metaDescription : theDescription) },
    { itemprop: 'image', content:  decode(url_tags ? url_tags.metaImage : theImage) },
    { name: 'description', content:  decode(url_tags ? url_tags.metaDescription : theDescription) },
    { property: 'og:title', content:  decode(url_tags ? url_tags.metaOgTitle : theTitle) },
    { property: 'og:type', content:  decode(url_tags ? url_tags.metaOgType : contentType || 'website') },
    { property: 'og:url', content:  decode(url_tags ? url_tags.metaOgUrl : SITE_URL + pathname) },
    { property: 'og:image', content:  decode(url_tags ? url_tags.metaOgImage : theImage) },
    { property: 'og:description', content:  decode(url_tags ? url_tags.metaOgDescription : theDescription) },
    { property: 'og:site_name', content:  decode(url_tags ? url_tags.metaOgSiteName : defaultTitle) }
  ];

  if (keywords) {
    metaTags.push({ name: 'keywords', content: decode(keywords) });
  }

  if (noCrawl) {
    metaTags.push({ name: 'robots', content: 'noindex, nofollow' });
  }

  if (published) {
    metaTags.push({ name: 'article:published_time', content: published });
  }
  if (updated) {
    metaTags.push({ name: 'article:modified_time', content: updated });
  }
  if (category) {
    metaTags.push({ name: 'article:section', content: category });
  }
  if (tags) {
    metaTags.push({ name: 'article:tag', content: tags });
  }
  return metaTags;
};

const MetaPage = ({ children, id, className, style, location, hostName, urls, canonicalSearch, ...rest }) => {
  const SITE_URL = APP_URL;
  return (
    <div className={className} style={style}>
      <Helmet
        htmlAttributes={{
          lang: 'ru',
          itemscope: undefined,
          itemtype: `http://schema.org/${rest.schema || 'WebPage'}`
        }}
        title={
          rest.title ? rest.title + defaultSep + defaultTitle : defaultTitle
        }
        link={[
          {
            rel: 'canonical',
            href: SITE_URL + location.pathname + canonicalSearch
          }
        ]}
        meta={getMetaTags(rest, location.pathname, location.search, urls, hostName)}
      />
      {children}
    </div>
  );
};

export default withRouter(MetaPage);
