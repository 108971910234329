import React from "react";
import "./Ads.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";

import mac from "@images/mac.jpg";
import phone from "@images/iPhone-13.jpg";
import macDesk from "@images/mac-desk.jpg";
import phoneDesk from "@images/iPhone-13-desk.jpg";

const Ads = () => {
  return (
    <div className="ads">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/ask">Коммерческое и некоммерческое сотрудничество</Link>
        </div>
        <h1 className="about-sub__title">Коммерческое и некоммерческое сотрудничество</h1>
        <div className="ads__block">
          <div className="ads__wrapper">
            <div className="ads__title">Решения для микробизнеса</div>
            <div className="ads__description">
              Оптимизируйте затраты на расширение аудитории и повысьте эффективность рекламы. Используйте цифровые
              инструменты +1Город для расширения воронки и привлечения новых покупателей. Разместите на нашей площадке
              свои товары/услуги и получите новые каналы продаж.
            </div>
            <div className="ads__links">
              <a rel="noreferrer" target="_blank" href="https://market.plus-one.ru/micro-business">
                Подробнее<SVG src={arrowLeft}></SVG>
              </a>
              <a rel="noreferrer" target="_blank" href="https://gorod.plus-one.ru/media/django-summernote/2022-09-29/7dd4448e-24ec-4c98-ab7b-43b07727cfbb.pdf">
                Скачать презентацию<SVG src={arrowLeft}></SVG>
              </a>
            </div>
          </div>
        </div>
        <div className="ads__block">
          <div className="ads__wrapper">
            <div className="ads__title">Решения для малого и среднего бизнеса</div>
            <div className="ads__description">
              Привлеките не просто новых клиентов, а тех, кто останется с вами надолго. Расширьте географию рекламы и
              повысьте узнаваемость своего бренда. Используйте новые каналы продаж на нашей площадке, а также
              подключайте потенциальных покупателей к собственной программе лояльности.
            </div>
            <div className="ads__wrapper-img">
              <img src={phone} />
            </div>
            <div className="ads__m0 ads__links">
              <a rel="noreferrer" target="_blank" href="https://market.plus-one.ru/middle-business">
                Подробнее<SVG src={arrowLeft}></SVG>
              </a>
              <a rel="noreferrer" target="_blank" href="https://gorod.plus-one.ru/media/django-summernote/2022-09-29/7dd4448e-24ec-4c98-ab7b-43b07727cfbb.pdf">
                Скачать презентацию<SVG src={arrowLeft}></SVG>
              </a>
            </div>
            <p className="ads__cooperation">По вопросам сотрудничества <a href="mailto:info@plus-one.ru">info@plus-one.ru</a></p>
          </div>
          <div className="ads__wrapper-img-desk">
              <img src={phoneDesk}/>
            </div>
        </div>
        <div className="ads__block">
          <div className="ads__wrapper">
            <div className="ads__title">Решения для крупного бизнеса</div>
            <div className="ads__description">
              Повысьте узнаваемость компании в медиаполе в качестве представителя ответственного бизнеса, привлеките
              новую лояльную аудиторию, а также увеличьте уровень вовлеченности клиентов в социально значимые устойчивые
              практики. Все механики разрабатываются индивидуально с учетом специфики для вашего бизнеса, стратегии
              компании, целевой аудитории и KPI.
            </div>
            <div className="ads__m0 ads__links">
              <a rel="noreferrer" target="_blank" href="https://market.plus-one.ru/big-business">
                Подробнее<SVG src={arrowLeft}></SVG>
              </a>
              <a rel="noreferrer" target="_blank" href="https://gorod.plus-one.ru/media/django-summernote/2022-09-29/7dd4448e-24ec-4c98-ab7b-43b07727cfbb.pdf">
                Скачать презентацию<SVG src={arrowLeft}></SVG>
              </a>
            </div>
            <p className="ads__cooperation">По вопросам сотрудничества <a href="mailto:info@plus-one.ru">info@plus-one.ru</a></p>
          </div>
        </div>
        <div className="ads__block">
          <div className="ads__wrapper">
            <div className="ads__title">Решения для государственных структур</div>
            <div className="ads__description">
              Интегрируйте карту +1Город с полезными сервисами в вашем городе на сайты региональных и муниципальных
              органов власти. Используйте возможности для реализации программ поддержки регионального бизнеса.
            </div>
            <div className="ads__wrapper-img">
              <img src={mac} />
            </div>
            <div className="ads__m0 ads__links">
              <a rel="noreferrer" target="_blank" href="https://market.plus-one.ru/govemenmt-packages">
                Подробнее<SVG src={arrowLeft}></SVG>
              </a>
              <a rel="noreferrer" target="_blank" href="https://gorod.plus-one.ru/media/django-summernote/2022-09-07/40d353d3-2f79-43e8-bb05-7869c53fb5ad.pdf">
                Скачать презентацию<SVG src={arrowLeft}></SVG>
              </a>
            </div>
            <p className="ads__cooperation">По вопросам сотрудничества <a href="mailto:info@plus-one.ru">info@plus-one.ru</a></p>
          </div>
          <div className="ads__wrapper-img-desk">
              <img src={macDesk}/>
            </div>
        </div>
        <div className="ads__block">
          <div className="ads__wrapper">
            <div className="ads__title">Решения для НКО и волонтеров</div>
            <div className="ads__description">
              Добавьте вашу организацию на карту +1Город, чтобы о вас узнало больше людей. Поощряйте волонтеров
              совершать добрые поступки, размещая задания в программе лояльности. Собирайте пожертвования с помощью
              простого и удобного интерфейса.
            </div>
            <p className="ads__links">
              <a rel="noreferrer" target="_blank" href="https://gorod.plus-one.ru/media/django-summernote/2022-09-07/a2a3c03b-85b3-4c45-9afe-874051a9e082.pdf">
                Скачать презентацию<SVG src={arrowLeft}></SVG>
              </a>
            </p>
            <p className="ads__cooperation">По вопросам сотрудничества <a href="mailto:info@plus-one.ru">info@plus-one.ru</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads;
