import React from 'react'
import { UIRoundedButton } from '@UIKit'


function TaskConfirm({
  onClose,
  onCancel,
}) {
  return (
    <div className="TaskDialog__Content TaskDialog__Confirm">
      <h2 className="TaskDialog__Title">
        Отменить выполнение задания?
      </h2>
      <div className="TaskDialog__Description">
        <p>
          Если вы откажитесь от выполнения задания сейчас, то вы потеряете все внесенные данные.
        </p>
        <p>
          Вы сможете попробовать выполнить задание повторно, оно будет в списке заданий пока не кончится акция
        </p>
      </div>
      <div className="TaskDialog__Controls">
        <UIRoundedButton
          size="large"
          onClick={ onCancel }
          color="secondary"
        >
          Вернуться к заданию
        </UIRoundedButton>
        <UIRoundedButton
          size="large"
          onClick={ onClose }
        >
          Отменить выполнение задания
        </UIRoundedButton>
      </div>
    </div>
  )
}

export default React.memo(TaskConfirm)
