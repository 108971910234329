import * as moment from 'moment';

export const validationErrors = {
  required: 'Поле обязательно',
  email: 'Некорректный email',
  maxLength: (max) => `Максимальная длина ${max} символов`,
  countryCode: 'Код страны неверный',
  phoneNumber: 'Номер телефона неверный',
  emailIsNotExist: 'Е-mail не зарегистрирован',
  website: 'Некорректный url'
}

export class Validators {
  static required = value => {
    return ((value || typeof value === 'number') && String(value).trim()) ? undefined : validationErrors.required;
  };
  static email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? validationErrors.email
      : undefined

  static maxLength = max => value =>
    value && value.length > max ? validationErrors.maxLength(max) : undefined;

  static phoneCode = value => {
    if (!value) {
      return;
    }

    const trimmedValue = value.trim() || '';
    return /^\+\d{1,4}$/g.test(trimmedValue) ? undefined : validationErrors.countryCode;
  }

  static website = value => {
    if (!value) {
      return;
    }

    const trimmedValue = value.trim() || '';
    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(trimmedValue) ? undefined : validationErrors.website;
  }

  static maxLength240 = Validators.maxLength(240);
  static maxLength100 = Validators.maxLength(100);
  static maxLength2000 = Validators.maxLength(2000);

  static year = maxYears => value => {
    if (!value) {
      return;
    }

    const date = moment(String(value).trim(), 'YYYY', true);
    const now = moment();
    return date.isValid() && date.isBefore(now) && date.isAfter(date.clone().subtract(maxYears, 'year')) ? undefined : ' ';
  };

  static date = value => {
    if (!value || !value.replace(/[_.]/g, '')) {
      return;
    }

    const dateCustom = moment(String(value).trim(), 'DD.MM.YYYY', true);
    const dateNative = moment(String(value).trim(), 'YYYY-MM-DD', true)

    return (dateCustom.isValid() || dateNative.isValid()) ? undefined : ' ';
  };

  static day = value => {
    if (!value) {
      return;
    }

    const day = +String(value).trim();
    return !isNaN(day) && day > 0 && day <= 31 ? undefined : ' ';
  }
  static maxYears100 = Validators.year(100);

  static silentRequired = value => Validators.required(value) ? ' ' : undefined;
  static silentPhoneCode = value => Validators.phoneCode(value) ? ' ' : undefined;

  static phoneNumber = value => {
    if (!value) {
      return;
    }

    const clearedValue = value.replace(/[()-]/g, '').trim() || '';
    return /^\d{10}$/g.test(clearedValue) ? undefined : 'Номер телефона неверный';
  }

  static correctEntity = (cities, message) => cityIdOrValue => {
    if (!cities || !cities.length) {
      return;
    }

    const compareFn = !isNaN(+cityIdOrValue)
      ? city => city.id === +cityIdOrValue
      : city => city.title.toLowerCase() === cityIdOrValue.toLowerCase();

    const foundCity = cities.find(compareFn);
    return foundCity
      ? undefined
      : message;
  }

  static passwordLength = value => {
    if (!value.length) {
      return;
    }
    return (value.length >= 8) ? undefined : 'Не менее 8 символов';
  }
}
