import { SET_IS_OPEN_MODAL_PRODUCTS, SET_IS_OPEN_MODAL_NAV } from "../constants/index";

const initialState = {
  isOpen: false,
  isOpenMenu: false
};

const modalProducts = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_OPEN_MODAL_PRODUCTS:
      return {
        ...state,
        isOpen: !state.isOpen
      };
    case SET_IS_OPEN_MODAL_NAV:
      return {
        ...state,
        isOpenMenu: !state.isOpenMenu
      };
      default: return state;
  }
};

export default modalProducts;
