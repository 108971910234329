import React from 'react';
import { timer } from 'rxjs';
import { commonService } from '@services/commonService';

export const Advertizer = ({ component: Component, period, showCondition }) => {
  const [ showAd, setShowAd ] = React.useState(false);

  const saveTimestamp = () => {
    commonService.saveLocalStorage({ mobileAppAddTimestamp: Date.now() });
  }

  const handleClose = React.useCallback(
    () => {
      setShowAd(false);
      saveTimestamp();
    },
    []
  );

  React.useEffect(() => {
    const { mobileAppAddTimestamp } = commonService.readLocalStorage(['mobileAppAddTimestamp']);

    const initialDelay = mobileAppAddTimestamp
      ? Math.max(0, +mobileAppAddTimestamp + period - Date.now())
      : 0;

    const sub = timer(initialDelay, period)
      .subscribe(() => {
        setShowAd(true);
        saveTimestamp();
      });

    return () => sub.unsubscribe();
  }, []);

  return (showAd && showCondition) ? <Component onClose={handleClose} /> : null;
}
