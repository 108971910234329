import * as React from 'react';
import { RoundedButton } from './RoundedButton';

export const NoPointDialog = ({handleClick, handleClose}) => {
  return (
    <div className="NoPointDialog">
      <span className="point-modal__close"
        onClick={handleClose}
        style={{
          top: 10,
          right: 10
        }}
      ></span>
      <div className="NoPointDialog__content">
        В этом городе пока нет точек. Если вы знаете о полезных местах, добавьте их на карту!
      </div>
      <RoundedButton handleClick={handleClick}>Добавить точку</RoundedButton>
    </div>
  )
}
