import { of, Subject, throwError } from 'rxjs';
import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';
import * as API from '../api/map';
import easings from './easings';
import { YMAPS_API_KEY} from '@constants';
import { point as turfPoint } from "@turf/helpers";
import turfDistance from "@turf/distance";
import { compact } from 'lodash';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import { get } from 'lodash';
import { filter, pairwise, scan, share } from 'rxjs/operators';
import { size } from 'lodash';

export const mobileApps = {
  iOS: 'https://apps.apple.com/ru/app/1%D0%B3%D0%BE%D1%80%D0%BE%D0%B4/id1505256876',
  Android: 'https://play.google.com/store/apps/details?id=com.poleznygorod.cityplusone'
}

export const deviceTypes = new Map([
  [ 'Desktop', 'desktop' ],
  [ 'Tablet', 'tablet' ],
  [ 'Smartphone', 'mobile' ],
  [ 'Feature Phone', 'mobile' ],
  [ 'Smart-TV', 'desktop' ],
  [ 'Other Mobile', 'mobile' ]
])

export class CommonService {
  MOBILE_SIZE = 768;
  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  isMobileDevice() {
    if (typeof document === 'undefined') {
      return false;
    }
    const match = window.matchMedia || window.msMatchMedia;
    if(match) {
        var mq = match("(pointer:coarse)");
        return mq.matches;
    }
    return false;
  }

  getMobileOperatingSystem() {
    if (typeof document === 'undefined') {
      return;
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
  }

  getCoords() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        resolve,
        reject
      );
    })
  }

  get API_KEY() {
    return YMAPS_API_KEY;
  }

  loadYMaps(element) {
    const script = document.createElement('script');
    script.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${this.API_KEY}`;

    return new Promise((resolve, reject) => {
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject)
      element.appendChild(script);
    })
  }

  async getAddressByCoords(coords) {
    if (!coords) {
      return throwError();
    }

    const { geoObjects } = await ymaps.geocode(coords)
    const geoObject = geoObjects.get(0);
    return geoObject.getAddressLine();
  }

  async getCoordsByAddressLine(addressLine) {
    if (!addressLine) {
      return throwError();
    }
    const { geoObjects } = await ymaps.geocode(addressLine);
    const geoObject = geoObjects.get(0);
    if (!geoObject) {
      return;
    }
    return geoObject.geometry.getCoordinates();
  }

  getFormFactor() {
    if (typeof window === 'undefined') {
      return '';
    }
    return deviceTypes.get(window.WURFL.form_factor);
  }

  getSize = () => {
    if (typeof window === 'undefined') {
      return {
        width: 0,
        height: 0
      };
    }

    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  popupCenter = ({url, title, w, h}) => {
    if (typeof window === 'undefined') {
      return;
    }
    var left = (window.screen.width/2)-(w/2);
    var top = (window.screen.height/2)-(h/2);
    const popup = window.open(
      url,
      title,
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left
    );
    popup.focus();
  }

  isMobileSize() {
    if (typeof window === 'undefined') {
      return false;
    }
    return this.getSize().width < this.MOBILE_SIZE;
  }

  isPhoneSize() {
    if (typeof window === 'undefined') {
      return false;
    }
    return this.getSize().width < this.MOBILE_SIZE;
  }

  copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      const textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
          return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
      } finally {
          document.body.removeChild(textarea);
      }
    }
  }

  composeKeywords = pin => {
    if (!pin) {
      return '';
    }
    const pinKeyWords = pin.keywords || '';
    const serviceFunctionKeyWords = pin.functions.reduce((acc, fn) => acc.concat(fn.keywords || ''), []);
    const hasKeyWords = pinKeyWords || serviceFunctionKeyWords;

    const keywords = hasKeyWords
      ? compact([pinKeyWords, serviceFunctionKeyWords.join(', ')]).join(', ')
      : '';

    return keywords;
  }

  findPin(map, pointId) {
    let foundObj = null;
    if (!map) {
      return null;
    }

    map.geoObjects
      .each((objectManager) => {
        if (!(objectManager instanceof ymaps.LoadingObjectManager)) {
          return;
        }
        if (foundObj) {
          return;
        }

        foundObj = objectManager.objects.getAll().find(obj => obj.id === pointId);
      });

    return foundObj;
  }

  translitPin(pin) {
    const title = get(pin, 'properties.title') || get(pin, 'title')
    const formattedTitle = title
      .replace(/[^A-Za-zА-Яа-я]/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();

    return cyrillicToTranslit().transform(formattedTitle, '-').toLowerCase();
  }

  toTranslit(map, pointId) {
    const foundPin = this.findPin(map, pointId);
    if (!foundPin) {
      return '';
    }

    return this.translitPin({...foundPin});
  }

  get locationHref() {
    if (typeof window !== 'undefined') {
        return window.location.href;
    }

    return '';
  }

  get locationSearch() {
    if (typeof window !== 'undefined') {
      return window.location.search;
    }

    return '';
  }

  get locationPathname() {
    if (typeof window !== 'undefined') {
      return window.location.search;
    }

    return '';
  }

  createCitiesStruct(cities) {
    if (!cities) {
      return [];
    }

    return cities
      .filter(city => city.latitude && city.longitude)
      .map(city => {
        return {
          id: city.id,
          name: city.title,
          slug: city.slug,
          center: [city.latitude, city.longitude],
          currentCenter: [city.latitude, city.longitude],
          centerPoint: turfPoint([city.latitude, city.longitude])
        }
      });
  }

  findClosestCity(coords, citiesStruct) {
    const closestPlace = citiesStruct.reduce(
      (state, city) => {
        const distance = turfDistance(city.centerPoint, coords);
        return state.distance > distance
          ? { distance, city }
          : state;
      },
      { distance: Infinity, city: null }
    );

    return closestPlace.city;
  }

  finishPointLoadingChanges() {
    if (typeof document === 'undefined') {
      return of();
    }

    const loadingChanges$ = new Subject();
    const finishLoadingChanges$ = loadingChanges$.pipe(
      scan((set, fn) => fn(new Set(set)), new Set()),
      pairwise(),
      filter(([prev, next]) => prev.size > 0 && !next.size),
      share()
    );

    const target = document.head;
    const config = {
      childList: true
    };
    const callback = mutationsList => {
      for (let mutation of mutationsList) {
        const addedScripts = Array.from(mutation.addedNodes)
          .filter(node => node.src && node.src.includes('points_in_tile'));

        if (size(addedScripts)) {
          addedScripts.forEach(script => loadingChanges$.next(set => set.add(script.src)));
        }

        const removedScripts = Array.from(mutation.removedNodes)
          .filter(node => node.src && node.src.includes('points_in_tile'));

        if (size(removedScripts)) {
          removedScripts.forEach(script => loadingChanges$.next(set => {
            set.delete(script.src);
            return set;
          }));
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(target, config);

    return finishLoadingChanges$;
  }

  composeQueryString(location, tagFilter, subcategories, categoryFilters) {
    const queryArray = [];
    const params = new URLSearchParams(location.search);

    const city = params.get('city')
    if (city) {
      queryArray.push(`city=${city}`)
    }

    if (tagFilter) {
      queryArray.push(`tag=${tagFilter}`)
    }

    if (subcategories) {
      const enabledSubs = subcategories.filter(sub => sub.selected);
      if (enabledSubs.length) {
        queryArray.push(`subs=${enabledSubs.map(enabledSub => enabledSub.slug)}`)
      }
    }

    if (categoryFilters) {
      const filterIds = Object.keys(categoryFilters)
      const enabledFiltersSlugs = filterIds
        .filter(key => categoryFilters[key].selected)
        .map(key => categoryFilters[key].slug);

      if (enabledFiltersSlugs.length) {
        queryArray.push(`catfilters=${enabledFiltersSlugs}`)
      }
    }

    return queryArray.join('&');
  }

  isOnlinePoint(point) {
    return !point.latitude || !point.longitude;
  }

  parseQueryString(queryStr) {
    const params = new URLSearchParams(queryStr);

    const getIds = (key) => params.get(key) ?
      params.get(key).split(',') : [];

    const city = params.get('city')
    const tagFilter = params.get('tag') || '';
    const subcategorySlugs = getIds('subs');
    const categoryFilterSlugs = getIds('catfilters');
    return {
      city,
      tagFilter,
      subcategorySlugs,
      categoryFilterSlugs
    }
  }

  isAbsoluteUrl(url) {
    return /^(?:[a-z]+:)?\/\//i.test(url);
  }

  sendEvent({ category, action = '' }) {
    ReactGA.event({ category, action });
    ym('reachGoal', category, { action });
  }

  composeSearchQuery(searchText, search, [latitude, longitude]) {
    const searchParams = new URLSearchParams(search);
    searchParams.set('search', searchText);
    searchParams.set('lat', latitude);
    searchParams.set('long', longitude);

    return searchParams.toString();
  }

  clearSearchQuery(location) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('search');
    searchParams.delete('lat');
    searchParams.delete('long');

    return searchParams.toString();
  }

  determineCity(location) {
    const params = new URLSearchParams(location.search);
    return params.get('city');
  }

  clearFilterQuery(location) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('subs');
    searchParams.delete('catfilters');
    searchParams.delete('tag');

    return searchParams.toString();
  }

  hasSearchQuery(queryStr) {
    return /search=\S*&lat=\d{2}\.\d+&long=\d{2}\.\d+/
      .test(decodeURIComponent(queryStr));
  }

  async fetchCities() {
    try {
      const { data: cities } = await API.fetchCities();
      return cities;
    } catch(e) {
      console.error('Cities could not be fetched.')
      return [];
    }
  }

  clearTileTree(map) {
    this.getObjectManagers(map)
      .forEach((objectManager) => {
        objectManager._dataLoadController._tileLoadTree.clear();
      });
  }

  getObjectManagers(map) {
    const objectManagers = [];

    if (!map) {
      return objectManagers;
    }

    map.geoObjects.each((object) => {
      if (object instanceof ymaps.LoadingObjectManager) {
        objectManagers.push(object);
      }
    });

    return objectManagers;
  }

  cleanObjectManagers(map) {
    const objectManagers = this.getObjectManagers(map);

    objectManagers.forEach(objectManager => {
      objectManager.objects.removeAll();
    });
  }

  updateObjectManagers(map) {
    const objectManagers = this.getObjectManagers(map);

    objectManagers.forEach(objectManager => {
      objectManager.reloadData();
    });
  }

  clearSelectionSubCategories(subcategories) {
    if (!subcategories) {
      return;
    }

    return subcategories.map(subCat => ({
      ...subCat,
      selected: false,
      servicefunctionSet: subCat.servicefunctionSet.map(fnSet => ({
        ...fnSet,
        selected: false,
        enabled: false
      }))
    }));
  }

  getLinkByOS(name) {
    return mobileApps[name];
  }

  scrollIt(destination, duration = 200, easing = 'linear', callback) {
    if (typeof window === 'undefined') {
      return;
    }

    const start = window.pageYOffset;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;

    if ('requestAnimationFrame' in window === false) {
      window.scroll(0, destinationOffset);
      if (callback) {
        callback();
      }
      return;
    }

    function scroll() {
      const now = 'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, ((now - startTime) / duration));
      const timeFunction = easings[easing](time);
      window.scroll(0, Math.ceil((timeFunction * (destinationOffset - start)) + start));

      if (window.pageYOffset === destinationOffset) {
        if (callback) {
          callback();
        }
        return;
      }

      requestAnimationFrame(scroll);
    }

    scroll();
  }

  saveLocalStorage(data) {
    if (typeof window === 'undefined') {
      return;
    }

    Object
      .keys(data)
      .forEach(key => localStorage.setItem(key, data[key]));
  }

  readLocalStorage(keys) {
    if (typeof window === 'undefined') {
      return {};
    }

    const toBoolean = value => {
      return (value === 'true' || value === 'false')
        ? value === 'true'
        : value;
    };

    return keys.reduce((data, key) => {
      return {
        ...data,
        [key]: toBoolean(localStorage.getItem(key))
      }
    }, {});
  }
}

export const commonService = new CommonService();
