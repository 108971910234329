import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";
import "./Menu.scss";
import { useSelector } from "react-redux";

export const MenuContent = ({ children }) => {
  return <div className="UIMenuContent">{children}</div>;
};

export const Menu = ({ children, className, onClick, isOpen }) => {
  const store = useSelector((state) => state);
  return (
    <div>
      {store.modalProducts.isOpen && (
        <OutsideClickHandler onOutsideClick={onClick}>
          <div className={clsx("UIMenu", className)}>{children}</div>
        </OutsideClickHandler>
      )}
    </div>
  );
};
