import React from 'react'
import clsx from 'clsx'
import { UIPrizeCard } from '@UIKit'
import './PrizesList.scss'


function PrizesList({
  className,
  list,
  onSelect,
  isUserPrizes,
  disabled,
}) {
  return (
    <div className={ clsx('PrizesList', className) }>
      { list && list.map((item) => (
        <UIPrizeCard
          className="PrizesList__Item"
          data={ item }
          onSelect={ onSelect }
          isUserPrizes={ isUserPrizes }
          disabled={ disabled }
          key={ item.id }
        />
      ))
      }
    </div>
  )
}

export default React.memo(PrizesList)
