import React from 'react';

const TaskDescription = React.memo(({ isUserTasks, task }) => {
  return (
    <div className="Task__Description TaskDescription">
      <p className="TaskDescription__Tip">
        Сегодня выполнить это задание больше нельзя
      </p>
      <div className="TaskDescription__Content">
        { isUserTasks
          ? task.title
          : <div dangerouslySetInnerHTML={{ __html: task.content }} /> }
      </div>
    </div>
  );
})

export default TaskDescription;