import React from 'react';
import clsx from 'clsx';
import { UIPortal } from '@UIKit';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { CSSTransition } from 'react-transition-group';

const cellHeightCache = new CellMeasurerCache({
  defaultHeight: 30,
  minHeight: 30,
  fixedHeight: true,
  fixedWidth: true
})

export const SuggestionsList = ({
  filteredSuggestions,
  activeSuggestion,
  onClick,
  inputRef,
  width = 200,
  isOpen = true,
  isDropdown,
  renderIndex = 0
}) => {

  const handleSelect = React.useCallback(
    suggestion => () => onClick(suggestion),
    []
  );

  const rowRenderer = ({ key, index, parent, style }) => {
    const suggestion = filteredSuggestions[index]
    const className = clsx(
      'SuggestionsList__Item',
      index === activeSuggestion && 'SuggestionsList__Item--active'
    );

    return (
      <CellMeasurer
        cache={cellHeightCache}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <li className={className}
          style={style}
          onClick={(handleSelect(suggestion))}>
          { suggestion.value }
        </li>
      </CellMeasurer>
    );
  };

  const getPosition = () => {
    if(!inputRef.current) {
      return null;
    }

    const inputWidth = inputRef.current.clientWidth;
    const { top, left } = inputRef.current.getBoundingClientRect()
    const leftPadding = 20;
    const topPadding = isDropdown ? 5 : 2;
    const rightPadding = 15;
    const fullWidth = (Math.min(width, inputRef.current.clientWidth) + leftPadding + rightPadding);
    const dx = inputWidth - fullWidth > 0 ? 0 : (inputWidth - fullWidth) / 2;

    const style = inputRef.current.currentStyle || window.getComputedStyle(inputRef.current);
    const marginBottom = +style.marginBottom.match(/\d/g).join('');
    const scrollTop = document.documentElement.scrollTop;

    return {
      top: top + (inputRef.current.clientHeight + marginBottom) + scrollTop + topPadding + 'px',
      left: left + dx + 'px',
    }
  };

  return (
    <UIPortal className="SuggestionsList__Portal" zIndex={UIPortal.zIndex + renderIndex}>
      <CSSTransition in={isOpen} timeout={10000} classNames="SuggestionsList" unmountOnExit appear>
        <div className="SuggestionsList" style={{
          ...getPosition(),
          zIndex: UIPortal.zIndex + renderIndex
        }}>
          <List
            className="SuggestionsList__List"
            rowHeight={cellHeightCache.defaultHeight}
            height={160}
            rowCount={filteredSuggestions.length}
            rowRenderer={rowRenderer}
            width={width}
            style={{
              height: 'auto',
              maxHeight: '160px',
              maxWidth: `${Math.min(width, inputRef.current.clientWidth)}px`
            }}
          />
        </div>
      </CSSTransition>
    </UIPortal>
  );
}
