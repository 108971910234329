import React, {useState} from "react";
import "./DotsStage_1.scss";
import SVG from "react-inlinesvg";
import dotsAdd from "@images/svg/dots__add-dots.svg";
import store from "@images/svg/dots__store.svg";
import price from "@images/svg/dots__price.svg";
import arrowLeft from "@images/svg/arrow-left-prize.svg";
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from "@UIKit/Modal/Modal";
import { getFetchData } from '@redux/actions/feature'
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";


const DotsStage_1 = ({ setStep, nextStep, handleFormData, values }) => {
  const history = useHistory();
  const [isOpen, setIzOpen] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  if (history.search === '?city=kazan' && firstRender === true) {
    setIzOpen(true);
    setFirstRender(false);
  }

  return (
    <div className="dots-stage_1">
      <div className="dots-container">
        <a href="/" className="dots__arrowMap"><SVG src={arrowLeft}></SVG>На карту</a>
        <h1 className="dots__title">Добавить свою точку на карту!</h1>
        <div className="dots-stage_1__desc">Пекарни, салоны, ремонтные мастерские, лавочки фермерских продуктов и сезонных овощей – всё это ВОКРУГ тебя.</div>
        <div className="dots-stage__items">
          <div className="dots-stage_1__item">
            <div className="dots-stage__img">
              <SVG src={dotsAdd} />
            </div>
            <div className="dots-stage__text">Добавляй точки, которые ты знаешь, на карту +1Город, чтобы расширять полезную сеть.
            </div>
          </div>
          <div className="dots-stage_1__item">
            <div className="dots-stage__img">
              <SVG src={store} />
            </div>
            <div className="dots-stage__text">Рекомендуй те бизнесы, чей товар или услуга тебе нравится.
            </div>
          </div>
          <div className="dots-stage_1__item">
            <div className="dots-stage__img">
              <SVG src={price} />
            </div>
            <div className="dots-stage__text">А еще получай за это призы в программе лояльности +1Город.
            </div>
          </div>
        </div>
        <h2 className="dots__subtitle">Выбери свою роль</h2>
        <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
          <InputLabel id="demo-simple-select-standard-label">Выберите свою роль</InputLabel>
          <Select
            className="dots-select"
            labelId="demo-simple-select-standard-label"
            id="role"
            defaultValue={values.role}
            onChange={handleFormData('role')}
            label="Выбери специализацию"
          >
            <MenuItem value={0}>Городской житель</MenuItem>
            <MenuItem value={1}>Представитель бизнеса</MenuItem>
            <MenuItem value={2}>Волонтер</MenuItem>
          </Select>
        </FormControl>
        <div className="dots__flex-container">
          <Link className="dots__button" onClick={() => setStep(values.role === 1 ? 2 : 3)}>Продолжить</Link>
        </div>
      </div>
      <Modal isOpen={isOpen} setIzOpen={setIzOpen}></Modal>
    </div>
  );
};

export default React.memo(DotsStage_1);
