import React from 'react'
import { UIDialog, UIRoundedButton, UICardMobile } from '@UIKit'
import { pluralizePoints } from '@UIKit/utils/pluralize'
import { commonService } from '@services/commonService'
import './TaskPreviewDialog.scss'


function TaskPreviewDialog({
  data,
  welcomeAmount,
  onClose,
  onUnauthorized,
  isOpen,
}) {
  const isMobile = commonService.isMobileSize()

  // TODO:
  if (!isOpen || !data) return null

  return (
    <UIDialog
      className="TaskPreviewDialog"
      rootClassName="TaskPreviewDialog__Root"
      onClose={ onClose }
      isOpen={ isOpen }
      onBackdropClick={ onClose }
      blurred
    >
      <div className="TaskPreviewDialog__Content">
        <div className="TaskPreviewDialog__Segment">
          <h2 className="TaskPreviewDialog__Title">
            Задание: { data.points } { pluralizePoints(data.points) }
          </h2>

          <div
            className="TaskPreviewDialog__Description"
            dangerouslySetInnerHTML={{ __html: data.content }}
          />

          <UIRoundedButton
            className="TaskPreviewDialog__Button"
            color="king"
            onClick={ onUnauthorized }
          >
            Получить { welcomeAmount } { isMobile ? 'бонусных' : 'приветственных' } { pluralizePoints(welcomeAmount) }
          </UIRoundedButton>
        </div>

        <UICardMobile isMobile={ isMobile } />
      </div>
    </UIDialog>
  )
}

export default TaskPreviewDialog
