import { createActions } from "redux-actions";
import * as API from "../../api/map";
import { SET_MAP, CHECK_TILE } from "../constants";
import { loadOnlinePoints, setSearchResult, setPoints } from "./points";
import { getQueryVariable } from "../../utils/queryHelper";
import { replace } from "connected-react-router";
import { sample } from "lodash";
import baseDebug from "debug";
import { find } from "lodash/fp";
import { commonService } from "../../services/commonService";
import { MAX_ZOOM } from "@constants";
const debug = baseDebug("gorod:App");
export const { setMap, checkTile } = createActions(SET_MAP, CHECK_TILE);

const isCorrectCity = (city) => city.longitude && city.latitude;

export const fetchCities = () => async (dispatch) => {
  try {
    const cities = await commonService.fetchCities();
    dispatch(setMap({ cities: cities.filter(isCorrectCity) }));
  } catch (e) {
    throw e;
  }
};

function load(tiles) {
  return Promise.all(
    tiles.map(async (tile) => {
      const { data } = await API.fetchTile(tile.properties.points);
      return data;
    })
  );
}

export const fetchTiles = () => async (dispatch) => {
  try {
    const res = await API.fetchTiles();
    const tiles = res.data.results;
    const tilesData = await load(tiles);
    const result = tiles.map((tile, idx) => ({
      ...tile,
      properties: {
        ...tile.properties,
        data: tilesData[idx].features,
      },
    }));
    dispatch(setMap({ tiles: result }));
  } catch (e) {
    throw e;
  }
};

export const detectMe = () => async (dispatch) => {
  const location = await ymaps.geolocation.get({
    provider: "browser",
    mapStateAutoApply: true,
  });
  dispatch(setMap({ me: location.geoObjects }));
};

export const goToCity = (url, citySlug) => replace(`${url}?city=${citySlug}`);

export const userAgreed = (location) => async (dispatch, getState) => {
  const {
    coords: { latitude, longitude },
  } = location;
  const {
    map: { staticTile, bounds },
    appState: { mapChangedBounds }
  } = getState();

  dispatch(loadOnlinePoints());

  debug("setMaps %o", location);

  if (!mapChangedBounds) {
    dispatch(
      setMap({
        center: [latitude, longitude],
        currentCenter: [latitude, longitude],
        zoom: MAX_ZOOM - 1,
      })
    );
  }

  if (staticTile && bounds.length > 0) {
    dispatch(checkTile(bounds));
  }
};

export const userDisagreed = (defaultPath) => (dispatch, getState) => {
  const state = getState();

  dispatch(loadOnlinePoints());
  debug("geolocation failed, defaulting to moscow center");
  if (!defaultPath.includes("points")) {
    dispatch(
      setMap({
        city: "Москва",
        center: [55.753215, 37.622504],
        currentCenter: [55.753215, 37.622504],
        zoom: 12,
        showPopover: true
      })
    );
    dispatch(goToCity(defaultPath, state.map.fullCity.slug));
  }
};

export const tryToDetermineUser = (pathname) => async (dispatch, getState) => {
  const state = getState();
  if (!("geolocation" in navigator)) {
    dispatch(goToCity(pathname, state.map.fullCity.slug));
  } else {
    try {
      const location = await commonService.getCoords();
      dispatch(userAgreed(location));
    } catch (e) {
      dispatch(userDisagreed(pathname));
    }
  }
  dispatch(setMap({ isGeoPositionProcessed: false }));
};

export const setRandomPlaceholder = () => (dispatch, getState) => {
  const state = getState();
  const { filters } = state.points;

  const randomFilter = sample(filters);
  const placeholder = (randomFilter && randomFilter.title) || "";
  dispatch(setSearchResult({ placeholder }));
};

export const initializeMap = (browserLocation) => async (dispatch, getState) => {
  const {
    points,
    map: { slug },
  } = getState();

  let geolocationState;
  try {
    geolocationState = await navigator.permissions.query({
      name: "geolocation",
    });
  } catch (e) {
    geolocationState = await Promise.resolve({
      state: "prompt",
    });
  }

  const isGeoPromptOrGranted = geolocationState.state === 'prompt' || geolocationState.state === 'granted' ;

  if (isGeoPromptOrGranted) {
    dispatch(setMap({ isGeoPositionProcessed: false }));
  }

  const { pathname } = browserLocation;
  const hasPoints = pathname.includes("points");
  const citySlug = getQueryVariable(browserLocation, "city");
  const hasCity = citySlug && citySlug !== "null";
  if (!hasPoints && !hasCity && isGeoPromptOrGranted) {
    dispatch(tryToDetermineUser(pathname));
  } else {
    dispatch(
      setMap({
        isGeoPositionProcessed: false,
        slug: citySlug || slug
      })
    );
  }
  dispatch(detectMe());
  const { filters } = points;
  const funcId = getQueryVariable(browserLocation, "func");
  if (funcId) {
    const filter = find({ id: +funcId }, filters);
    dispatch(setPoints({ setFilter: filter, isFilter: true }));
  }
};
