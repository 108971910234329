import React from "react";
import "./CommunityOfDogLovers.scss";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Validators } from '@UIKit';

const CommunityOfDogLovers = ({ handleSubmit, handleFormData, prevStep, values }) => {
  const [error, setError] = React.useState(false);
  const submitFormData = (e) => {
    e.preventDefault();
    if (
      Validators.required(values.city) ||
      Validators.required(values.address)
    ) {
      setError(true);
    } else {
      handleSubmit();
    }
  };

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 24,
    height: 24,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',

  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'white',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#FFE04D, #fff 48%,transparent 40%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#FFE04D',
    },
  });

  return (
    <div className="action">
      <div className="dots-action">
        <div className="dots-action__desc-container">
          <div className="dots-stage_2__desc">Добавить сообщество любителей собак</div>
          <div className="dots__coins">+200 экокоинов</div>
        </div>
        <Box
          className="dots-stage_3__box"
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">Город</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="1"
              value={values.city}
              defaultValue={values.city}
              onChange={handleFormData('city')} 
              label="Город"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Москва</MenuItem>
              <MenuItem value={20}>Питер</MenuItem>
              <MenuItem value={30}>Барыш</MenuItem>
            </Select>
          </FormControl>
          {error && Validators.required(values.city)}
          <TextField className="dots__input" onChange={handleFormData('address')} defaultValue={values.address} id="standard-basic" label="Улица, дом, строение, корпус" variant="standard" />
          {error && Validators.required(values.address)}
          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder="Ближайший адрес к точке сбора участников сообщества или описание маршрута "
            style={{ width: "100%", padding: "10px", border: "1px solid #EBEBEB", borderRadius: "8px"}}
          />
          <TextField className="dots__input" id="standard-basic" onChange={handleFormData('groupTime')} defaultValue={values.groupTime} label="Время сбора" variant="standard" />
          <TextField className="dots__input" id="standard-basic" onChange={handleFormData('groupSize')} defaultValue={values.groupSize} label="Сколько человек на встречах" variant="standard" />
          <div className="dots-stage_2__desc dots-stage_2__desc--fol">Чем занимаются участники
            с собаками на встречах?</div>
          <FormGroup className="dots-formgroup-checkbox">
            <FormControlLabel control={<Checkbox />} label="Щенки" />
            <FormControlLabel control={<Checkbox />} label="Мелкие породы" />
            <FormControlLabel control={<Checkbox />} label="Средние породы" />
            <FormControlLabel control={<Checkbox />} label="Крупные породы" />
            <FormControlLabel control={<Checkbox />} label="Пожилые собаки" />
            <FormControlLabel control={<Checkbox />} label="Собаки из приюта" />
          </FormGroup>
          <div className="dots-stage_2__desc dots-stage_2__desc--fol">Нуждается ли площадка в усовершенствовании/ремонте?</div>
          <FormControl className="dots-stage_2__formControl dots-stage_2__formControl--radio">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel value="yes" control={<Radio checkedIcon={<BpCheckedIcon />} />} label="Да" />
              <FormControlLabel value="no" control={<Radio checkedIcon={<BpCheckedIcon />} />} label="Нет" />
            </RadioGroup>
          </FormControl>
          <div className="input-wrapper-field rec">
          <div className="dots-stage_2__desc small">Добавить рекомендацию</div>
          <span className="ecocoin">+100 экокоинов</span></div>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={6}
            placeholder="Напиши, что тебе нравится больше всего"
            style={{ width: "100%", padding: "10px", border: "1px solid #EBEBEB", borderRadius: "8px"}}
            onChange={handleFormData('description')}
            defaultValue={values.description}
          />
        </Box>
        <div className="dots__flex-container">
          <Link className="dots__button" onClick={submitFormData}>Продолжить</Link>
          <Link className="dots__button dots__button--transparent" onClick={prevStep}>Назад</Link>
        </div>
      </div>
    </div>
  );
};

export default CommunityOfDogLovers;
