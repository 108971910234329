import { SET_SEARCH_RESULT, RESET_SEARCH_RESULT } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

const initialState = {
  searchTextToDisplay: '',
  searchText: '',
  searchResult: [],
  searchQuery: null,
  modalSearchResult: false
};

export default makeReducer(initialState, SET_SEARCH_RESULT, (state, action) => {
  const { type } = action;
  switch (type) {
    case RESET_SEARCH_RESULT:
      return initialState;
    default:
      return state;
  }
});
