import './FavoritePlace.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { createPoint } from '../../../../redux/actions/points';
import { Validators } from '@UIKit';
import SVG from 'react-inlinesvg';
import arrowLeft from '@images/svg/arrow-left-prize.svg';
import arrow from '@images/svg/arrow-kazan.svg';
import cosmetic from '@images/cosmetic.jpg';
import slider1 from '@images/kazan-slider-1.jpg';
import slider2 from '@images/kazan-slider-2.jpg';
import slider3 from '@images/kazan-slider-3.jpg';
import slider4 from '@images/sil.png';
import star from '@images/svg/star_req.svg';
import { sortBy, pickBy } from 'lodash';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";
import useDevice from "@hooks/useDevice";
import vokrug1 from '@images/svg/vokrug-1.svg';
import vokrug2 from '@images/svg/vokrug-2.svg';
import vokrug3 from '@images/svg/vokrug-3.svg';
import vokrug4 from '@images/svg/vokrug-4.svg';
import vokrug5 from '@images/svg/vokrug-5.svg';

const defaultForm = {
  pointType: 0,
  pointTag: 'service',
  authorName: '',
  authorEmail: '',
  authorPosition: '',
  title: '',
  functions: [],
  city: '',
  house: '',
  address: '',
  phone: '',
  url: '',
  description: '',
  activeDateFrom: '',
  activeDateTo: '',
  socialMediaLink1: '',
  socialMediaLink2: '',
  socialMediaLink3: '',
  socialMediaLink4: '',
  promoCode: '',
  groupTime: '',
  groupSize: '',
  role: 0,
}

const toSuggestion = (filter) => ({ ...filter, key: filter.id, value: filter.title });

const FavoritePlace = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [device] = useDevice();

  let [formData, setFormData] = useState({
    pointType: 0,
    pointTag: 'service',
    authorName: '',
    authorEmail: '',
    authorPosition: '',
    title: '',
    functions: [],
    house: '',
    city: '',
    address: '',
    phone: '',
    url: '',
    description: '',
    activeDateFrom: '',
    activeDateTo: '',
    socialMediaLink1: '',
    socialMediaLink2: '',
    socialMediaLink3: '',
    socialMediaLink4: '',
    promoCode: '',
    groupTime: '',
    groupSize: '',
    role: 0,
  });
  const [errorNumber, setErrorNumber] = useState(false);
  const [pointCities, setPointCities] = useState([]);
  const [error, setError] = useState(false);
  const [errorMail, setErrorMail] = useState('');

  const store = useSelector((state) => state);

  const handleCreatePoint = () => {
    formData.city.length || setFormData(formData, formData.city = 70);
    formData.functions.length || setFormData(formData, formData.functions = [70]);
    formData.phone || setFormData(formData, formData.phone = '+79999999999');
    formData.authorName || setFormData(formData, formData.authorName = 'service');
    formData.authorName && setFormData(formData, formData.address = `${formData.address} ${formData.house}`)

    const phoneNumbers = formData.phone.split('').filter(element => element !== ' ');

    phoneNumbers.length !== 12 ? setErrorNumber(true) : dispatch(createPoint(pickBy(formData), dispatch));
  };

  const finishFunction = (textInput) => {
    handleCreatePoint();
    if (textInput === 'Отправить') {
      formData = defaultForm;
      setFormData(formData);
    }
  }

  const emailValidate = (formData.authorEmail === '' || formData.authorEmail.indexOf('@') === -1 || formData.authorEmail.indexOf('.') === -1);

  const submitFormData = e => {
    const textInput = e.target.innerText;
    e.preventDefault();
    if (Validators.required(formData.title) ||
      Validators.required(formData.address) ||
      Validators.required(formData.authorEmail) ||
      emailValidate) {
      setError(true);
      emailValidate && setErrorMail('Некорректный email');
    } else {
      finishFunction(textInput);
    }
  };

  const handleInputData = (input) => (e) => {
    input === 'functions' ? setFormData({ ...formData, [input]: [e.target.value] }) : setFormData({ ...formData, [input]: e.target.value })
    formData.phone.length === 12 && setErrorNumber(false);
    !emailValidate && setErrorMail('');
  };

  useEffect(() => {
    setPointCities(sortBy(store.map.cities, ['title']).map(toSuggestion));
  }, [store.map.cities]);

  return (
    <div className='dots-stage_3 bigForm bigFormKazan favoritePlace'>
      <div className='dots-container'>
        <h1 className='dots__title'>Любимым местам быть</h1>
        <p className='dots-p'>У тебя есть «свои» места, где ты обычно 🛒 покупаешь продукты, 🛠 ремонтируешь технику, ☕️ пьешь кофе, 🐈‍⬛ покупаешь корм питомцу?
        </p>
        <div className='favoritePlace__wrapper'>
          <div className='favoritePlace__card'>
            <SVG src={vokrug1} />
            <div>
              Качество жизни сильно ухудшается, когда магазины закрываются, товары пропадают, а люди теряют работу.
              Ты можешь это изменить!
            </div>
          </div>
          <div className='favoritePlace__card'>
            <span>1</span>
            <SVG src={vokrug2} />
            <div>
              Порекомендуй места, которые тебе нравятся,
              и они появятся на карте
            </div>
          </div>
          <div className='favoritePlace__card'>
            <span>2</span>
            <SVG src={vokrug3} />
            <div>
              Получи за каждый адрес баллы и обменяй их на скидки и призы
            </div>
          </div>
          <div className='favoritePlace__card'>
            <span>3</span>
            <SVG src={vokrug4} />
            <div>
              Жители района будут совершать покупки
              в рекомендованных местах и они останутся открытыми.
            </div>
          </div>
          <div className='favoritePlace__card'>
            <span>4</span>
            <SVG src={vokrug5} />
            <div>
              Даже в кризис товары
              и услуги останутся доступными, а рабочие места будут сохранены
            </div>
          </div>

        </div>
        <div className='dots-action'>
          <Box
            className='dots-stage_3__box'
            component='form'
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete='off'
          >
            <div className='input-wrapper-field-label-kazan'>Название места</div>
            <div className='input-wrapper-field'>
              <TextField
                className='dots__input'
                onChange={handleInputData('title')}
                value={formData.title}
                id="outlined-error-helper-text"
                label='Любимое кафе, салон, магазин и пр'
                variant='standard'
                error={(error && Validators.required(formData.title)) ? true : false}
              />
              {error && Validators.required(formData.title)}
            </div>
            <div className='input-wrapper-field-label-kazan'>Адрес</div>
            <div className='input-wrapper-field'>
              <TextField
                className='dots__input'
                id="outlined-error-helper-text"
                onChange={handleInputData('address')}
                value={formData.address}
                label='Улица'
                variant='standard'
                error={(error && Validators.required(formData.address)) ? true : false}
              />
              <div className='icon-star'>
                100 баллов
              </div>
              {error && Validators.required(formData.address)}
            </div>
            <div className='input-wrapper-field-label-kazan'>Знаешь номер дома? Удваивай баллы</div>
            <div className='input-wrapper-field'>
              <TextField
                className='dots__input'
                id='standard-basic'
                onChange={handleInputData('house')}
                value={formData.house}
                label='Дом'
                variant='standard'
              />
              <div className='icon-star'>
                х2
              </div>
            </div>
            <div className='input-wrapper-field-label-kazan'>Твой email</div>
            <div className='input-wrapper-field'>
              <TextField
                className='dots__input'
                type='email'
                id="outlined-error-helper-text"
                value={formData.authorEmail}
                onChange={handleInputData('authorEmail')}
                label='email'
                variant='standard'
                error={errorMail ? true : false}
              />
              {/* {error && (Validators.required(formData.authorEmail))} */}
              {errorMail}
            </div>
          </Box>
          <div className='dots-cooperation'>Отправляя форму, ты соглашаешься с <a href='https://gorod.plus-one.ru/legal.pdf' target='_blank' rel='noreferrer'>политикой конфиденциальности</a></div>
          <div className='dots__flex-container'>
            <div className='dots__button kazan' onClick={(e) => submitFormData(e)}>
              Отправить
            </div>
          </div>
        </div>
        {/* <ul className='dots__list-first-block'>
          <li className='dots__item-first-block'>
            <div>Больше добавленных мест</div>
            <SVG src={arrow}></SVG>
          </li>
          <li className='dots__item-first-block'>
            <div>Больше баллов</div>
            <SVG src={arrow}></SVG>
          </li>
          <li className='dots__item-first-block'>
            <div>Больше подарков</div>
          </li>
        </ul> */}
        <div className='dots__text-first-block'>Гарантированные призы за баллы</div>
        <div className='dots__firs-block-cosmetic'>
          <div className='dots__wrapper-image-first-block'>
            <img src={cosmetic} />
          </div>
          <div className='dots__text-first-block-d'>
            <div className='dots__text-first-block'>Главный приз</div>
            <div className='dots__grey-text'>Набор косметики SYNERGETIC</div>
            <span>Розыгрыш для самых активных</span>
          </div>
        </div>
        <div className='dots__wrapper-images-second'>
          <div className='fw__wrapper-image'>
            <div className='f__wrapper-image'>
              <img src={slider1} />
              <p>60% скидка в Нетологии</p>
              <span>180 баллов</span>
            </div>
            <div className='f__wrapper-image'>
              <img src={slider4} />
              <p>Скидки на товары и услуги</p>
              {/* <span>50 баллов</span> */}
            </div>
            <div className='f__wrapper-image'>
              <img src={slider2} />
              <p>Косметика</p>
              <span>1100 баллов</span>
            </div>
            <div className='f__wrapper-image'>
              <img src={slider3} />
              <p>Экотовары</p>
              <span>800 баллов</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritePlace;
