import React from 'react';
import { UIInput } from '@UIKit';
import eyeIcon from '@images/design/eye.svg';
import eyeOffIcon from '@images/design/eye-off.svg';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';
import './PasswordInput.scss';

export const PasswordInput = ({ className, ...props }) => {
  const [ isVisible, setIsVisible ] = React.useState(false);
  const toggleButton = React.useCallback(() => setIsVisible(!isVisible), [isVisible]);
  const getType = () => isVisible ? 'text' : 'password';

  const topLabel = () => {
    if (props.istop) {
      return <span className="Input__Label--top">{props.istop}</span>
    }
  }
  
  return (
    <div className={clsx("PasswordInput", className)}>
      <UIInput type={getType()} {...props}>
        <button type="button" onClick={toggleButton} className="PasswordInput__Button">
          { isVisible ? <SVG src={eyeOffIcon} /> : <SVG src={eyeIcon} />}
        </button>
      </UIInput>
      {topLabel()}
    </div>
  );
}