import React, { useState } from "react";
import "./Prize_congratulation.scss";
import SVG from "react-inlinesvg";
import arrow from "@images/svg/arrow-left-prize.svg";
import { Link } from "react-router-dom";

const Prize_congratulation = () => {
  const [code] = useState("FBMACQTZRKA");
  return (
    <div className="prize_congratulation">
      <div className="prize_congratulation__container">
        <Link to='/prix'>
          <SVG className="prize_congratulation__arrow" src={arrow} />
        </Link>
        <div className="prix_in__title">Поздравляем!</div>
        <div className="prix_in__wrapper-c">
          <div className="prize_congratulation__left-block">
            <div className="prize_congratulation__description">
              <p>Ты получил приз от «Макдоналдс» — легендарный бургер «Биг Мак».</p>
              <p>Зайди в раздел «Акции» приложения «Макдоналдс» и введи промокод в строку «Есть промокод? Введите!».</p>
              <p>
                Приложение сгенерирует QR-код, который ты сможешь предъявить на кассе или в терминале в любом ресторане
                «Макдоналдс» и забрать свой подарок.
              </p>
              <p>Срок действия промокода: 31 декабря 2021 года</p>
              <p>Спасибо, что делаешь мир лучше вместе с +1Город!</p>
            </div>
          </div>
          <div className="prize_congratulation__right-block">
            <div className="prize_congratulation__copy-code">Скопируй код и отправь</div>
            <div className="prize_congratulation__text-code">{code}</div>
            <div className="prize_congratulation__buttons">
              <Link to="/loyalty/prizes" className="prix_in__nav default">
                вернуться к призам
              </Link>
              <button
                className="prix_in__nav"
                onClick={() => {
                  navigator.clipboard.writeText(code);
                }}
              >
                скопировать код
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prize_congratulation;
