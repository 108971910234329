import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import googlePlay from "@images/svg/google-play-footer.svg";
import appStore from "@images/svg/app-store-footer.svg";
import youtube from "@images/svg/youtube-footer.svg";
import instagram from "@images/svg/instagram-footer.svg";
import vk from "@images/svg/vk-footer.svg";
import facebook from "@images/svg/facebook-footer.svg";
import SVG from "react-inlinesvg";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__navbar-lvl1">
        <div className="footer__title">+1ГОРОД</div>
        <ul className="footer__list">
          <li>
            <ul>
              <li className="footer__item-lvl2 footer__item-lvl2-not-link"><Link className="footer__link-b" to="/filter/local_business">ВОКРУГ</Link></li>
              <li className="footer__item-lvl2">
                <Link to="/filter/local_business?subs=discount" className="footer__link-lvl2">
                  Товары
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/local_business?subs=services" className="footer__link-lvl2">
                  Услуги
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/local_business?subs=eco_market" className="footer__link-lvl2">
                  Экологичные товары
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/local_business?subs=local_news" className="footer__link-lvl2">
                  Местные новости
                </Link>
              </li>
            </ul>
            <ul>
              <li className="footer__item-lvl2 footer__item-lvl2-not-link"><Link className="footer__link-b" to="/filter/otvetstvenno-potreblyat">РАЗДЕЛЬНЫЙ СБОР</Link></li>
              <li className="footer__item-lvl2">
                <Link to="/filter/otvetstvenno-potreblyat?subs=eco_recycling" className="footer__link-lvl2">
                  Сдать вторсырье
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/otvetstvenno-potreblyat?subs=hazardous_waste" className="footer__link-lvl2">
                  Утилизировать опасные отходы
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/otvetstvenno-potreblyat?subs=unnecessary_things" className="footer__link-lvl2">
                  Избавиться от ненужных вещей
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/otvetstvenno-potreblyat?subs=macdonalds" className="footer__link-lvl2">
                  Мероприятия и акции
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/otvetstvenno-potreblyat?subs=events" className="footer__link-lvl2">
                  Получай подарки за выполненные задания
                </Link>
              </li>
            </ul>
            <ul>
              <li className="footer__item-lvl2 footer__item-lvl2-not-link"><Link className="footer__link-b" to="/filter/get_help">Сервисы помощи</Link></li>
              <li className="footer__item-lvl2">
                <Link to="/filter/get_help?subs=help" className="footer__link-lvl2">
                  Оказать помощь
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/get_help?subs=helping" className="footer__link-lvl2">
                  Получить помощь
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/get_help?subs=proven_funds" className="footer__link-lvl2">
                  Помочь проверенным фондам
                </Link>
              </li>
            </ul>
            <ul>
              <li className="footer__item-lvl2 footer__item-lvl2-not-link"><Link className="footer__link-b" to="/filter/pets">Животные</Link></li>
              <li className="footer__item-lvl2">
                <Link to="/filter/pets?subs=help_animals" className="footer__link-lvl2">
                  Помочь животным
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/pets?subs=services_for_animals" className="footer__link-lvl2">
                  Сервисы для животных
                </Link>
              </li>
            </ul>
            <ul>
              <li className="footer__item-lvl2 footer__item-lvl2-not-link"><Link className="footer__link-b" to="/filter/health">Здоровье</Link></li>
              <li className="footer__item-lvl2">
                <Link to="/filter/health?subs=health_beauty" className="footer__link-lvl2">
                  ЗОЖ
                </Link>
              </li>
              <li className="footer__item-lvl2">
                <Link to="/filter/health?subs=personal_care" className="footer__link-lvl2">
                  Уход за собой
                </Link>
              </li>
            </ul>
            <ul>
              <li className="footer__item-lvl2 footer__item-lvl2-not-link"><Link className="footer__link-b" to="/filter/lifestyle">Образ жизни</Link></li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="footer__navbar-lvl2">
        <ul>
          <li>
            <ul className="footer__list-lvl2">
              <li>
                <Link to="/loyalty/">Программа лояльности</Link>
              </li>
              <li>
                <Link to="/loyalty/prizes">Призы</Link>
              </li>
              <li>
                <Link to="/loyalty/tasks">Задания</Link>
              </li>
            </ul>
          </li>
          <li>
            <ul className="footer__list-lvl2">
              <li>
                <Link to="/about/">О проекте</Link>
              </li>
              <li>
                <Link to="/about/contacts/">Контакты</Link>
              </li>
              <li>
                <Link to="/sign-up/">Личный кабинет</Link>
              </li>
            </ul>
          </li>
          <li>
            <ul className="footer__list-lvl2">
              <li>
                <Link to="/about/smi">Для СМИ</Link>
              </li>
              <li>
                <Link to="/about/ads">Размещение рекламы</Link>
              </li>
              <li>
                <Link to="/about/rekvizity">Реквизиты</Link>
              </li>
            </ul>
          </li>
          <li>
            <ul className="footer__list-lvl2">
              <li>
                <Link to="/about/medianetwork">Медиасеть</Link>
              </li>
              <li>
                <Link to="/about/business_partnership">Для малого бизнеса</Link>
              </li>
              <li>
                <Link to="/about/citizens_partnership">Для горожан</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="footer__social-links">
        <div>
          <div className="footer__social-title">Подписаться</div>
          <div className="footer__social-group">
            {/* <a href="/">
            <SVG src={facebook} />
            </a> */}
            <a href="https://vk.com/project_plus_one">
              <SVG src={vk} />
            </a>
            {/* <a href="/">
            <SVG src={instagram} />
            </a> */}
            <a href="https://www.youtube.com/c/Plusoneru">
              <SVG src={youtube} />
            </a>
              <a href="/crowd/" target="_blank" className="suppotLink">Поддержи проект</a>
          </div>
        </div>
        <div>
          <div className="footer__social-title">Скачать приложение</div>
          <div className="footer__social-market">
            <a href="https://play.google.com/store/apps/details?id=com.poleznygorod.cityplusone&hl=ru&gl=US&showAllReviews=true">
              <SVG src={googlePlay} />
            </a>
            <a href="https://apps.apple.com/ru/app/%D1%8D%D0%BA%D0%BE-%D0%BF%D0%B5%D1%80%D0%B5%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0-%D1%83%D1%82%D0%B8%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F/id1505256876">
              <SVG src={appStore} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
