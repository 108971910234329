import React from 'react';
import { UIPortal } from '@UIKit';
import SVG from 'react-inlinesvg';
import closeIcon from '@images/svg/close-black.svg';
import './Dialog.scss';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { identity } from 'lodash';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export const Dialog = React.forwardRef(({
    children,
    onClose,
    isOpen,
    className,
    rootClassName,
    blurred,
    renderIndex,
    timeout = 0,
    onBackdropClick = identity,
    zIndex,
    closable = true,
    scrollY,
    backdropClassName = ''
  }, ref) => {

  const handleOnEntered = () => {
    scrollY &&  ref.current && ref.current.scroll({
      top: scrollY,
      left: 0,
      behavior: 'smooth'
    });
  }

  const onCancel = () => onClose(false);
  return (
    <UIPortal renderIndex={renderIndex} zIndex={zIndex} className={backdropClassName}>
      <CSSTransition
        in={isOpen}
        timeout={timeout}
        onEntered={handleOnEntered}
        classNames={
          clsx(
            rootClassName,
            blurred && 'UIDialog--blurred',
            'UIDialog'
          )
        }
        unmountOnExit
        appear
      >
        <div className="UIDialog">
          <div className="UIDialog__Container" ref={ref}>
            <ClickAwayListener onClickAway={onBackdropClick}>
              <div className={"UIDialog__Root"}>
                { closable && <SVG className="UIDialog__Close-Icon" src={closeIcon} onClick={onCancel} /> }
                <div className={className}>{ children }</div>
              </div>
            </ClickAwayListener>
          </div>
        </div>
      </CSSTransition>
    </UIPortal>
  )
})
