import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

const FADING_TIME = 5000;

const addOrUpdateMe = (() => {
  let placemark;
  return (me, map) => {
    const meLayout = ymaps.templateLayoutFactory.createClass('<div class="placemark-me"></div><div class="placemark-me-shadow"></div>');

    if (!placemark) {
      placemark = new ymaps.Placemark(me.position, null, {
        preset: 'islands#circleIcon',
        iconLayout: meLayout
      });
      map.geoObjects.add(placemark);
    } else {
      placemark.geometry.setCoordinates(me.position);
    }
  }
})();


const GeoLocationControl = ({ screen: { isMobile }, detectMe, noPointsInCity, me, map}) => {
  const [ timeoutId, setTimeoutId ] = useState(null);
  const [ isVisible, setIsVisible ] = useState(false);
  const [ shouldCenteredUser, setShouldCenteredUser ] = useState(false);

  const handleUserPosition = () => {
    if(shouldCenteredUser) {
      map.setCenter(me.position, 16, {
        checkZoomRange: true,
        duration: 500,
        timingFunctionL: 'ease-in'
      });
      setShouldCenteredUser(false);
    }
  }

  useEffect(() => {
    if (me) {
      addOrUpdateMe(me, map);
      handleUserPosition();
    }
  }, [me]);

  const showMessage = () => {
    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => setIsVisible(false), FADING_TIME));
    setIsVisible(true);
  }

  const hideMessage = () => {
    setIsVisible(false);
    clearTimeout(timeoutId);
  }

  const geoCodeMe = async () => {
    try {
      setShouldCenteredUser(true);
      await detectMe();
      hideMessage();
    } catch (e) {
      showMessage();
    }
  }

  const handleClick = () => {
    geoCodeMe();
  };

  const geoContainerClass = classNames({
    GeoContainer: true,
    "GeoContainer--mobile": isMobile
  });

  const geoMessageTextClass = classNames({
    GeoMessageText: true,
    "GeoMessageText--mobile": isMobile
  })

  return (
    <div className={geoContainerClass}>
      { isVisible && (
        <div className="GeoMessage">
          <i className="point-modal__close point-modal__close--white GeoCloseIcon"
            onClick={hideMessage}
          >
          </i>
          <div className={geoMessageTextClass}>
            Активируйте <br/>геолокацию в настройках
          </div>
        </div>
      )}
      <div className='navigation-btn GeoButton' onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18.889" height="18.889" viewBox="0 0 18.889 18.889">
          <g transform="translate(-274.474 -936.703) rotate(45)"><path d="M875.45,474.839a1.138,1.138,0,0,1-.452-.1l-5.212-3.06-5.141,3.023a1.125,1.125,0,0,1-1.337-.226,1.108,1.108,0,0,1-.176-1.281l5.659-10.944a1.164,1.164,0,0,1,1.993,0l5.659,10.944a1.125,1.125,0,0,1-.993,1.639Zm-5.663-11.668-5.2,10.061,5.2-3.064,5.2,3.057Zm-.164-.316h0Z" transform="translate(0 0)" fill="#1a1818"/></g>
        </svg>
      </div>
    </div>
  )
}



export default GeoLocationControl;
