import React from 'react';
import clsx from 'clsx';
import './ShrinkingButton.scss';

export const ShrinkingButton = React.memo(({
  iconContent,
  children,
  color = 'primary',
  isHovered,
  onMouseEnter,
  onMouseLeave,
  highlighted,
  onClick
}) => {
  return (
    <div className="ShrinkingButton__Container"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <button className={clsx(
          'ShrinkingButton',
          `ShrinkingButton--${color}`,
          highlighted && 'ShrinkingButton--highlighted',
          isHovered && 'ShrinkingButton--hovered'
        )}
        onClick={onClick}
      >
        { iconContent }
        <span className="ShrinkingButton__Content">{ children }</span>
      </button>
    </div>
  )
});
