import React from 'react';
import clsx from 'clsx';
import { identity } from 'lodash';
import TaskControls from './TaskControls'
import TaskDescription from './TaskDescription';
import TaskCounter from './TaskCounter';
import './Task.scss';

const Task = ({
  className,
  onClick = identity,
  onFavouritesChange = identity,
  color = 'primary',
  task,
  isAuthenticated,
  isFavourites,
  isUserTasks,
}) => {

  const isDisabled = task.limitCalc <= 0;

  const handleClick = React.useCallback(() => {
    onClick(task);
  }, [onClick, task])

  const handleFavourites = React.useCallback(() => {
    onFavouritesChange(task, isFavourites)
  }, [onFavouritesChange, task, isFavourites])

  return (
    <div
      className={
        clsx(
          'Task',
          `Task--${ color }`,
          isDisabled && 'Task--disabled',
          className
        )
      }
    >
      { isAuthenticated && <TaskCounter task={task} disabled={isDisabled} /> }

      <div className="Task__Content">
        <TaskDescription task={task} isUserTasks={isUserTasks} />

        { !isUserTasks &&
          <TaskControls
            task={ task }
            color={ color }
            onClick={ handleClick }
            onFavourites={ handleFavourites }
            isAuthenticated={ isAuthenticated }
            isDisabled={ isDisabled }
            isFavourites={ isFavourites }
          />
        }
      </div>
      <div className="Task__Score">
        <div className="Score__Container">
          <div className="Score__Points">{ task.points }</div>
          <span className="Score__Currency">Баллов</span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Task)
