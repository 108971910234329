import { SET_MAP } from '../constants/index';
import makeReducer from '../../utils/fabrickReducer';

const initialState = {
  isReady: false,
  center: [55.753215, 37.622504],
  currentCenter: [55.753215, 37.622504],
  isGeoPositionProcessed: true,
  objectsOnMap: false,
  zoom: 12,
  city: 'Москва',
  slug: 'moscow',
  showPopover: false,
  fullCity: {
    slug: 'moscow',
    name: 'Москва',
    center: [55.753215, 37.622504],
  },
  cities: [],
  hasOnlinePoints: false,
  hint: null,
  bounds: [],
  cityBoundLc: [],
  cityBoundUc: [],
  coub: 0,
  tiles: [],
  currentTiles: [],
  tilesWithData: [],
  inStatic: true,
  me: null,
  staticTile: {
    type: 'Feature',
        properties: {},
    geometry: {
        type: 'Polygon',
            coordinates: [
            [
                [
                    37.326051,
                    55.491126
                ],
                [
                    37.967682,
                    55.491126
                ],
                [
                    37.967682,
                    55.957565
                ],
                [
                    37.326051,
                    55.957565
                ],
                [
                    37.326051,
                    55.491126
                ]
            ]
        ]
    }
  }
};

export default makeReducer(initialState, SET_MAP);
