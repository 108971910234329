import React from 'react';
import './AppReturnLoyaltyButton.scss';
import { UIRoundedButton } from '@UIKit';

export const AppReturnLoyaltyButton = ({ onClick }) => {
  return (
    <UIRoundedButton size="large" color="secondary" onClick={onClick}>
      <span className="AppReturnLoyaltyButton__BtnText--long">Вернуться к программе лояльности</span>
      <span className="AppReturnLoyaltyButton__BtnText--short">Программа лояльности</span>
    </UIRoundedButton>
  )
}