import React from 'react';
import './Notification.scss';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { defaults } from 'lodash';
import { CSSTransition } from 'react-transition-group';
import { UIPortal } from '@UIKit';

export const Notification = ({ notification }) => {
  const [ isOpen, setIsOpen ] = React.useState(!!notification);

  const settings = defaults(notification, {
    type: '',
    message: '',
    title: '',
    icon: '',
    time: 0
  });

  React.useEffect(() => setIsOpen(!!notification), [notification]);

  React.useEffect(() => {
    setIsOpen(!!notification)

    if (!notification) {
      return;
    }

    setTimeout(() => setIsOpen(false), notification.time)
  }, [notification]);

  return (
      <CSSTransition in={isOpen} timeout={10000000} classNames="Notification" unmountOnExit mountOnEnter>
        <UIPortal zIndex={15000} className="Notification__Portal">
          <div className={clsx('Notification', `Notification--${settings.type}`)}>
            <div className='Notification__Icon'>
              <SVG src={settings.icon} />
            </div>
            <div className="Notification__Content">
              <h6 className={clsx('Notification__Title', !settings.message && 'Notification__Title--no-message')}>
                { settings.title }
              </h6>
              <p className="Notification__Message">
                { settings.message }
              </p>
            </div>
          </div>
        </UIPortal>
      </CSSTransition>
  )
}