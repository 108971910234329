import React from 'react'
import { UIDropzone } from '@UIKit'
import { times } from 'lodash'

function TaskFormList({
  files,
  amount,
  startIndex = 0,
  onFilesChange,
  placeholder,
  accept,
}) {
  const [fileArray, setFileArray] = React.useState([]);
  React.useEffect(() => setFileArray(new Array(amount).fill(null)), [amount]);

  const handleFiles = React.useCallback((files, index) => {
    const changedFiles = [
      ...fileArray.slice(0, index),
      ...files,
      ...fileArray.slice(index + 1)
    ];
    setFileArray(changedFiles);
    onFilesChange(changedFiles);
  }, [fileArray]);

  return (
    <ol className="TaskDialog__List">
      { times(amount, (i) => (
        <li key={ i + startIndex }>
          <div className="TaskDialog__Dropzone">
            <UIDropzone
              value={ files[i + startIndex] }
              onChange={ handleFiles }
              onDelete={ handleFiles }
              name={ i + startIndex }
              placeholder={ placeholder }
              accept={ accept }
            />
          </div>
        </li>
      ))
      }
    </ol>
  )
}

export default TaskFormList
