import { SET_FEATURE } from '@redux/constants';
import { createActions } from 'redux-actions';
import * as API from "../../api/feature";

export const { setFeature } = createActions(SET_FEATURE);

const getFetchData = () => async (dispatch) => {

    const {data} = await API.getFeature();
    dispatch(setFeature(data));
    return data;
};

export {getFetchData};