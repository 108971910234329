import React from "react";
import "./Prix_in.scss";
import SVG from "react-inlinesvg";
import euro from "@images/svg/euro-prize.svg";
import prize from "../../../../img/prize.jpg";
import prix_in from "../../../../img/prix_in.jpg";
import logo from "@images/svg/logo-prize.svg";
import arrowBottom from "@images/svg/arrow-bottom-prize.svg";
import share from "@images/svg/share-prix_in.svg";
import { Link } from "react-router-dom";

const Prix_in = () => {
  return (
    <div className="prix_in">
      <div className="prix_in__container">
        <div className="prix_in__price mobile">
          <div className="prix_in__block-price">
            <SVG className="prix_in__logo" src={logo} />
            <span className="prix_in__number">
              1500
              <SVG className="prix_in__euro" src={euro} />
            </span>
          </div>
          <div>
            <SVG src={arrowBottom} />
          </div>
        </div>
        <div className="prix_in__wrapper-desktop">
          <div className="prix_in__right-block">
            <div className="prix_in__wrapper-img">
              <img className="prix_in__img" src={prix_in} />
            </div>
          </div>
          <div className="prix_in__left-block">
            <div className="prix_in__wrapper-price">
              <div className="prix_in__price-number">
                1 500 <SVG className="prix_in__euro-small" src={euro} />
              </div>
              <SVG src={share} />
            </div>
            <Link to='/prix' className="prix_in__nav">
              Получить
            </Link>
            <div className="prix_in__title">Описание</div>
            <p>
              Гранд-кафе «Dr. Живаго» — это модный и стильный ресторан на первом этаже легендарного отеля Националь.
              Концепция современной русской кухни интересна не только туристам, но и москвичам – здесь прослеживается
              идеальный баланс между исконно русскими традициями и современными веяниями.
            </p>
          </div>
        </div>
        <div className="prix_in__title mobile">Похожие призы</div>
        <div className="prix_in__title desktop">Похожие</div>
      </div>
      <div className="prix_in__cards">
        <div className="prizes__card-default">
          <div className="prizes__card-wrapper-img">
            <img src={prize} className="prizes__card-img" />
          </div>
          <div className="prizes__card-text-wrapper">
            <div className="prizes__title-text">Большой&#160;капучино в Макдоналдс</div>
            <div className="prizes__title-purchases">Твои покупки: 1 раз(а)</div>
            <div className="prizes__title-number">
              1 500 <SVG className="prizes__euro" src={euro} />
            </div>
          </div>
        </div>
        <div className="prizes__card-default">
          <div className="prizes__card-wrapper-img">
            <img src={prize} className="prizes__card-img" />
          </div>
          <div className="prizes__card-text-wrapper">
            <div className="prizes__title-text">Большой&#160;капучино в Макдоналдс</div>
            <div className="prizes__title-purchases">Твои покупки: 1 раз(а)</div>
            <div className="prizes__title-number">
              1 500 <SVG className="prizes__euro" src={euro} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prix_in;
