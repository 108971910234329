import React from 'react';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';
import listConnector from '@images/design/list-connector.svg';
import './StepList.scss';

export const StepList = ({ children: steps, className }) => {
  return (
    <ul className={clsx("StepList", className)}>
      { React.Children.map(steps, ({ props: { children } }, indx) => {
        return (
          <li key={indx} className="StepList__Item">
            <span className="StepList__Index">{ indx + 1 }</span>
            { indx % 2 > 0 && !!steps[indx + 1] && <SVG className="StepList__Connector" src={listConnector} /> }
            { children }
          </li>
        );
      })}
    </ul>
  )
}