import React from 'react';
import { withRouter } from 'react-router-dom';
import { AppButtonPanel } from './ButtonPanel/ButtonPanel';
import clsx from 'clsx';

const AppDashboard = ({ selectedCity, className, openCityDialog, wallet, openLoyalty }) => {
  return (
    <div className={clsx(className, 'AppDashboard')}>
      <AppButtonPanel
        className="AppDashboard__ButtonPanel"
        selectedCity={selectedCity}
        openCityDialog={openCityDialog}
        wallet={wallet}
        openLoyalty={openLoyalty}
      /> 
    </div>
  );
}

export default withRouter(AppDashboard);
