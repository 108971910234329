import React from 'react';
import { UIAutocomplete, UINativeSelect } from '@UIKit';
import { identity } from 'lodash';
import { commonService } from '@services/commonService';

export const AdaptiveAutocomplete = ({ onClick = identity, ...props}) => {
  const isMobile = React.useMemo(() => commonService.getMobileOperatingSystem(), []);
  const handleClick = React.useCallback(() => {
    isMobile && commonService.scrollIt(155, 300, 'easeOutQuad')
  }, [isMobile]);
  const handleTimeoutClick = () => setTimeout(() => handleClick(), 50);

  return (
    <>
      { isMobile
        ? <UINativeSelect {...props} onClick={handleClick} />
        : <UIAutocomplete { ...props } onClick={handleTimeoutClick} /> }
    </>
  );
}
