import '@babel/polyfill';
import React, { useState, useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import Main from './components/Main';
import withTracker from './components/HOC/withTracker';
import { AppContext } from './contexts/AppContext';
import { throttle } from 'lodash';
import { commonService } from './services/commonService';
import ReactGA from 'react-ga';
import ym from 'react-yandex-metrika';
import { timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { useSelector } from "react-redux";
import ModalAbout from './components/About/modalAbout';
import CitizensPartnerShip from "./components/About/CitizensPartnerShip/CitizensPartnerShip"
import BusinessPartnerShip from "./components/About/BusinessPartnerShip/BusinessPartnerShip"
import Smi from "./components/About/Smi/Smi"

import LoyaltyProgram from "./components/About/LoyaltyProgram/LoyaltyProgram"
import Ask from "./components/About/Ask/Ask"
import Ads from "./components/About/Ads/Ads"
import Contacts from "./components/About/Contacts/Contacts"
import Team from "./components/About/Team/Team"
import Medianetwork from "./components/About/Medianetwork/Medianetwork"
import Rekvizity from "./components/About/Rekvizity/Rekvizity"
import PrizesPage from './components/UIKit/pages/PrizesPage/PrizesPage';

import {
  About,
  Filter,
  ModalPin,
  ReviewModal,
  BlogModal,
  SignInPage,
  SignUpPage,
  SignUpPageThanks,
  MailVerification,
  MailVerificationFinish,
  MailVerificationNotFinish,
  B2BPage,
  B2CPage,
  WhyPage,
  ForgotPasswordPage,
  SocialFallbackPage,
  LoyaltyPage,
  LoyaltyPrizesPage,
  MobileAppAdPage,
  Page404,
  PasswordChangedPage,
  SearchResultsPage,
  SignUpGeekPicnicPage,
  SignUpMondelezPage,
  SignUpCocaColaPage,
  SignUpZilPage,
  AuthCocaColaPage,
  SignUpEcoinPage,
  AuthEcoinPage,
  SignUpGraziaPage,
  AuthGraziaPage,
  SignUpFaceslacesPage,
  AuthFaceslacesPage,
  SignUpNordicweeksPage,
} from './lazyModules';
import { connect } from 'react-redux';
import { UIUnauthorizedRoute, UIAdvertizer } from '@UIKit';
import { frontloadConnect } from 'react-frontload';
import { loadData } from '@redux/actions/points'
import { bindActionCreators } from 'redux';
import Spinner from './components/Spinner';
import { Switch } from 'react-router-dom/cjs/react-router-dom.min';
import Prizes from './components/UIKit/pages/Prizes/Prizes.js';
// import Tasks from './components/UIKit/pages/Tasks/Tasks.js';
import Prix_in from './components/UIKit/pages/Prix_in/Prix_in';
import Prix from './components/UIKit/pages/Prix/Prix';
import Prize_congratulation from './components/UIKit/pages/Prize_congratulation/Prize_congratulation';
import Prize_delivery from './components/UIKit/pages/Prize_delivery/Prize_delivery';
import Prize_thank from './components/UIKit/pages/Prize_thank/Prize_thank';
import Prize_error from './components/UIKit/pages/Prize_error/Prize_error';
// import PrizePage from './components/UIKit/pages/PrizePage/PrizePage';
import AddPointStepForm from './components/UIKit/pages/Dots/AddPointStepForm';
import BigFormDots from './components/UIKit/pages/BigFormDots/BigFormDots';
import BigFormKazan from './components/UIKit/pages/BigFormKazan/BigFormKazan';
import FavoritePlace from './components/UIKit/pages/FavoritePlace/FavoritePlace';

const frontload = async ({ loadData, isLoaded }) => {
  await loadData();
};

export const App = (props) => {
  const [ appState, setAppState ] = useState({
    map: null,
    setMap: (map) => setAppState({
      ...appState,
      map
    }),
    screen: {
      ...commonService.getSize(),
      isMobile: commonService.isMobileSize()
    }
  });

  const store = useSelector((state) => state.modalProducts);

  useEffect(() => {
    const sub = timer(15 * 1000).pipe(
      take(1),
      tap(() => {
        ym('reachGoal', 'reachGoal');
        ReactGA.event({ category: 'reachGoal', action: 'reachGoal' });
      })
    ).subscribe();

    return () => sub.unsubscribe();
  });

  useEffect(() => {
    const resizeHandler = throttle(() => {
      setAppState({
        ...appState,
        screen: {
          ...commonService.getSize(),
          isMobile: commonService.isMobileSize()
        },
      })
    }, 50);
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  });

  useEffect(() => {
    ForgotPasswordPage.preload();
    SignInPage.preload();
    SignUpPage.preload();
    LoyaltyPrizesPage.preload()
  }, [])

  const host = typeof window !== 'undefined' ? window.location.host : props.hostName;
  const determinedOS = React.useMemo(() => commonService.getMobileOperatingSystem(), []);

  if (!props.isLoaded) {
    return (
      <div className="preload">
        <Spinner />
      </div>
    )
  }

  const routes = (
    <>
      <Switch>
        <Route path="/" exact={true} render={() => null}></Route>
        <Route path="/map" exact={true} render={() => null}></Route>
        <Route path="/categories" exact={true} render={() => null}></Route>
        <Route
          path={[
            "/points/fromcatalog/:ids",
            "/points/fromquery/:ids",
            "/points/frommap/:ids"
          ]}
          render={p =>
            <AppContext.Consumer>{(context) =>
              <ModalPin {...p} hostName={host} {...context} /> }
            </AppContext.Consumer>
          }
        />
        <Route path="/loyalty/new" component={BigFormDots} hostName={host} isAuthenticated={props.isAuthenticated}/>
        <Route path="/loyalty/new_vokrug_kazan" component={BigFormKazan} hostName={host} isAuthenticated={props.isAuthenticated}/>
        <Route path="/loyalty/new_vokrug" component={FavoritePlace} hostName={host} isAuthenticated={props.isAuthenticated}/>
        <Route path="/loyalty/new_point" component={AddPointStepForm} hostName={host} isAuthenticated={props.isAuthenticated}/>
        <Route path="/loyalty/prizes/tobuyprize/395" component={Prix_in} />
        {/* <Route path="/loyalty/prizess" component={Prizes} /> */}
        {/* <Route path="/loyalty/taskss" render={p => <Tasks {...p} hostName={host}/>}/> */}
        {/* <Route path="/loyalty/prizes" component={PrizePage} /> */}
        <Route path="/loyalty/prize_congratulation" component={Prize_congratulation} />
        <Route path="/prix" component={Prix} />
        
        <Route path="/prize_delivery" component={Prize_delivery} />
        <Route path="/prize_thank" component={Prize_thank} />
        <Route path="/prize_error" component={Prize_error} />

        <Route path="/about/citizens_partnership" component={CitizensPartnerShip} />
        <Route path="/about/business_partnership" component={BusinessPartnerShip} />
        <Route path="/about/smi" component={Smi} />
        <Route path="/about/team" component={Team} />
        <Route path="/about/loyalty_program" component={LoyaltyProgram} />
        <Route path="/about/faq" component={Ask} />
        <Route path="/about/ads" component={Ads} />
        <Route path="/about/contacts" component={Contacts} />
        
        <Route path="/about/medianetwork" component={Medianetwork} />
        <Route path="/about/rekvizity" component={Rekvizity} />

        <Route path="/about" component={ModalAbout} hostName={host} />

    

       

        <Route path="/loyalty" render={p => <LoyaltyPage {...p} hostName={host} isAuthenticated={props.isAuthenticated} />} />
        <Route path="/points/:id/review" render={p => <ReviewModal {...p} hostName={host} />} />
        <Route path="/points/:id/blog" render={p => <BlogModal {...p} hostName={host} />} />
        <Route path="/points/:id/blog/:blogId" render={p => <BlogModal {...p} hostName={host} />} />
        <Route path="/filter/:slug" render={p => <Filter {...p} hostName={host} />} />
        <Route path="/search" render={p => <SearchResultsPage {...p} hostName={host} /> } />
        <UIUnauthorizedRoute path="/sign-in" component={SignInPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/sign-up" component={SignUpPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/geekpicnic/sign-in" component={SignUpGeekPicnicPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/mondelez/sign-in" component={SignUpMondelezPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/coca-cola/sign-in" component={SignUpCocaColaPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/coca-cola/auth" component={AuthCocaColaPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/zil/sign-in" component={SignUpZilPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/ecoin/sign-in" component={SignUpEcoinPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/ecoin/auth" component={AuthEcoinPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/grazia/sign-in" component={SignUpGraziaPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/grazia/auth" component={AuthGraziaPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/faceslaces/sign-in" component={SignUpFaceslacesPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/faceslaces/auth" component={AuthFaceslacesPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <UIUnauthorizedRoute path="/nordicweeks/sign-in" component={SignUpNordicweeksPage} hostName={host} isAuthenticated={props.isAuthenticated} />
        <Route path="/sign-up-thanks" render={p => <SignUpPageThanks {...p} hostName={host} /> } />
        <Route path="/mail-verification" render={p => <MailVerification {...p} hostName={host} isAuthenticated={props.isAuthenticated} /> } />
        <Route path="/mail-verification-finish" render={p => <MailVerificationFinish {...p} hostName={host} /> } />
        <Route path="/mail-verification-not-finish" render={p => <MailVerificationNotFinish {...p} hostName={host} /> } />
        <Route path="/forgot-password" render={p => <ForgotPasswordPage {...p} hostName={host} /> } />
        <Route path="/social-fallback" render={p => <SocialFallbackPage {...p} hostName={host} /> } />
        <Route path="/b2c" render={p => <B2CPage {...p} hostName={host} /> } />
        <Route path="/b2b" render={p => <B2BPage {...p} hostName={host} /> } />
        <Route path="/why" render={p => <WhyPage {...p} hostName={host} /> } />
        <Route path="/password-reset" render={p => <PasswordChangedPage {...p} hostName={host} />} />
        <Route path="/404" render={p => <Page404 {...p} hostName={host} /> } />
        <Route component={Page404} />
      </Switch>
      {props.location.pathname !== '/loyalty/new_point' && <UIAdvertizer
        period={1000 * 60 * 60 * 14}  // 14h
        showCondition={!!determinedOS}
        component={MobileAppAdPage}
      />}
      
    </>
  )

  return (
    <AppContext.Provider value={{...appState }}>
      <div className={"App" + ((store.isOpen || store.isOpenMenu) ? ' hidden' : '') + (props.location.pathname.startsWith("/loyalty/prizes") ? ' prizes' : '')}>
        <Route path="/"
          render={p =>
            <AppContext.Consumer>
              {context => <Main {...p} {...context} hostName={host} routes={routes} />}
            </AppContext.Consumer>
          }
        />
      </div>
    </AppContext.Provider>
  );
}

const mapStateToProps = ({ auth: { isAuthenticated }, appState: { isLoaded } }) => ({ isAuthenticated, isLoaded });
const mapStateToDispatch = dispatch => bindActionCreators({ loadData }, dispatch);
export default withTracker(withRouter(
  connect(mapStateToProps, mapStateToDispatch)(frontloadConnect(frontload, { onMount: true, onUpdate: false })(App)
)));
