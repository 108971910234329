export const createTaskPayload = (message, files) => {
  const formData = new FormData();
  formData.append('content', message || 'У этого задания нет описания');
  files.forEach((file, i) => {
    const fileName = i === 0 ? 'file' : `file${i + 1}`;
    formData.append(fileName, file);
  });

  return formData;
}
