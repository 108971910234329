import React from "react";
import "./Prize_error.scss";
import SVG from "react-inlinesvg";
import error from "@images/svg/prize-error.svg";
import { Link } from "react-router-dom";

const Prize_error = () => {
  return (
    <div className="prize_error">
      <div className="prize_error__container">
        <div className="prize_error__title">
          <span>4</span>
          <SVG src={error} />
          <span>4</span>
        </div>
        <div className="prix_in__title">Ложки не существует...</div>
        <div className="prize_error__text">The Matrix</div>
        <div  className="prize_error__wrapper-button">
          <Link to="/prizes" className="prix_in__nav">
            вернуться
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Prize_error;
