import React from "react";
import "./Rekvizity.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";
import phoneCity from "@images/phone-city.jpg";

const Rekvizity = () => {
  return (
    <div className="rekvizity">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/rekvizity">Реквизиты</Link>
        </div>
        <h1 className="about-sub__title">Реквизиты</h1>
        <div className="rekvizity-wrapper">
          <p>
            <div>Полное наименование:<span>НЕКОММЕРЧЕСКОЕ ПАРТНЕРСТВО ОКАЗАНИЯ ПОМОЩИ ЛЮДЯМ В ЗАТРУДНИТЕЛЬНЫХ ЖИЗНЕННЫХ
            ОБСТОЯТЕЛЬСТВАХ «И ВСЕ ЗА ОДНОГО»</span></div>
          </p>
          <p>Краткое наименование: НП «И ВСЕ ЗА ОДНОГО»</p>
          <p>Юридический адрес: 119121, г. Москва, ул.Плющиха, д. 9-9А, стр. 2</p>
          <p>ИНН 7704281640</p>
          <p>КПП 770401001</p>
          <p>р/сч 40703810000390000004 в АО «ИнвестТоргБанк» г.Москва</p>
          <p>к/сч 30101810645250000267</p>
          <p>БИК 044525267</p>
          <p>Телефон: +7 (968) 405-5550</p>
          <p>E-mail: info@plus-one.ru</p>
          <p>Председатель: Богачкова Галина Леонидовна </p>
        </div>
      </div>
    </div>
  );
};

export default Rekvizity;
