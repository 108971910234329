import React from "react";
import "./Smi.scss";

import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import arrowLeft from "@images/svg/arrow-about.svg";
import AlertDialog from "./Dialog"

const Smi = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="smi about-sub">
      <div className="CONTAINER">
        <div className="breadcrumbs">
          <Link to={(location) => ({ ...location, pathname: "/" })}>Главная</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about">О проекте</Link>
          <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          <Link to="/about/smi">Для СМИ</Link>
        </div>
        <h1 className="about-sub__title">Для СМИ</h1>
        <div className="about-sub__description">
          <p className="about-sub__desc">
            По вопросам информационного сотрудничества обращайтесь в нашу пиар-дирекцию{" "}
            <a href="mailto:pr@plus-one.ru">pr@plus-one.ru</a>
          </p>
          <p className="about-sub__link-arrow">
            <a href="https://gorod.plus-one.ru/media/django-summernote/2022-09-25/a50c2b5a-b7e4-4561-adba-17237b7de22e.pdf" target="blank">Скачать медиакит</a> <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
          </p>
          <p className="about-sub__desc">
            Разместите виджет с картой +1Город у себя на сайте и организуйте размещение материалов в медиасети.
          </p>
          <div className="about-sub__wrapper-link">
            {/* <p className="about-sub__link-arrow">
              <a href="https://gorod.plus-one.ru/filter/otvetstvenno-potreblyat?city=Moscow&subs=eco_recycling&popup-mobile=no-open&=no-menu&no-burger&=no-footer&=no-back&=no-search" target="_blank">Получить код виджета</a> <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
            </p> */}
            <p className="about-sub__link-arrow">
              <a target="_blank" href="https://gorod.plus-one.ru/media/django-summernote/2022-09-07/a2a3c03b-85b3-4c45-9afe-874051a9e082.pdf">Скачать презентацию</a> <SVG className="breadcrumbs_arrow" src={arrowLeft}></SVG>
            </p>
          </div>
        </div>
        <div className="about-sub__footer-text">
          <a onClick={handleClickOpen}>Использование материалов сайта и мобильного приложения</a>
        </div>
      </div>
      <AlertDialog handleClose={handleClose} open={open}/>
    </div>
  );
};

export default Smi;
