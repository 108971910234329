import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import AppDashboard from "./components/Dashboard";
import { MoreMenu } from "./components/MoreMenu";
import useDevice, { DEVICE } from "@hooks/useDevice";
import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";
import { Link } from "react-router-dom";
import "./AppBar.scss";
import { UIHamburger, UISideBar, UICityDialog } from "@UIKit";
import { UISignOutDialog } from "@UIKit";
import { bindActionCreators } from "redux";
import { toggleSignOutDialog } from "@redux/actions/auth";
import { commonService } from "@services/commonService";
import logo from "@images/svg/app-bar-logo.svg";
import search from "@images/svg/search.svg";
import arrowAcc from "@images/svg/arrow-acc.svg";
import { useDispatch } from "react-redux";
import { setIsOpenNavModal } from "../../../redux/actions/modalProducts";

import appStoreIcon from "@images/svg/navigations-app-store.svg";
import closeIcon from "@images/svg/navigations-close.svg";
import dotIcon from "@images/svg/navigations-dot.svg";
import googlePlayIcon from "@images/svg/navigations-google-play.svg";
import mapIcon from "@images/svg/navigations-map.svg";
import taskIcon from "@images/svg/navigations-task.svg";
import gamburger from "@images/svg/gamburger.svg";

import googlePlayDesktopIcon from "@images/svg/navigations-desktop-google-play.svg";
import appStoreDesktopIcon from "@images/svg/navigations-desktop-app-store.svg";

import vkontakte from "@images/svg/navigations-desktop-vkontakte.svg";
import instagram from "@images/svg/navigations-desktop-instagram.svg";
import youtube from "@images/svg/navigations-desktop-youtube.svg";
import tiktok from "@images/svg/navigations-desktop-tiktok.svg";

const AppBar = ({
  className,
  cities,
  citySlug,
  wallet,
  signOutDialogOpen,
  toggleSignOutDialog,
  location,
  history,
  map,
  isOpenModal,
  props,
}) => {
  const dispatch = useDispatch();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [cityDialogOpen, setCityDialogOpen] = useState(false);
  const [renderIndex, setRenderIndex] = useState(0);
  const [isHiddenHamburger, setIsHiddenHamburger] = useState(false);
  let [isNavigations, setIsNavigations] = useState(false);
  // const [isHiddenLogo, setIsHiddenLogo] = useState(false);
  const [isHiddenAllMenu, setIsHiddenAllMenu] = useState(false);

  const [url, setUrl] = useState("");
  const [firstUpdate, setFirstUpdate] = useState(true);
  const [device] = useDevice();
  const selectedCity = React.useMemo(() => {
    if (location.search.includes("&")) {
      setUrl(location.search);

      const DopUrl = location.search;

      const a = DopUrl.split("&");
      a[0] = "";

      const bezCity = a.join("&");

      setUrl(bezCity);

      setTimeout(() => {
        history.push(location.pathname + location.search + (location.search.includes(url) ? "" : url));
      }, 0);

      setFirstUpdate(false);
    } else {
      if (!firstUpdate) {
        setTimeout(() => {
          if (location.pathname === "/") {
            history.push(location.pathname + location.search + url);
          }
        }, 0);
      }

      setFirstUpdate(false);
    }

    return cities.find((city) => city.slug === citySlug);
  }, [cities, citySlug]);

  const tabs = [
    {
      title: "поиск и карта",
      content: [
        {
          to: "/filter/local_business/",
          link: "вокруг",
        },
        {
          to: "/filter/otvetstvenno-potreblyat/",
          link: "раздельный сбор",
        },
        {
          to: "/filter/get_help/",
          link: "вокруг",
        },
        {
          to: "/filter/local_business/",
          link: "сервисы помощи",
        },
        {
          to: "/loyalty/new_point",
          link: "добавить точку",
        },
        {
          to: "/filter/pets/",
          link: "животные",
        },
        {
          to: "/filter/health/",
          link: "здоровье",
        },
        {
          to: "/filter/lifestyle",
          link: "образ жизни",
        },
      ],
    },
    {
      title: "программа лояльности",
      content: [
        {
          to: "/loyalty/tasks/",
          link: "выполнить задания",
        },
        {
          to: "/loyalty/prizes/",
          link: "получить призы",
        },
      ],
    },
    {
      title: "о проекте",
      content: [
        {
          to: "/about/",
          link: "о проекте",
        },
        {
          to: "/about/contacts/",
          link: "контакты",
        },
        {
          to: "/about/smi/",
          link: "для сми",
        },
        {
          to: "/about/ads/",
          link: "размещение рекламы",
        },
        {
          to: "/about/rekvizity/",
          link: "реквизиты",
        },
        {
          to: "/about/medianetwork/",
          link: "медиасеть",
        },
        {
          to: "/about/loyalty_program/",
          link: "программа лояльности",
        },
        {
          to: "/about/business_partnership",
          link: "для малого бизнеса",
        },
        {
          to: "/about/citizens_partnership",
          link: "для горожан",
        },
        {
          to: "/about/faq/",
          link: "часто задаваемые вопросы",
        },
        {
          to: "/about/team/",
          link: "команда",
        },
      ],
    },
  ];

  React.useEffect(() => {
    setIsHiddenHamburger(commonService.locationSearch.includes("no-burger"));
    // setIsHiddenLogo(commonService.locationSearch.includes('=no-logo'))
    setIsHiddenAllMenu(commonService.locationSearch.includes("=no-menu"));
  }, []);

  const closeCityDialog = React.useCallback(() => setCityDialogOpen(false), []);
  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const openCityDialog = () => {
    setRenderIndex(renderIndex + 1);
    setCityDialogOpen(true);
    // history.push("/loyalty" + location.search);
  };

  const handleOpenLoyalty = () => {
    // history.push("/loyalty" + location.search);
  };

  React.useEffect(() => {
    device === DEVICE.DESKTOP && sideBarOpen && setSideBarOpen(false);
  }, [device]);

  const appBarClassName = clsx(
    className,
    "AppBar",
    sideBarOpen && "AppBar--mobile",
    isHiddenAllMenu && "AppBar--hidden"
  );

  const toggleMenu = (e) => {
    const a = document.querySelector(".AppBar__icon-bottom.gamburger-app");
    const b = document.querySelector("svg.gamburger-app");
    const c = document.querySelector("svg.gamburger-app line");
    if (!isNavigations && (e.target === a || e.target === b || e.target === c)) {
      isNavigations = true;
      setIsNavigations(true);
      dispatch(setIsOpenNavModal());
    } else {
      if (isNavigations) {
        dispatch(setIsOpenNavModal());
      }
      isNavigations = false;
      setIsNavigations(false);
    }
  };

  useEffect(() => {
    if (isNavigations) {
      isNavigations = false;
      setIsNavigations(false);
      dispatch(setIsOpenNavModal());
    }
  }, [location.pathname]);

  const [accMapAndSearch, setAccMapAndSearch] = useState(false);
  const [accProject, setAccProject] = useState(false);
  const [accAbout, setAbout] = useState(false);

  const toggleAccordionMapandSearch = () => {
    setAccMapAndSearch(!accMapAndSearch);
  };

  const toggleProgramLoyaliti = () => {
    setAccProject(!accProject);
  };

  const toggleAbout = () => {
    setAbout(!accAbout);
  };

  const TabContent = ({ title, content }) => (
    <ul className="navigations-menu__tabs-content active">
      {content.map((item, index) => (
        <li key={index}>{item.to ? <Link to={item.to}>{item.link}</Link> : <a href={item.href}>{item.link}</a>}</li>
      ))}
    </ul>
  );

  const [active, setActive] = React.useState(0);

  const openTab = (e) => setActive(+e.target.dataset.index);

  const changeSearch = ({ target }) => {
    props.setSearchResult({ searchText: target.value });
  };

  const onEnter = (event) => {
    const { searchText, currentCenter, setSearchResult } = props;

    if (event.key === "Enter") {
      setSearchResult({ searchTextToDisplay: searchText });
      handleSearch(searchText, currentCenter, true);
    }
  };

  const closeAllModal = () => {
    const tmpArr = props.categories;
    tmpArr.forEach((cater) => (cater.active = false));
    props.setPoints({ categories: tmpArr, onlineServiceOpen: false });
  };

  const getSearchResults = () => {
    const { searchText, currentCenter, setSearchResult } = props;
    setSearchResult({ searchTextToDisplay: searchText });
    handleSearch(searchText, currentCenter, true);
  };

  const handleSearch = (searchText, [latitude, longitude], composeQuery) => {
    let searchQuery = null;
    const {
      history,
      location: { search },
    } = props;

    if (composeQuery) {
      const composedQuery = commonService.composeSearchQuery(searchText, search, [latitude, longitude]);
      searchQuery = `/search/?${composedQuery}`;
      history.push(searchQuery);
      props.setSearchResult({
        searchQuery,
        searchText,
      });
    }
    props.setPoints({ selectedPointIds: [] });
    props.setAppState({
      filterIsOpen: false,
      isSearching: true,
    });
    props.searchPoints(searchText, latitude, longitude);
  };

  const toggleBurger = () => {
    setIsNavigations(!isNavigations);
  };

  const renderSearchForm = () => {
    const { searchText } = props;

    const isNoSearch = props.location.search.includes("=no-search");

    return (
      <div className={isNoSearch ? "search hidden" : "search"}>
        <div className="search__form">
          <input
            onChange={changeSearch}
            onKeyPress={onEnter}
            onClick={closeAllModal}
            value={searchText}
            className="search__field"
            type="text"
            placeholder="Поиск..."
            spellCheck="false"
          />
          <button className="search__icon" onClick={getSearchResults}>
            <SVG src={search} />
          </button>
        </div>
      </div>
    );
  };

  const isLogo = props.location.search.includes("=no-logo");
  const isNoSearch = props.location.search.includes("=no-search");
  const ismenuAll = props.location.search.includes("=no-menu-all");

  return (
    <>
      <div className={appBarClassName}>
        {!isLogo && (
          <a href="/" className={isNavigations ? "AppBar__logo-title desktop visible" : "AppBar__logo-title desktop"}>
            +1ГОРОД
          </a>
        )}
        <div className={isNavigations ? "AppBar__top white-style" : "AppBar__top"}>
          <div className="AppBar__Section AppBar__Section--left">
            {!isHiddenAllMenu && (
              <MoreMenu
                isOpenModal={isOpenModal}
                className="AppBar__Icon"
                selectedCity={selectedCity}
                openCityDialog={openCityDialog}
              />
            )}
          </div>
          <div className="AppBar__Section-center">
            <SVG src={logo} />
          </div>
          <div className="AppBar__Section AppBar__Section--right">
            <UIHamburger
              className="AppBar__Icon"
              isPressed={sideBarOpen}
              onClick={toggleSideBar}
              isHidden={isHiddenHamburger}
            />
          </div>
        </div>
        <div className="maps-icons-hidden">
          <a href="/" className="AppBar__logo-title mobile">
            +1ГОРОД
          </a>
          <div className="AppBar__Icons-bottom">
            <AppDashboard
              className="AppBar__Dashboard"
              selectedCity={selectedCity}
              openCityDialog={openCityDialog}
              wallet={wallet}
              openLoyalty={handleOpenLoyalty}
            />
            <div className="AppBar__icon-bottom">
              {!isNoSearch && (
                <div className="search mobile">
                  <SVG src={search} />
                </div>
              )}
              <div className="search desktop">{renderSearchForm()}</div>
            </div>
            {!isHiddenHamburger && (
              <div className="AppBar__icon-bottom gamburger-app">
                {!ismenuAll && <SVG className="gamburger-app" src={isNavigations ? closeIcon : gamburger} />}
              </div>
            )}
            {
              <div className={isNavigations ? "AppBar__menu AppBar__menu-visible" : "AppBar__menu AppBar__menu-hide"}>
                {device === "phone" && (
                  <OutsideClickHandler onOutsideClick={toggleMenu}>
                    <div className="navigations-menu__wrapper">
                      <div className="navigations-menu__item">
                        <div
                          id="search"
                          className="navigations-menu__title"
                          onClick={() => toggleAccordionMapandSearch()}
                        >
                          <div className="navigations-menu__title-text">поиск и карта</div>
                          <div className="navigations-menu__icon">
                            <SVG src={arrowAcc} className={accMapAndSearch && "accMapAndSearch"} />
                          </div>
                        </div>
                        {accMapAndSearch && (
                          <ul className="navigations-menu__content">
                            <li className="navigations-menu__subitem">
                              <Link to="/filter/local_business" className="navigations-menu__link">
                                ВОКРУГ
                              </Link>
                            </li>
                            <li className="navigations-menu__subitem">
                              <Link to="/filter/otvetstvenno-potreblyat" className="navigations-menu__link">
                                РАЗДЕЛЬНЫЙ СБОР
                              </Link>
                            </li>
                            <li className="navigations-menu__subitem">
                              <Link to="/filter/get_help" className="navigations-menu__link">
                                Сервисы помощи
                              </Link>
                            </li>
                            <li className="navigations-menu__subitem">
                              <a href="https://plus-one.simpoll.ru/4d261309" className="navigations-menu__link">
                                Добавить точку
                              </a>
                            </li>
                            <li className="navigations-menu__subitem">
                              <Link to="/filter/pets" className="navigations-menu__link">
                                Животные
                              </Link>
                            </li>
                            <li className="navigations-menu__subitem">
                              <Link to="/filter/health" className="navigations-menu__link">
                                Здоровье
                              </Link>
                            </li>
                            <li className="navigations-menu__subitem">
                              <Link to="/filter/lifestyle" className="navigations-menu__link">
                                образ жизни
                              </Link>
                            </li>
                          </ul>
                        )}
                        <div className="navigations-menu__buttons">
                          <Link to="/map" className="navigations-menu__button">
                            <SVG className="navigations-menu__button-icon" src={mapIcon} />
                            Посмотреть карту
                          </Link>
                          <Link
                            to="/loyalty/new_point"
                            href="https://plus-one.simpoll.ru/4d261309"
                            className="navigations-menu__button"
                          >
                            <SVG className="navigations-menu__button-icon" src={dotIcon} />
                            Добавить точку
                          </Link>
                        </div>
                      </div>
                      <div className="navigations-menu__item">
                        <div className="navigations-menu__title" onClick={() => toggleProgramLoyaliti()}>
                          <Link to="/loyalty/" className="navigations-menu__title-text">
                            программа лояльности
                          </Link>
                          <div className="navigations-menu__icon">
                            <SVG src={arrowAcc} className={accProject && "accProject"} />
                          </div>
                        </div>
                      </div>
                      {accProject && (
                        <ul className="navigations-menu__content">
                          <li className="navigations-menu__subitem">
                            <Link to="/loyalty/tasks" className="navigations-menu__link">
                              выполнить задания
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/loyalty/prizes" className="navigations-menu__link">
                              получить призы
                            </Link>
                          </li>
                        </ul>
                      )}
                      <div className="navigations-menu__buttons">
                        <a href="/" className="navigations-menu__button">
                          <SVG className="navigations-menu__button-icon" src={taskIcon} />
                          Добавить задание
                        </a>
                      </div>
                      <div className="navigations-menu__item">
                        <div className="navigations-menu__title" onClick={() => toggleAbout()}>
                          <Link className="navigations-menu__title-text" to="/about/">
                            о проекте
                          </Link>
                          <div className="navigations-menu__icon">
                            <SVG src={arrowAcc} className={accAbout && "accAbout"} />
                          </div>
                        </div>
                      </div>
                      {accAbout && (
                        <ul className="navigations-menu__content">
                          <li className="navigations-menu__subitem">
                            <Link to="/about/contacts/" className="navigations-menu__link">
                              контакты
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/smi/" className="navigations-menu__link">
                              для сми
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/ads/" className="navigations-menu__link">
                              размещение рекламы
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/rekvizity/" className="navigations-menu__link">
                              реквизиты
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/medianetwork/" className="navigations-menu__link">
                              медиасеть
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/loyalty_program/" className="navigations-menu__link">
                              программа лояльности
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/rekvizity/" className="navigations-menu__link">
                              для малого бизнеса
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/citizens_partnership" className="navigations-menu__link">
                              для горожан
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/faq/" className="navigations-menu__link">
                              часто задаваемые вопросы
                            </Link>
                          </li>
                          <li className="navigations-menu__subitem">
                            <Link to="/about/team/" className="navigations-menu__link">
                              команда
                            </Link>
                          </li>
                        </ul>
                      )}
                      <div className="navigations-menu__text-setup">скачать приложение</div>
                      <div className="navigations-menu__buttons">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.poleznygorod.cityplusone&hl=ru&gl=US&showAllReviews=true"
                          className="navigations-menu__button"
                        >
                          <SVG src={googlePlayIcon} />
                        </a>
                        <a
                          href="https://apps.apple.com/ru/app/%D1%8D%D0%BA%D0%BE-%D0%BF%D0%B5%D1%80%D0%B5%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0-%D1%83%D1%82%D0%B8%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F/id1505256876"
                          className="navigations-menu__button"
                        >
                          <SVG src={appStoreIcon} />
                        </a>
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
                {device === DEVICE.DESKTOP && (
                  <OutsideClickHandler onOutsideClick={toggleMenu}>
                    <div className="navigations-menu__wrapper-desktop">
                      <div className="navigations-menu__block">
                        <div className="navigations-menu__tabs-links">
                          {tabs.map((n, i) => (
                            <div
                              onClick={openTab}
                              key={i}
                              data-index={i}
                              className={`navigations-menu__tabs-link active" ${i === active ? "active" : ""}`}
                            >
                              {n.title}
                            </div>
                          ))}
                        </div>
                        <div>
                          <div className="navigations-menu__setup">скачать приложение +1Город</div>
                          <div className="navigations-menu__setup-app">
                            <a href="https://play.google.com/store/apps/details?id=com.poleznygorod.cityplusone&hl=ru&gl=US&showAllReviews=true">
                              <SVG src={googlePlayDesktopIcon} />
                            </a>
                            <a href="https://apps.apple.com/ru/app/%D1%8D%D0%BA%D0%BE-%D0%BF%D0%B5%D1%80%D0%B5%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0-%D1%83%D1%82%D0%B8%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F/id1505256876">
                              <SVG src={appStoreDesktopIcon} />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="navigations-menu__block">{tabs[active] && <TabContent {...tabs[active]} />}</div>
                      <div className="navigations-menu__block">
                        <div className="navigations-menu__buttons-desktop">
                          <Link to="/map" className="navigations-menu__button-desktop">
                            посмотреть карту
                          </Link>
                          <a href="/crowd/" target="_blank" className="navigations-menu__button-desktop">
                            поддержи проект
                          </a>
                          <Link to="/loyalty/new_point" className="navigations-menu__button-desktop">
                            Добавить точку
                          </Link>
                        </div>
                        <div>
                          <div className="navigations-menu__subscribe">Подпишись</div>
                          <div className="navigations-menu__social-desktop">
                            <a href="https://vk.com/project_plus_one" className="navigations-menu__button-social">
                              <SVG src={vkontakte} />
                            </a>
                            <a href="/" className="navigations-menu__button-social">
                              <SVG src={instagram} />
                            </a>
                            <a href="https://www.youtube.com/c/Plusoneru" className="navigations-menu__button-social">
                              <SVG src={youtube} />
                            </a>
                            <a href="https://zen.yandex.ru/plusoneru" className="navigations-menu__button-social">
                              <SVG src={tiktok} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            }
            <div className="AppBar__icon-bottom">
              <UISideBar
                sideBarOpen={sideBarOpen}
                toggleSideBar={toggleSideBar}
                toggleSignOutDialog={toggleSignOutDialog}
                className={"AppBar__SideBar"}
              />
            </div>
          </div>
        </div>
      </div>
      <UICityDialog
        isOpen={cityDialogOpen}
        selectedCity={selectedCity}
        onClose={closeCityDialog}
        renderIndex={renderIndex}
        map={map}
      />
      <UISignOutDialog isOpen={signOutDialogOpen} onClose={toggleSignOutDialog} />
    </>
  );
};

const mapStateToProps = ({ map: { slug, cities }, loyalty: { wallet }, auth: { signOutDialogOpen } }) => ({
  citySlug: slug,
  cities,
  wallet,
  signOutDialogOpen,
});
const mapStateToDispatch = (dispatch) => bindActionCreators({ toggleSignOutDialog }, dispatch);
export default withRouter(connect(mapStateToProps, mapStateToDispatch)(AppBar));
